import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/trackChanges";
} else {
  apiEndpoint = herokuUrl + "/trackChanges";
}

export async function saveTrackedChanges(  assetId, sectionId, assetType, createdBy, addedContent, removedContent, htmlContent, userId ) {
    let data = await httpService.post(`${apiEndpoint}/saveTrackedChanges`,
    {
        assetId, sectionId, assetType, createdBy, addedContent, removedContent, htmlContent, userId
    }
    );
    return data;
  }



export async function getTrackedChanges( assetId, sectionId, assetType ) {
    let data = await httpService.post(`${apiEndpoint}/getTrackedChanges`,
        {
            assetId, sectionId, assetType
        }
    );
    return data;
}

export async function sendRevertRequest(data) {
  let response = await httpService.post(`${apiEndpoint}/sendRevertRequest`,{data});
  return response;
}

export async function updateRevertRequest(data) {
  let response = await httpService.post(`${apiEndpoint}/updateRevertRequest`,{data});
  return response;
}



export async function saveContentLibrary(orgName, assetId, sectionId, createdBy, fieldValues) {
  let data = await httpService.post(`${apiEndpoint}/saveContentLibrary`, {
      orgName,
      assetId,
      sectionId,
      createdBy,
      fieldValues
  });
  return data;
}

export async function getContentLibrary( orgName, assetId, sectionId, createdBy ) {
  let data = await httpService.post(`${apiEndpoint}/getContentLibrary`,
      {
          orgName, assetId, sectionId, createdBy
      }
  );
  return data;
}

export async function getClausesList() {
  let data = await httpService.get(`${apiEndpoint}/getClausesList`);
  return data;
}

