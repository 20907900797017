import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Link,
  styled,
  DialogContentText
} from "@material-ui/core";
import Select from 'react-select';
import React, { useState, useEffect, useRef, useMemo } from "react";
import { diffWordsWithSpace } from "diff";
import Divider from '@mui/material/Divider';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import {
  Box,
  Container,
  TextField,
  Button,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Menu,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@material-ui/core";
import { DialogActions } from '@mui/material';
import JoditEditor, { Jodit } from "jodit-react";
import { showToast } from "../../messages";
import PreviewIcon from "@mui/icons-material/Preview";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import mammoth from "mammoth";
import { docx } from "html-docx-js/dist/html-docx";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import WindowIcon from "@mui/icons-material/Window";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DownloadIcon from "@mui/icons-material/Download";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SendIcon from "@mui/icons-material/Send";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PrintIcon from "@mui/icons-material/Print";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import TaskCompletionModal from "../TaskCompletionModal";
import { node } from "prop-types";
import {
  getUserInfo,
  getopportunities,
  isSummarizedDescription,
  updateSummarizedDescription,
} from "../../services/opportunityService";
import { getresourceallocation, mappedReuirementClause } from "../../services/resourceAllocationService";
import {
  deleteExisting,
  deleteFile,
  getList,
  improveInputSection,
  improveSectionContent,
  uploadFile,
} from "../../services/s3bucketService";
import {
  addAssetsection,
  decisionByReceiver,
  getAllSectionVersions,
  getAssetsection,
  updateAssetsection,
} from "../../services/assetSectionServices";
import { toast } from "react-toastify";
import axios from "axios";
import {
  getFileContent,
  getRFPDocument,
  getSectionDocument,
  getSpecificVersionData,
} from "../../services/templatesService";
import { PreviewEditor } from "../PreviewEditor";
import html2pdf from "html2pdf.js";
import { AltRoute, Bluetooth, RepeatOneSharp } from "@mui/icons-material";
import Loader from "../../utils/loader";
import { getCheckSummariser } from "../../services/summariserService";
import {
  OpportunityInputBoxMui,
  OpportunityMultilineInputBoxMui,
} from "../ReusableComponents/inputBoxOpportunity";
import {
  UpdateassetComments,
  addAssetComments,
  deleteAssetComment,
  getAssetComments,
  replyToComment,
  deleteCommentReply,
  UpdateReplyOfComment,
} from "../../services/assetSectionNotes";
import { ReusableCard } from "../MyDocumentsCards";
import OppourtunityManagementTemplate from "../OppourtunityManagementTemplate";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import Swapping from "../Images/Swapping.png";
import ReorderModal from "./ReorderModal";
import { addActivity, getActivity } from "../../services/activityTransaction";
import "./opportunityManagmentNewPage.css";
import ReorderSequence from "./ReorderSequence";
// import ShareAssetsPage from "./ShareAssetsPage";
import { getAllUserForSameOrg } from "../../services/organizationService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { templateData } from "../../redux/template/template.action";
// import ShareAssetsSectionPage from "../pages/AssetSectionSharePage";
import { getSectionSharedData } from "../../services/assetsSharingService";
import EditIcon from "@mui/icons-material/Edit";
import { GetDocData, ImprovedDocData } from "../../services/templatesService";
import {
  addClauseProject,
  addClauses,
  approveClause,
  deleteClause,
  getClauseProject,
  getClauses,
  getResourceLinks,
  rejectClause,
  updateClauseProject,
  getDeletedClause
} from "../../services/clauseExtractionService";
import KeyIcon from "@mui/icons-material/Key";
import {
  addAisections,
  getAllTaskAssignments,
  getAllassetsharing,
  getasset,
} from "../../services/assetService";
import { getSpecificAsset, bidAsset, nobidAsset, requestBid } from "../../services/assetService";
import {
  extractPdfData,
  improveInput,
  aiGeneratedRequirnments,
  requirementMapping,
  keywordForSamrtSuggestion,
  smartSuggestion
} from "../../services/s3bucketService";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { saveAs } from "file-saver";
import { asBlob } from "html-docx-js-typescript";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Chip } from '@material-ui/core';
import TableContainer from "@mui/material/TableContainer";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Paper from "@mui/material/Paper";
import Axios from "axios";
import { Icon } from "semantic-ui-react";
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table as DocxTable,
  TableCell as TableCell1,
} from "docx";
import htmlDocx from "html-docx-js";
import { TemplateMerge } from "../../services/templatesService";
import {
  saveTrackedChanges,
  getTrackedChanges,
  getClausesList,
  sendRevertRequest, 
  updateRevertRequest 
} from "../../services/trackChanges";
import TaskAssignmentModal from "./TaskAssignment";
import {
  VersionComapreModal,
  VersionHistoryCard,
} from "./Modals/versionCompare";
import { sendFinalEmail } from "../../services/assetSharingServices";
import { getFarParts } from "../../services/farCauses";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { getRequirementMapping, getRequirementMappingById, revertRequirementMapping } from "../../services/resourceAllocationService";


import { getTeamTask, updateTeamTask } from "../../services/assetService";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'; import { Tooltip } from "recharts";
import ReactTooltip from "react-tooltip";
import { getTaskAssignedDetails } from "../../services/taskAssignmentData";
import { getAssetRequestStatus, sendAssetRequest } from "../../services/assetRequest";
import { TaskAssignmentAddComentModal, TaskAssignmentComentModal,  RevertRequestModal, RevertApprovalModal  } from "./Modals/rfpModals";
import { AttachmentModal } from "./Modals/AttachmentModal";

const Diff = require("diff");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1300,
  height: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const removeHtmlTags = (htmlContent) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlContent;
  const text = tempElement.textContent || tempElement.innerText || "";
  return text;
};


const customStyles1 = {
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#1976d2',
    color: 'white',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': {
      backgroundColor: '#145a86',
      color: 'white',
    },
  }),
};







const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const greenRowStyle = { backgroundColor: '#90EE90' };
const redRowStyle = { backgroundColor: '#FF7F7F' };

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: '200px',
    marginRight: '8px'
  })
};

export const OpportunityManagementUpload = () => {
  const [open, setOpen] = useState(false);

  const [openPopup, setOpenPopup] = useState(false);

  const [opennewPopup, setOpennewPopup] = useState(false);

  const [taskApprovalOwnerData, setTaskApprovalOwnerData] = useState(null);

  const [taskApprovalReceiverData, setTaskApprovalReceiverData] =
    useState(null);
  const [farData, setFarData] = useState(null);
  const [openAiSectionsList, setOpenAiSectionsList] = useState([])
  const [specifiSectiontaskData, setSpecifiSectiontaskData] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [inputKey, setInputKey] = useState();
  const [userInfo, setUserInfo] = useState("");
  const [asssetsectionData, setAssetSectionData] = useState([]);
  const [statusAccepted, setStatusAccepted] = useState(false);
  const [submittedStatus, setSubmittedStatus] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [normalDate, setNormalDate] = useState("");
  const [cuurentUser, setCuurentUser] = useState("");
  const [cuurentUserName, setCuurentUserName] = useState("");
  const [title, setTitle] = useState("");
  const [adddescription, setaddDescription] = useState("");
  const [agancyName, setAgancyName] = useState("");
  const [cleanedContent, setCleanedContent] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileURL, setFileURL] = useState();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isLeftPaneVisible, setLeftPaneVisible] = useState(true);
  const [content, setContent] = useState(null);
  const [isgetDataEditor, setIsgetDataEditor] = useState(false);
  const [assetsectionId, setAssetSetionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showtemplate, setShowTemplate] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [htmlOwnerContent, setHtmlownerContent] = useState("");
  const [sectionTitle, setSectionTitle] = useState("");
  const [editSectionTitle, seteditSectionTitle] = useState(false);
  const [summarizedDescription, setsummarizedDescription] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [note, setNote] = useState("");
  const [notesData, setNotesData] = useState([]);
  const [selectedNoteId, setSelectedNoteId] = useState();
  const [updateIcon, setUpdatIcon] = useState();
  const [commentsText, setComments] = useState();
  const [commentData, setCommentData] = useState([]);
  const [commentCount, setCommentCount] = useState();
  const [isExpanded2, setIsExpanded2] = useState(false)
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [updatCommentIcon, setUpdatCommentIcon] = useState();
  const [isCommentModalOpen, setisCommentModalOpen] = useState();
  const [isModalOpen, setisModalOpen] = useState(false);
  const [noteCount, setNoteCount] = useState();
  const [isCardDatModalOpen, setisCardDatModalOpen] = useState();
  const [cardData, setcardData] = useState();
  const [viewType, setViewType] = useState();
  const [createdByCard, setCreatedByCard] = useState();
  const [cardId, setCardId] = useState();
  const [activityCount, setActivityCount] = useState();
  const [propsPassed, setPropPassed] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [isOpenClauseModal, setIsOpenClauseModal] = useState(false);
  const [sideModal, setSideModal] = useState(false)
  const [extractedClause, setExtractedClause] = useState([]);
  const [openJoditPopup, setOpenJoditPopup] = useState(null);
  const [sharedAssetSectionScreenType, setSharedAssetSectionScreenType] =
    useState();
  const [sharedAssetSectionType, setSharedAssetSectionType] = useState();
  const [sharedSectionDetails, setSharedSectionDetails] = useState([]);
  const [particularSection, setParticularSection] = useState({});
  const [isreadonly, setIsReadOnly] = useState(true);
  const [departMentSector, setDepartmentSector] = useState("");
  const [reviewData, setReviewData] = useState({})
  const [activityMsg, setActivityMsg] = useState({
    uploadFile: "uploaded the document",
    uploadSection: "uploaded the section",
    deleteFile: "deleted the document",
    addSection: "added a section",
    updateSection: "updated a section",
    deleteSection: "deleted a section",
    addComment: "added a comment",
    updateComment: "updated a comment",
    deleteComment: "deleted a comment",
    addNote: "added a note",
    updateNote: "updated a note",
    deleteNote: "deleted a note",
    addClause: "added a clause",
    addReply: "sent a reply",
    deleteReply: "deleted a reply",
    updateReply: "updated a reply",
    taskAccepted: "accepted a task",
    taskRejected: "rejected a task",
    submitForReview: "submitted a task for review",
    accepteChanges: "approved a changes",
    rejectChanges: "rejected a changes",
  });
  const editorForRequirnment = useRef(null);
  const editorRequirementMapping = useRef(null);
  const [commentMessage, setCommentMessage] = useState('')
  const [revertHtml, setRevertHtml] = useState('')
  const [revertDoc, setRevertDoc] = useState('')

  const [revertApprovalOpen, setRevertApprovalOpen] = useState(false)

  const [revertRequest, setRevertRequest] = useState('')
  const [revertRequestModalOpen, setRevertRequestModalOpen] = useState(false)

  const previewConfig = {
    style: {
      zIndex: 9999,
      position: 'absolute', // Ensure it's positioned to apply z-index
      backgroundColor: "red"
      // You can add more styles here if needed
    }
  };
  const config = useMemo(
    () => ({
      branding: false,
      statusbar: false,
      readonly: isreadonly,
      height: 480,
      allowResizeX: false, // Disable horizontal resizing
      allowResizeY: false, // Disable vertical resizing
      controls: {
        paragraph: {
          list: Jodit.atom({
            p: 'Paragraph',
            h1: 'Heading 1',
            h2: 'Heading 2',
            h3: 'Heading 3',
            h4: 'Heading 4',
            blockquote: 'Quote',
          })
        }
      },
      uploader: {
        insertImageAsBase64URI: true, // Enable Base64 encoding
      }

    }),
    [isreadonly]
  );



  const [wordCount, setWordCount] = useState(0);

  const [addManualClause, setAddManualClause] = useState("");
  const [sharedOwnerId, setsharedOwnerId] = useState("");
  const [displayCount, setDisplayCount] = useState(5); // Initial count of messages to display
  const [activityData, setActivityData] = useState([]);
  const assetType = "rfp";
  const commentType = "comments";
  const noteType = "note";
  const editor = useRef(null);
  const editor1 = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSectionSharing, setIsSectionSharing] = useState("");

  const [improvedInputState, setImprovedInputState] = useState(null);
  const [improveInputModal, setImproveInputModal] = useState(null);
  const [inputBtnType, setInputBtnType] = useState(null);
  const [editshow, setEditshow] = useState(false);

  const [acceptedSections, setAcceptedSections] = useState([]);

  const [allSharedAsset, setallSharedAsset] = useState();

  const [allTaskAssignments, setallTaskAssignments] = useState();

  const [mainAssetid, setmainAssetid] = useState();

  const [success, setSuccess] = useState(false);

  const [taskDetail, setTaskDetail] = useState();

  const [assignbyUser, setAssignbyUser] = useState();

  const [rawUpdatedContent, setRawUpdatedContent] = useState("");

  const [acceptedBothData, setAcceptedBothData] = useState("");

  // console.log(statusAccepted, "statusAcceptedstatusAccepted");
  const [clauseDataEditable, setClauseDataEditable] = useState(false);
  const [clauseProjectData, setClasueProjectData] = useState({});
  const [isClauseProjectModalOpen, setIsClauseProjectModalOpen] = useState("");
  const [selectedClause, setSelectedClause] = useState("");
  const [selectedClauseObjectId, setSelectedClauseObjectId] = useState("");
  const [selectedClauseDocumentId, setSelectedClauseDataId] = useState("");
  const [selectedClauseAssetId, setSelectedClauseAssetId] = useState("");
  const [downloadFileName, setDownloadFileName] = useState("");
  const [showClauseDownloadModal, setClauseshowDownloadModal] = useState("");
  const [assetData, setAssetData] = useState();
  let screenName = "RFP Management";
  const [showEditButton, setShowEditButton] = useState("");
  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const userEmail = sessionStorage.getItem("currentUserEmail")
  const featureArray = features?.featuresAccess;
  const [initialAsssetSectionData, setInitialAssetSectionData] = useState([]);
  const [cleanedInitialContent, setInitialCleanedContent] = useState("");
  const [initialSummariseDiscription, setInitialSummariseDiscription] =
    useState("");
  const [isSearchTerm, setIsSearchTerm] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [filteredSectionData, setFilteredSectionData] =
    useState(asssetsectionData);
  const [isFilteredAndSearch, setIsFilteredAndSearch] = useState(false);
  const [initialfilteredSectionData, setinitialfilteredSectionData] =
    useState(false);

  const [isAssignedToCurrentUser, setIsAssignedToCurrentUser] = useState(false)

  // const isAssignedToCurrentUser = asssetsectionData?.some((document) =>
  //   document?.taskAssignWith?.some(
  //     (task) => task?.assignToEmail === cuurentUser
  //   )
  // );
  const [isTaskAssignmentModalOpen, setTaskAssignmentModalOpen] =
    useState(false);
  const [assetRequestStatusData, setAssetRequestStatusData] = useState([]);
  const [statusValidation, setStatusValidation] = useState(false)
  const [newSectionTitle, setnewSectionTitle] = useState();
  const [aiRequirnmentsArray, setaiRequirnmentsArray] = useState([]);
  const [versionSectionId, setVersionSectionId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [showReorderModal, setshowReorderModal] = useState(false);
  const [modalOpenshr, setModalOpenshr] = useState(false);
  const [modalOpenSectionshr, setModalOpenSectionshr] = useState(false);
  const [users, setUsers] = useState([]);
  const [existingusers, setExistingUsers] = useState([]);
  const [existingSection, setExistingSection] = useState([""]);
  const [showPopups, setShowPopups] = useState(false);
  const [farClauses, setFarClauses] = useState([])
  const [dfarClauses, setDfarClauses] = useState([])
  const [selectedSectionMap, setSelectedSectionMap] = useState()
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false)


  const [accepted, setAccepted] = useState(false);
  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
    const matchingFeature = featureArray.filter(
      (feature) => feature.ScreenName == screenName
    );

    if (matchingFeature) {
      accessFeatures = matchingFeature[0].features;

    } else {
    }
  } else {
    // console.log("No features found in the storage.");
  }
  const [versionModalOpen, setVersionModalOpen] = useState(false);
  const [isOpenattachmentModal, setIsOpenAttachmentModal] = useState(false);
  const [opportunity, setOpportunity] = useState(null);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [resourceLinks, setResourceLink] = useState([]);
  const [documentData, setDocumentData] = useState(null);
  const [showSection, setShowSection] = useState(false);
  const [showRequirnments, setShowRequirnments] = useState(false);
  const [showMapping, setShowMapping] = useState(false);
  const [aiRequirnmentsData, setAiRequirnmentsData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileContent, setSelectedFileContent] = useState(null);
  const [requirementMappingData, setRequirementMappingData] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [dummy, setDummy] = useState([]);
  const [dummy1, setDummy1] = useState([])
  const [showDownloadMappingModal, setShowDownloadMappingModal] = useState(false);
  const [attachmentPDFText, setAttachmentPDFText] = useState(null);
  const [currentInfo, setCurrentInfo] = useState({
    type: "",
    htmlContent: "",
    title: "",
    name: "",
    sectionId: "",
  });
  const [priorHtmlContent, setPriorHtmlContent] = useState("");
  const [trackedChanges, setTrackedChanges] = useState([]);
  const [temporaryContent, setTemporaryContent] = useState("");
  const [taskAssignmentsectionId, setTaskAssignmentsectionId] = useState("");
  const [selectedClauseResources, setselectedClauseResources] = useState();
  const [isReplyModelOpen, setIsReplyModelOpen] = useState(false);
  const [selectedCommentForReply, setSelectedCommentForReply] = useState();
  const [replyText, setReplyText] = useState();
  const [showReply, setShowReply] = useState(true);
  const [isNotesReplyModelOpen, setIsNotesReplyModelOpen] = useState(false);
  const [selectedNoteForReply, setSelectedNoteForReply] = useState();
  const [notesReplyText, setNotesReplyText] = useState();
  const [notesReplyUpdateIcon, setNotesReplyUpdateIcon] = useState(false);
  const [commentReplyUpdateIcon, setCommentReplyUpdateIcon] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState();
  const [versionData, setVersionData] = useState([]);
  const [specificVersionData, setSpecificVersionData] = useState([]);
  const [sectionsArray, setSectionsArray] = useState(asssetsectionData);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [taskAssignWithData, setTaskAssignWithData] = useState([]);
  const [isManagement, setIsManagement] = useState(false)
  const [selectedResources, setSelectedResources] = useState({});
  const [effectiveHours, setEffectiveHours] = useState({});
  const [resourceOptions, setResourceOptions] = useState([]);
  const orgId = sessionStorage.getItem("organizationId")
  const [resourcePayRates, setResourcePayRates] = useState({});
  const [totalBillingAmounts, setTotalBillingAmounts] = useState({});
  const [remarks, setRemarks] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState('');
  const [keywordData, setKeywordData] = useState('');
  const [editableRow, setEditableRow] = useState(null);
  const [editedRequirementText, setEditedRequirementText] = useState("");
  const [requirementDataForSuggestion, setRequirementDataForSuggestion] = useState(null);
  const [selectedSectionName, setSelectedSectionName] = useState()
  const [sectionCreateddBy, setSectionCreatedBy] = useState("");
  const projectType = useSelector((state) => state.opportunity.projectType);
  const [sUserRole, setSUserRole] = useState(null);
  const [sUserId, setSUserId] = useState(null);
  const [reportModal, setReportModal] = useState(false)
  const [rows, setRows] = useState(null)
  const [showDownloadModalForReport, setShowDownloadModalForReport] = useState(false)
  const [pathArray, setPathArray] = useState([])
  const [dataArray, setDataArray] = useState([]);
  const [isModalOpenCustom, setIsModalOpenCustom] = useState(false);
  const [clausesInput, setClausesInput] = useState();
  const [openModalClauseAdd, setOpenModalClauseAdd] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [hasYellowOrRedRows, setHasYellowOrRedRows] = useState(false);
  const [requirementMappingHistory, setRequirementMappingHistory] = useState([])
  const [requirementMappingHistoryExists, setRequirementMappingHistoryExists] = useState(false)
  const [retrieveRequirementModal, setRetrieveRequirementModal] = useState(false)
  const [retrieveRequirementClicked, setRetrieveRequirementClicked] = useState(false)
  const [historyClicked, setHistoryClicked] = useState(false)
  const [requirementMappingId, setRequirementMappingId] = useState('')
  const [isOpenBidModel, setIsOpenBidModel] = useState(false);
  const [taskCompletionModal, setTaskCompletionModal] = useState(false)
  const [teamTasks, setTeamTasks] = useState([])
  const [isOpenDeleteReasonModel, setIsOpenDeleteReasonModel] = useState(false);
  const [dataForDeleteClause, setDataForDeleteClause] = useState({
    id: "",
    clause: "",
    dataid: ""
  });
  const [deleteReason, setDeleteReason] = useState("");

  
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [isOpenAddReasonModel, setIsOpenAddReasonModel] = useState(false);
  const [addReason, setAddReason] = useState("");
  const [deletedClauseArray, setDeletedClauseArray] = useState([])
  const [createdTime, setCreatedTime] = useState('')
  const [showDocxPreview, setShowDocxPreview] = useState("")
  const [assignedTaskdetails, setAssignedTaskDetails] = useState()
  const [showDownloadSectionModal, setShowDownloadSectionModal] = useState(false);
  const [downloadContent, setDownloadContent] = useState();
  const [downloadTitle, setDownloadTitle] = useState();
  const [assetDetails, setAssetDetails] = useState(null)
  const [taskApproveStatus, setTaskApproveStatus] = useState('')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const AiData = [
    { label: 'Select All', value: 'select-all' },
    { label: 'Attachment', value: 'Attachment' },
    { label: 'Technical Approach', value: 'Technical Approach' },
    { label: 'Letter of Interest', value: 'Letter of Interest' },
    { label: 'Project Organization Chart', value: 'Project Organization Chart' },
    { label: 'Desired Features', value: 'Desired Features' },
    { label: 'Resumes', value: 'Resumes' },
    { label: 'Firm Financial Information ', value: 'Firm Financial Information' },
    { label: 'Cost Summary', value: 'Cost Summary' },
    { label: 'Executive Summary', value: 'Executive Summary' },
    { label: 'Needs Assessment', value: 'Needs Assessment' },
    { label: 'Goals and Objectives', value: 'Goals and Objectives' },
    { label: 'Benefits', value: 'Benefits' },
    { label: 'Project Deliverables', value: 'Project Deliverables' },
    { label: 'Training Plan', value: 'Training Plan' },
    { label: 'Schedule and Milestones', value: 'Schedule and Milestones' },
    { label: 'Expected Result', value: 'Expected Result' },
    { label: 'Project Managment', value: 'Project Managment' },
    { label: 'Communication Plan', value: 'Communication Plan' },
    { label: 'Deployment Recommendations', value: 'Deployment Recommendations' },
    { label: 'Company History', value: 'Company History' },
    { label: 'Similar Projects', value: 'Similar Projects' },
    { label: 'Project Assumptions', value: 'Project Assumptions' },
    { label: 'Team Capabilities', value: 'Team Capabilities' },
    { label: 'Testimonials', value: 'Testimonials' },
    { label: 'Maintenance and Support', value: 'Maintenance and Support' }

  ];
  const sectorData = [
    { label: 'Defense and Aerospace', value: 'Defense and Aerospace' },
    { label: 'Healthcare and Medical Research', value: 'Healthcare and Medical Research' },
    { label: 'Information Technology', value: 'Information Technology' },
    { label: 'Construction and Infrastructure', value: 'Construction and Infrastructure' },
    { label: 'Environmental Services', value: 'Environmental Services' },
    { label: 'Education and Training', value: 'Education and Training' },
    { label: 'Public Safety and Law Enforcement', value: 'Public Safety and Law Enforcement' },
    { label: 'Energy', value: 'Energy' },
    { label: 'Transportation', value: 'Transportation' },
  ];
  const predefinedSections = [
    "Executive Summary",
    "Needs Assessment",
    "Goals and Objectives",
    "Expected Result",
    "Desired Features",
    "Maintenance and Support",
    "Training Plan",
    "Technical Approach",
    "Project Assumption",
    "Project Deliverables"
  ];
  const [selectedSectors, setSelectedSectors] = useState(null)
  const [selectedFields, setSelectedFields] = useState([])
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileView = windowWidth < 600





  const handleCloseRevertRequestModal = () =>{
    setRevertRequestModalOpen(false)
    setRevertRequest('')
  }

  const config2 = {
    toolbar: false,
    readonly: true,

    branding: false,

    statusbar: false,
    height: mobileView ? 300 : 520,
    allowResizeX: false, // Disable horizontal resizing
    allowResizeY: false, // Disable vertical resizing
    uploader: {
      insertImageAsBase64URI: true, // Enable Base64 encoding
    },
  };
  const config3 = {
    toolbar: false,

    branding: false,

    statusbar: false,

    // Set to false to hide the status bar
    height: mobileView ? 300 : 520,
    allowResizeX: false, // Disable horizontal resizing
    allowResizeY: false, // Disable vertical resizing
    uploader: {
      insertImageAsBase64URI: true, // Enable Base64 encoding
    },
  };

  const acceptRequestStyle = {
    fontSize: "11px",
    color: "red",
    borderRadius: "50%",
    WebkitAnimation: "sonarEffect 1.3s ease-out infinite",
    MozAnimation: "sonarEffect 1.3s ease-out infinite",
    animation: "sonarEffect 1.3s ease-out infinite"
  };

  const handleDownloadSectionModalClose = () => {
    setShowDownloadSectionModal(false);
  };
  const downloadFunction = async (name, type, htmlContent, sectionId, title) => {
    try {
      //console.log("name of the file on which i am working ", name)
      if (Array.isArray(name) && typeof name[0] === 'string') { // Check if name is an array and the first element is a string
        const newName = decodeURIComponent(name[0]); // Decode the filename
        const parts = newName.split('/');
        const FilteredName = parts.pop();
        console.log("File name for editor__:", FilteredName);
        const oppoid = rfmManagementData.value.id
        console.log(oppoid, "oppoid")
        const docxContent = await getSectionDocument(FilteredName, userInfo.organizationId, oppoid, rfmManagementData.value.assetid, userInfo._id, sectionId);
        console.log("yes", docxContent, FilteredName)


        if (docxContent.textResponse) {
          // seteditSectionTitle(false)
          // setIsgetDataEditor(true)
          const parsedResponse = JSON.parse(docxContent.textResponse);
          console.log("download resp   ", (parsedResponse.data.documentBody));
          setDownloadTitle(title)

          setDownloadContent(parsedResponse.data.documentBody)
        }
      }
    } catch (error) {
      console.error('Error fetching and Section file content:', error);
    }
  }
  const handleSectionDownload = async (documentpath, type, htmlContent, _id, title, createdby) => {
    let featurename = "Download";
      const checkfeatureName = accessFeatures?.filter(
        (k) => k.name == featurename
      );
      // console.log(checkfeatureName[0].access, "checkfeatureName");
      if (!checkfeatureName[0].access) {
        toast.error("You are not allowed to download")
      } else {
    setShowDownloadSectionModal(true);
    downloadFunction(documentpath, type, htmlContent, _id, title);
      }
  };


  const downloadSectionSinglePDF = () => {
    if (downloadContent === undefined) {
      showToast('Proposal.nothingDownload')
      setShowDownloadSectionModal(false)
      return;
    }

    let fileName = downloadTitle
    const pdfContent = `<div><h5>${fileName}</h5><p>${downloadContent}</p></div>`;

    const element = document.createElement('div');
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: `${fileName}.pdf`, // Set the filename to the document name
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }).then((pdf) => {
      saveAs(pdf, `${fileName}.pdf`);
    });
    //onHide();
    setShowDownloadSectionModal(false)
  };


  const handleOpenRejectDialog = () => {
    setOpenRejectDialog(true);
  };

 
  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
    // handleAcceptClose(); 
  };


  function updateImgWidth(match, p1, p2, p3) {
    // p2 is the current width value
    const currentWidth = parseInt(p2, 10);
    // Check if the current width is less than 600
    if (currentWidth < 600) {
      return `<img${p1}width="${currentWidth}"${p3}>`;
    }
    // If the current width is 600 or more, set it to 600
    return `<img${p1}width="600"${p3}>`;
  }



  const handleSectionDocxDownload = async () => {
    if (downloadContent === undefined) {
      showToast(``)
      setShowDownloadSectionModal(false)
      return
    }
    let fileName = downloadTitle

    let htmlString = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>${fileName}</title>
    </head>
    <body>
        <h3>${fileName}</h3>
        <hr/>
        ${downloadContent}
    </body>
    </html>`;

    htmlString = htmlString.replace(/<img([^>]*)width="(\d+)"([^>]*)>/g, updateImgWidth);


    htmlString = htmlString.replace(/<img((?!width)[^>]*)>/g, '<img$1 width="600">');

    asBlob(htmlString).then(docx => {
      saveAs(docx, `${fileName}.docx`);
      setShowDownloadSectionModal(false) // Close the modal after the download
    });
  };
  const fetchAssignedTaskdetails = async () => {
    try {
      const data = {
        assetId: rfmManagementData.value.assetid,
        assetType: "rfp"
      }
      const response = await getTaskAssignedDetails(data);
      console.log(response, "_____ress")
      setAssignedTaskDetails(response.taskAssignmentData);
    } catch (err) {
      toast.error(err.response?.data?.error || 'Error fetching asset');
    }
  };

  const fetchAssetData = async () => {
    try {
      const assetId = rfmManagementData.value.assetid
      const response = await getasset(assetId);
      setCreatedTime(response.data.createdTimeAgo);
      setAssetDetails(response.data)
      if (response?.data?.aiSections?.length > 0) {
        setOpenAiSectionsList(response?.data?.aiSections)
        console.log(response?.data?.aiSections, "setAssetDetails")
      }

    } catch (err) {
      toast.error(err.response?.data?.error || 'Error fetching asset');
    }
  };

  useEffect(() => {
    fetchAssetData()
  }, [])


  const handleNext = () => {
    console.log(currentIndex + 1, tasks.length - 1, "datacurrentindex")
    if (currentIndex < tasks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  console.log(currentIndex, "currentIndex")

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };


  const closeTaskCompletionModal = () => {
    setTaskCompletionModal(false)
  }
  useEffect(() => {
    fetchAssignedTaskdetails()
  }, [])

  console.log(assignedTaskdetails, "assignedTaskdetails")




  const formatDate2 = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  };

  const handleBidModalOpen = () => {
    setIsOpenBidModel(true);
  };
  const handleBidModalclose = () => {
    setIsOpenBidModel(false);
  };
  const applicationAdmin = "Application Admin"
  // const systemAdmin = "System Admin"
  const hasRequiredRole = userInfo?.userRole?.includes(applicationAdmin);
  console.log(userInfo.userRole, "UUUU")



  function extractPartNumber(reference) {
    console.log("reference123  ", reference)
    // Check if reference is a string
    if (typeof reference === 'string') {
      // Extract the number from the reference string
      const number = reference.match(/\d+/);
      console.log("extractPartNumber234  ", number)
      return number ? parseInt(number[0]) : null;
    } else {
      console.error("Reference is not a string:", reference);
      return null;
    }
  }



  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format the date
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    // Format the time
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  };

  const createDataArray = (createdBy, createdDate, modifiedBy, modifiedDate) => {
    const formattedModifiedDate = modifiedDate.map(date => formatDate(date));

    setDataArray(prevDataArray => [
      ...prevDataArray,
      {
        createdBy,
        createdDate: formatDate(createdDate),
        modifiedBy,
        modifiedDate: formattedModifiedDate,
      }
    ]);
  };





  function findFilePath(partNumber, FarClauseData) {
    console.log("arrayGet   ", partNumber, "&& ", FarClauseData);

    // Iterate through each part in the FarClauseData
    for (const partData of FarClauseData.data) {
      // Extract the part number from the partName
      const partNum = parseInt(partData.part.partName.match(/\d+/)[0]);

      if (partNum === partNumber) {

        console.log("partttt ", partData)

        console.log("filePath321  ", partData.filePath);
        console.log("CreatedBy  ", partData.createdBy)
        console.log("createdDate  ", partData.createdDate)
        console.log("modifiedBy  ", partData.modifiedBy)
        console.log("modifiedDate  ", partData.modifiedDate)
        const newDataArray = createDataArray(
          partData.createdBy,
          partData.createdDate,
          partData.modifiedBy,
          partData.modifiedDate
        );
        console.log("dataArrray  ", newDataArray)

        return partData.filePath;
      }
    }

    // Return null if no matching part is found
    return null;
  }


  function getFilePaths(references, FarClauseData) {
    const filePaths = [];
    // Iterate through each reference
    for (const reference of references) {
      // Extract the part number from the reference
      const partNumber = extractPartNumber(reference.clause);
      if (partNumber !== null) {
        // Find the filePath for the extracted part number
        const filePath = findFilePath(partNumber, FarClauseData);
        if (filePath) {
          // Add filePath to the list if found
          filePaths.push(filePath);
        }
      }
    }
    // Return array of filePaths
    return filePaths;
  }


  const getFarData = async () => {
    let data = "FAR";
    try {
      let response = await getFarParts(data)
      return response
    }
    catch (error) {
      showToast('Error.errorOccured')
    }


  }


  const openReportModal = async () => {
    if (!assetData?.isBidRequested) {
      let data = {
        resourceLinks: assetData?.opportunityid?.resourceLinks,
        assetId: assetData?._id,
        email: sessionStorage.getItem("currentUserEmail"),
      };

      try {
        setLoading(true);
        let FarClauseData = await getFarData();
        setFarData(FarClauseData);
        console.log("res2 ", FarClauseData);

        const response = await getResourceLinks(data);
        console.log("extracted clauses:", response);

        if (response.status === 201) {
          let transformData = transformResponseData(response.data);
          console.log("32mx  ", transformData);
          setRows(transformData);
          setReportModal(true);

          const result = getFilePaths(transformData, FarClauseData);
          setPathArray(result);
        } else if (response.status === 400) {
          try {
            const value = assetData?._id;
            let featurename = "Clause";
            const checkfeatureName = accessFeatures?.filter(
              (k) => k.name === featurename
            );

            let data = {
              assetid: rfmManagementData.value.assetid,
              email: sessionStorage.getItem("currentUserEmail"),
            };
            if (value !== true) {
              setLoading(true);
            }
            const response = await getClauses(data);

            let transformData = transformResponseData(response.data);
            console.log("32mx  ", transformData);
            setRows(transformData);
            setReportModal(true);

            const result = getFilePaths(transformData, FarClauseData);
            setPathArray(result);
          } catch (e) {
            console.log(e, "error in getExtractedClause");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      if (assetData?.acceptBidRequest) {
        return showToast(`RFP.rfpBidSuccessfully`)
      }
      showToast(`RFP.rfpBidRequested`)
    }
  };



  function transformResponseData(data) {
    return data.clauses.reduce((acc, clause) => {
      const clausesArray = typeof clause.responseData === 'string'
        ? clause.responseData.split('\n')
          .filter(clauseText => clauseText !== 'no clauses found') // Filter out 'no clauses found'
          .map(clauseText => ({ clause: clauseText }))
        : clause.responseData.filter(clauseObj => clauseObj.clause !== 'no clauses found'); // Handle pre-processed arrays

      return acc.concat(clausesArray);
    }, []);
  }




  const downloadExcelForReport = () => {
    const wb = XLSX.utils.book_new();
    const ws_data = [
      ["FAR Clauses", "Version", "Customization Details", "User"]
    ];

    rows.forEach((clause, index) => {
      const versions = ["version 1.0"];
      if (pathArray[index] && pathArray[index].length > 0) {
        pathArray[index].forEach((_, index1) => {
          versions.push(`version ${index1 + 2}.0`);
        });
      }

      const customizations = ["Added"];
      if (pathArray[index] && pathArray[index].length > 0) {
        pathArray[index].forEach((_, index1) => {
          customizations.push(`Modified ${index1 + 1}.0`);
        });
      }

      const user = dataArray[index]?.createdBy ? dataArray[index]?.createdBy : "N/A";
      const modifiedBy = dataArray[index]?.modifiedBy.length > 0 ? dataArray[index]?.modifiedBy.join(", ") : "N/A";

      ws_data.push([
        clause.clause,
        versions.join(", "),
        customizations.join(", "),
        `${user}\n${modifiedBy}`
      ]);
    });

    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Report.xlsx");
    setShowDownloadModalForReport(false)

  };



  const downloadWordDocumentForMapping = () => {
    const tableHtml = document.getElementById("table-to-word").outerHTML;
    const converted = htmlDocx.asBlob(tableHtml);
    saveAs(converted, "table.docx");
  };
  console.log(particularSection, "taskassigniddd");

  let assignedBy;

  const handleSubmitteTask = async (id, sectionTitle) => {

    // console.log(id, "idid");

    // alert(assetsectionId);

    // alert("checkingsubmission");

    const workflowName = `Task Assignment`;

    const fromStageName = `Open`;

    const toStageName = `Open`;

    const FromStatusName = `Accepted`;

    const ToStatusName = `Task Submitted`;

    const orgId = userInfo.organizationId;

    const assetId = mainAssetid;

    const assetSectionId = assetsectionId;

    const assetType = particularSection?.assetType;

    const assignedTo = cuurentUser;

    const taskassigid = particularSection?.taskassignids;

    const link = particularSection?.name;

    const status = "submit";

    allSharedAsset.forEach((item) => {
      // Check if any object's id matches the assetSectionId

      const matchedSection = item.assetSection.find(
        (section) => section.id === assetSectionId
      );

      if (matchedSection) {
        assignedBy = item;


      } else {
        // console.log("No matching section found in object:", item);
      }
    });

    assignedBy = assignedBy?.sharedBy;

    // console.log(assignedBy, "sharedByEmail");

    // console.log(assetType, assignedBy, taskassigid, "kalpesh");

    try {
      const response = await decisionByReceiver(
        assetType,
        assetsectionId,
        assetId,
        taskassigid,
        userInfo.organizationId,
        cuurentUser,
        assignedBy,
        status,
        link
      );

      // console.log(response, "=============");

      if (response) {
        // console.log(response?.data, "rerrerererecheck");

        setTaskDetail(response?.data);

        getAssetSectionData();

        getTaskAssignments();
        showToast(`Proposal.taskSubmit`);
        const activityResponse = await addActivity(rfmManagementData.value.assetid, assetType, activityMsg.submitForReview, userInfo.username, selectedSectionName)

        handleGetActivity();
        setIsgetDataEditor(false);
      }
    } catch (error) {
      // console.log(error, "responseerror");
    }

    setSubmittedStatus(true);

    setIsgetDataEditor(false);
  };
  // console.log(asssetsectionData, "vrushabhdata");

  const handleAcceptClose = () => {
    setOpen(false);

    setOpenPopup(false);
  };


  const handleOpenDownloadMappingModal = () => {
    setShowDownloadMappingModal(true);
  };
  const handleDownloadMappingModalClose = () => {
    setShowDownloadMappingModal(false);
  };

  const handleDownloadModalOpen = () => {
    setShowDownloadModal(true);
  };
  const handleDownloadModalClose = () => {
    setShowDownloadModal(false);
  };



  const setShowPopup = (assetType, assetsectionId, assetId, taskassignid, taskAssignWith,) => {
    console.log(assetType, assetsectionId, assetId, taskassignid, taskAssignWith, "kkkkkkk");

    setmainAssetid(assetId);

    setOpen(assetsectionId);
  };

  const setShowPopupfoApproval = (
    assetType,
    assetsectionId,
    assetId,
    taskassignid,
    taskAssignWith
  ) => {
    console.log(assetType, assetsectionId, assetId, taskassignid, taskAssignWith, "kkkkkkk");
    setTaskAssignWithData(taskAssignWith)
    setmainAssetid(assetId);

    setOpenPopup();
    setTaskApprovalOwnerData('')
    setTaskApprovalReceiverData('')
    setOpenJoditPopup(assetsectionId)
  };

  const getaalassetsharings = async () => {
    try {
      const response = await getAllassetsharing();

      // console.log(response, "asdfghjk");

      setallSharedAsset(response.data);
    } catch (error) {
      console.log(error, "errorerror");
    }
  };
  useEffect(() => {
    getaalassetsharings();
    fetchClausesList()
    getTaskCompletionOnpageLoad()
  }, []);

  const getTaskAssignments = async () => {
    try {
      const response = await getAllTaskAssignments();

      // console.log(response, "responseresponse");

      setallTaskAssignments(response.data);
    } catch (error) {
      console.log(error, "errorerror");
    }
  };

  useEffect(() => {
    getTaskAssignments();
  }, []);

  // console.log(allTaskAssignments, "allTaskAssignments");
  const handleAcceptClickforApproval = async () => {
    setOpennewPopup(true);

    try {
      allSharedAsset("working");
    } catch (error) { }
  };

  const handleAcceptClick = async (
    assetType,
    assetsectionId,
    assetId,
    taskassigid,
    sectionTitle
  ) => {
    console.log(sectionTitle, "sectionTitle");
    // alert(taskassigid);

    try {


      const assetSectionId = assetsectionId;

      const assetId = mainAssetid;

      allSharedAsset.forEach((item) => {
        // Check if any object's id matches the assetSectionId

        const matchedSection = item.assetSection.find(
          (section) => section.id === assetSectionId
        );

        if (matchedSection) {
          assignedBy = item;


        } else {
          console.log("No matching section found in object:", item);
        }
      });

      assignedBy = assignedBy?.sharedBy;


      setAssignbyUser(assignedBy);


      const status = "accepted";

      const response = await decisionByReceiver(
        assetType,
        assetsectionId,
        assetId,
        taskassigid,
        userInfo.organizationId,
        cuurentUser,
        assignedBy,
        status
      );

      if (response.status == 200) {
        getAssetSectionData();

        setSuccess(true);

        setStatusAccepted(true);
        showToast(`Proposal.taskAccept`);
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.taskAccepted,
          userInfo.username,
          sectionTitle
        );

        handleGetActivity();
      }

      // console.log(response, "rerrererere");
    } catch (error) {
      console.log(error, "errorerror");
    }

    setEditshow(true);

    setAcceptedSections((prev) => [...prev, assetsectionId]);

    setOpen(false);
  };

  const handleRejectClick = async (
    assetType,
    assetsectionId,
    // assetId,
    taskassigid,
    document
  ) => {
    console.log("trrigerd  of rejcect click ", assetType, assetsectionId, taskassigid, document)

    // const assetsectionId = sectionId
    const assetId = mainAssetid;

    allSharedAsset.forEach((item) => {
      // Check if any object's id matches the assetsectionId

      const matchedSection = item.assetSection.find(
        (section) => section.id === assetsectionId
      );

      if (matchedSection) {
        assignedBy = item;

        // console.log("Found matching section in object:", item);

        // console.log("Matching section:", matchedSection);
      } else {
        console.log("No matching section found in object:", item);
      }
    });

    assignedBy = assignedBy?.sharedBy;
    console.log("assignedBy@", assignedBy)
    // console.log(assignedBy, "assignedByassignedBy");

    setAssignbyUser(assignedBy);

    // console.log(assignedBy, "sharedByEmail");

    const status = "reject";

    const response = await decisionByReceiver(
      assetType,
      assetsectionId,
      assetId,
      taskassigid,
      userInfo.organizationId,
      cuurentUser,
      assignedBy,
      status
    );

    if (response.status == 200) {
      getAssetSectionData();

      setSuccess(true);
      setOpen(false);
      setStatusAccepted(true);
      showToast(`Proposal.taskReject`);
      handleCloseRejectDialog()
    }


    const activityResponse = await addActivity(
      rfmManagementData.value.assetid,
      assetType,
      activityMsg.taskRejected,
      userInfo.username,
      sectionTitle
    );

    handleGetActivity();
  };




  const improvedDocData = async (scopeContent) => {
    // console.log("12345   ", scopeContent);

    // setScopeModal(true)
    const parser = new DOMParser();
    const doc = parser.parseFromString(scopeContent, "text/html");
    // console.log("54321  ", doc.body.textContent);
    setLoading(true);
    try {
      let responce = await ImprovedDocData(doc.body.textContent);
      // console.log("data in scopee  ", responce);
      setImprovedInputState(responce);
      setImproveInputModal(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleAccordionClick = () => {
    setExpanded(!expanded);
  };

  const rfmManagementData = useSelector((state) => state.rfp);
  useEffect(() => {
    console.log("props passed to this component", rfmManagementData);
    handleReduxValue(rfmManagementData.value);
    const firstValue = rfmManagementData?.value?.agancy?.split('.')[0];

    if (firstValue) {
      setDepartmentSector(firstValue)
      console.log("firstValue", firstValue)
    }

  }, [rfmManagementData]);

  useEffect(() => {
    console.log("Proppppppppppppp", propsPassed);
  }, [propsPassed]);

  const handleReduxValue = (rfpData) => {
    setPropPassed(rfpData);
    // console.log("propPassed", rfpData.title);
    getAssetSectionData();
    getDescriptionData();
    const convertToDate = (dateTimeString) => {
      if (dateTimeString) {
        const date = new Date(dateTimeString);
        return date.toISOString().split("T")[0];
      }
    };

    handleHtmlContent(rfpData.descriptionData);

    const dateTimeString = rfpData.responseDeadLine;
    // commented as of now as date is comming in date formate
    const convertedDate = convertToDate(rfpData.date1);
    setNormalDate(convertedDate);

    if (rfpData.agancy) {
      const splitDepartment = rfpData.agancy.split(".");

      const last_value = splitDepartment[splitDepartment.length - 1];
      // console.log(last_value, "last_value")
      const first_value = splitDepartment[0];
      setAgancyName(first_value);
      // console.log("First value", first_value)
    }

    handleGetList();
    handleColorforDate();
    handleGetComment();
    handleGetTypeComment();
    handleGetActivity();
    getSectionSharedfunction();
  };

  const countNotes = () => {
    if (notesData) {
      const count = notesData.length;
      const commentCount = commentData.length;
      const activityiesCount = activityData.length;
      setNoteCount(count);
      setCommentCount(commentCount);
      setActivityCount(activityiesCount);
      // console.log("count of note",count,commentCount)
    }
  };

  useEffect(() => {
    countNotes();
  }, [notesData, commentData, activityData]);

  const getDescriptionData = async () => {
    // console.log("oppopopopopo", rfmManagementData.value.id);
    try {
      const response = await getopportunities(rfmManagementData.value.id);
      if (response) {
        // console.log("Descrption response is ", response);
        setOpportunity(response);
      }
    } catch (e) {
      console.log("error in getting description ", e);
    }
  };
  const handleHtmlContent = (htmlContent) => {
    console.log(htmlContent, "___VV")
    if (htmlContent == undefined) {
      setCleanedContent("Description not provided");
      setInitialCleanedContent("Description not provided");
    } else {
      const cleanedText = removeHtmlTags(htmlContent);
      setCleanedContent(cleanedText);
      setInitialCleanedContent(cleanedText);
    }
  };
  const handleClosePopup = () => {
    setPopupOpen(false);
    setFileURL();
    setShowDocxPreview("")
  };
  const handleGetList = async () => {
    try {
      const response = await getList();
      if (response) {
        const filteredData = response.filter((item) => {
          const parts = item.split("/");
          return parts.length > 1 && parts[1] === rfmManagementData.value.id; // Replace yourStateId with the ID stored in your state
        });

        const names = filteredData.map((item) => {
          const parts = item.split("/");
          return parts.length > 2 ? parts[2] : null;
        });

        // console.log("Filtered data:", filteredData);
        // console.log("Names:", names);
      }
    } catch (e) {
      console.log("Error in getting list data", e);
    }
  };
  const getAssetSectionData = async () => {
    // console.log("Check 1", propsPassed);
    try {
      const response = await getAssetsection(
        rfmManagementData.value.id,
        assetType,
        rfmManagementData.value.assetid
      );
      if (response) {
        // console.log("response of asssetsection ", response);
        setAssetSectionData(response.data);
        setInitialAssetSectionData(response.data);
        const checkdata = response.data;

        const countApproved = checkdata?.filter(
          (item) => item.statusofSection === "Task Approved"
        ).length;

        // console.log(countApproved, "countApproved");
      } else {
        console.log("error in response");
      }
    } catch (e) {
      console.log(e, "errrororrooror");
    }
  };

  console.log(asssetsectionData, "asssetsectionData")

  useEffect(() => {
    if (opportunity !== null) {
      // Extract resourceLinksPath and resourceLinks arrays from the API response
      const resourceLinksPath = opportunity?.data?.resourceLinksPath;
      const resourceLinks = opportunity?.data?.resourceLinks;

      // Create a new array to store the combined data
      const combinedResources = [];

      // Loop through resourceLinksPath to create objects with path, linkname, and corresponding URL
      if (resourceLinksPath) {
        resourceLinksPath.forEach((pathObj, index) => {
          const { path, linkname } = pathObj;
          const url = resourceLinks[index]; // Get corresponding URL from resourceLinks array

          // Create an object with path, linkname, and URL
          const combinedObject = {
            path,
            linkname,
            url,
          };

          // Push the combined object into the array
          combinedResources.push(combinedObject);
        });
      }


      setDocumentData(combinedResources);
    }
  }, [opportunity]);

  const DownloadAttachments = async (fileName, path) => {
    try {
      // alert(fileName);
      const id = rfmManagementData?.value?.id;
      const parts = path.split("/");
      const encodedFileName = parts.pop();
      let clause;
      if (!id) {
        throw new Error("Document ID is not available.");
      }
      const response = await axios.get(
        `https://api.contractpro360.com/api/opportunities/getFile/${id}/${clause}/${encodedFileName}`,
        {
          responseType: "blob", // Set responseType to 'blob' to handle binary data
        }
      );
      // console.log(response, "downloadResponse");

      if (response.status !== 200) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      } else {

        // Create a Blob object with appropriate MIME type
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Create a temporary URL for the blob object
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;

        // Trigger a click event on the link to initiate the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Cleanup: revoke the temporary URL
        window.URL.revokeObjectURL(url);

        // Display success message
        const toastId = "download-success";
        if (!toast.isActive(toastId)) {
          showToast('Success.fileDownload')
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      showToast('Error.errorOccured')
    }
  };

  const handlePreviewClick = (name, path) => {
    if (name === "Blank Template") {
      setIsPreviewOpen(true);
      setContent("");
      return;
    }

    previewAttachments(name, path);
  };

  const convertBlobToText = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  };

  async function previewAttachments(fileName, path) {
    try {
      let docFormat = fileName.slice(-4).replace(/\./g, "");
      // const newName = decodeURIComponent(path[0]); // Decode the filename
      const parts = path.split("/");
      const encodedFileName = parts.pop(); // Get the last part of the URL (filename with URL encoding)

      // Decode the URL-encoded filename
      const decodedFileName = decodeURIComponent(encodedFileName);
      // console.log(decodedFileName, encodedFileName, "all data");
      const id = rfmManagementData?.value?.id;
      let clause;
      if (!id) {
        throw new Error("Document ID is not available.");
      }

      const docxContent = await axios.get(
        `https://api.contractpro360.com/api/opportunities/getFile/${id}/${clause}/${encodedFileName}`,
        {
          responseType: "blob", // Set responseType to 'arraybuffer' to receive binary data
        }
      );

      const pdfDataArray = new Uint8Array(docxContent.data); // Convert ArrayBuffer to Uint8Array

      // Convert Uint8Array to base64 string
      const pdfDataBase64 = Buffer.from(pdfDataArray).toString("base64");

      // console.log("docxContent", docxContent);
      // console.log(docFormat, "docFormat");
      if (docxContent.status == 200) {
        if (docFormat == "doc") {
          showToast(`Warning.noPreview`);
        } else if (docFormat == "docx") {

          console.log(docxContent, "doxcc")
          const blob = docxContent.data; // Assuming you get a Blob here
          const textContent = await convertBlobToText(blob);
          console.log(textContent, "textContent")
          setPreviewContent(textContent);
          setIsPreviewOpen(true);
        } else if (docFormat == "html") {
          // console.log("EditorResponce  ", docxContent.textResponse);
          setPreviewContent(docxContent.textResponse);
          setIsPreviewOpen(true);
        } else if (docFormat == "pdf") {
          const pdfUrl = URL.createObjectURL(docxContent.data);
          // setPreviewContent(pdfUrl);
          setFileURL(pdfUrl);
          setPopupOpen(true);
          // setIsPreviewOpen(true);
        }
      } else {
        showToast(`Error.errorOccured`);
      }
    } catch (error) {
      console.error("Error fetching and processing file content:", error);
      showToast(`Error.errorOccured`);
    }
    setLoading(false);
  }



  const handleFileChange = async (e) => {
    if (!assetData?.isBidRequested) {
      let featurename = "Upload";
      const checkfeatureName = accessFeatures?.filter(
        (k) => k.name == featurename
      );
      // console.log(checkfeatureName[0].access, "checkfeatureName");
      if (!checkfeatureName[0].access) {
        showToast(`Error.upload`);
      } else {
        // console.log("inside upload");
        const file = e.target.files[0];
        if (file) {
          handleUpload(file);
        }
      }
    } else {
      if (assetData?.acceptBidRequest) {
        return showToast(`RFP.rfpBidSuccessfully`)
      }
      showToast(`RFP.rfpBidRequested`)
    }
  };

  const handleUpload = async (file) => {
    console.log("FFFFF  ", file)
    setLoading(true);
    const formData = new FormData();
    formData.append("filename", file);
    formData.append("orgName", userInfo.organizationName);
    formData.append("opportunityid", rfmManagementData.value.id);
    formData.append("assetid", rfmManagementData.value.assetid);
    formData.append("userId", userInfo._id);
    formData.append("assetType", assetType);
    formData.append("orgId", userInfo.organizationId);
    formData.append('createdbyName', userInfo.username)


    try {
      const response = await axios.post(
        `https://api.contractpro360.com/api/newbucket/newuploads/${rfmManagementData.value.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status == 200) {
        // console.log("upload response", response);
        showToast(`Success.fileUpload`);
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.uploadFile,
          userInfo.username
        );
        handleGetActivity();
        getAssetSectionData();
      } else {
        console.log("error in upload");
      }
      // Handle success
      // console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const Username = sessionStorage.getItem("currentUserEmail");
    setCuurentUserName(Username.split('@')[0])
    setCuurentUser(Username);
    getCuurentUser(Username);

    const fetchData = async () => {
      const response = await getAssetData();

      // if (response) {
      //   console.log(" ",response)
      //   handleClauseExtractionOnPageLoad(response);
      // }
    };
    getAllUserForSameOrgData();
    fetchData();

  }, [])
  useEffect(() => {
    if (userInfo) {
      setSUserRole(userInfo.userRole[0]);
      setSUserId(userInfo._id);
    }
  }, [userInfo]);
  console.log(sUserRole, "__sUserRolerfp")
  console.log(sUserId, "_sUserIdrfp")

  const getCuurentUser = async (Username) => {
    try {
      const response = await getUserInfo(Username);
      // console.log("userinfo", response);
      setUserInfo(response.data);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const isAdmin = userInfo?.userRole?.includes("System Admin") || userInfo?.userRole?.includes("Application Admin");
    setIsManagement(isAdmin)
  }, [userInfo])

  useEffect(() => {

    const isSubAdmin = userInfo?.userRole?.includes("Application Admin")
    setIsAdmin(isSubAdmin)
  }, [userInfo])


  const handleAddopenModal = () => {
    if (!assetData?.isBidRequested) {
      let featurename = "Add Section";
      const checkfeatureName = accessFeatures?.filter(
        (k) => k.name == featurename
      );
      // console.log(checkfeatureName[0].access, "checkfeatureName");
      if (!checkfeatureName[0].access) {
        showToast(`RFP.addSection`);
      } else {
        setTitle("");
        setaddDescription("");
        setOpenAddModal(true);
      }
    } else {
      if (assetData?.acceptBidRequest) {
        return showToast(`RFP.rfpBidSuccessfully`)
      }
      showToast(`RFP.rfpBidRequested`)
    }
  };
  const AddSectionData = async () => {
    try {
      if (!adddescription || !title) {
        return showToast(`Proposal.titleReq`)
      }
      const response = await addAssetsection(
        rfmManagementData.value.assetid,
        adddescription,
        title,
        assetType,
        "Manual",
        userInfo.organizationId,
        userInfo._id,
        userInfo.username
      );
      if (response.status == 200) {

        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.addSection,
          userInfo.username
        );
        showToast(`Success.dataAdded`, {
          toastId: 'success1',
        })
        handleGetActivity();
        getAssetSectionData();
        setOpenAddModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAppNameChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setaddDescription(event.target.value);
  };

  useEffect(() => {
    console.log("READSSSSS", isreadonly)
  }, [isreadonly])

  const handlegetFileName = (
    name,
    type,
    htmlContent,
    sectionId,
    title,
    assetType,
    taskassignwith,
    keydata,
    createdby
  ) => {
    console.log(name,
      type,
      htmlContent,
      sectionId,
      title,
      assetType,
      createdby,
      taskassignwith,
      keydata, "checkkkkkkkkkkk")
    let featurename = "Preview";
    setSectionCreatedBy(createdby)
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    // console.log(checkfeatureName[0].access, "checkfeatureName");
    setnewSectionTitle(title);
    if (!checkfeatureName[0].access) {
      showToast(`RFP.previewCheck`);
    } else {
      // console.log("before change name ", name);
      // console.log("type of selected accordian ", type);
      // console.log("sectionId", sectionId);


      const currentUser = cuurentUser;
      const foundMatchingObject = taskassignwith?.find(
        (obj) => obj.assignToEmail === currentUser && obj.isSubmitted && obj.statusofSection === "Task performed"
      );

      const isAssigned = taskassignwith?.some(
      (task) => task?.assignToEmail === cuurentUser
    )
      setIsAssignedToCurrentUser(isAssigned)
      // Set isReadOnly to true only when the condition is met; otherwise, it's false

      setIsReadOnly(!!foundMatchingObject);
      const firstReadonlyCondition = !!foundMatchingObject
      console.log("matchingObject", foundMatchingObject, currentUser)
      console.log("isreadonly State", isreadonly)

      setAssetSetionId(sectionId);
      setSelectedSectionName(title)
      const assignByUser = assignbyUser;
      let matchingObject;
      if (taskassignwith.length > 0) {
        matchingObject = taskassignwith?.find(
          (obj) => obj.assignToEmail === currentUser
        );
      }
      console.log(matchingObject, "matchingobj");
      const assignedEmail = matchingObject?.assignToEmail;
      const isTaskAccepted = matchingObject?.isAccepted;
      const isTaskSubmitted = matchingObject?.isSubmitted;
      console.log(assignedEmail, "assignedEmail")
      // If matchingObject is found, get the taskassignid, otherwise assign null

      const taskassignids = matchingObject
        ? matchingObject?.taskassignid
        : null;

      // Print the taskassignid

      console.log(taskassignids, "taskassignidtaskassignid");

      setAssetSetionId(sectionId);
      setSelectedSectionName(title)
      const data = {
        name,
        type,
        htmlContent,
        sectionId,
        title,
        assetType,
        taskassignids,
        keydata,
      };

      setParticularSection(data);
      if (type == "Doc" || type == "doc") {
        if (Array.isArray(name) && typeof name[0] === "string") {
          console.log("isssarray");
          // Check if name is an array and the first element is a string
          const newName = decodeURIComponent(name[0]); // Decode the filename
          const parts = newName.split("/");
          const FilteredName = parts.pop(); // Extract the last element after splitting by '/'
          setFileName(FilteredName);
          fetchDataFile(FilteredName, sectionId);
          // console.log("FilteredName__:", FilteredName);
          return FilteredName;
        } else {
          console.log("Input is not an array containing strings.");
        }
      } else {
        console.log("vvvvvvv");
        setCurrentInfo({ type, htmlContent, title, name, sectionId });
        fetchDataInEditor(type, htmlContent, title, name, sectionId, keydata, createdby, assignedEmail, firstReadonlyCondition, isTaskAccepted, isTaskSubmitted);
        fetchTrackedChanges(
          rfmManagementData.value.assetid,
          sectionId,
          assetType
        );
      }
    }
  };

  const tasks = taskAssignWithData?.filter(
    (task) => task?.statusofSection === "Task performed"
  );

  console.log(tasks, taskAssignWithData, "taskusers");

  const handlegetFileNames = (
    name,
    type,
    htmlContent,
    sectionId,
    title,
    assetType,
    taskassignwith,
    submittedlink,
    content,
    assignToEmail,
    link
  ) => {
    console.log(
      name,
      type,
      htmlContent,
      sectionId,
      title,
      assetType,
      taskassignwith,
      submittedlink,
      content,
      "linkkk"
    );

    let specificdata = {
      name,
      type,
      htmlContent,
      sectionId,
      title,
      assetType,
      taskassignwith,
      submittedlink,
      content,
      assignToEmail,
      link,
    };

    setSpecifiSectiontaskData(specificdata);

    // console.log(specificdata, "console.log");

    const getHighlightedContent = (original, updated) => {
      console.log(original, "updatedcontent", updated);

      // Helper function to strip HTML tags
      const stripHtmlTags = (str) => str.replace(/<[^>]*>/g, '');

      const strippedOriginal = stripHtmlTags(original);
      const strippedUpdated = stripHtmlTags(updated);

      // Get the diff based on the stripped content
      const diff = diffWordsWithSpace(strippedOriginal, strippedUpdated);

      if (!Array.isArray(diff)) {
        return updated;
      }

      let updatedContent = updated; // Copy of the updated content

      // Function to safely replace a word in the content
      const replaceWord = (content, word, replacement) => {
        const escapedWord = word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escape special chars in word
        const regex = new RegExp(`\\b${escapedWord}\\b`, 'g'); // Create regex for the whole word
        return content.replace(regex, replacement); // Replace the word
      };

      diff.forEach((part, index) => {
        const color = part.added
          ? "#A4BCFA"
          : part.removed
            ? "#F8CCAD"
            : "#eefaa0";

        const style = color !== "#eefaa0" ? `background-color: ${color};` : "";

        if (color !== "#eefaa0") {
          // Split part.value into individual words
          const words = part.value.split(/\s+/);

          // Replace each word in the updated content with a <span> tag
          words.forEach(word => {
            if (word.trim()) { // Ignore empty strings
              const replacement = `<span key=${index} style="${style}">${word}</span>`;
              updatedContent = replaceWord(updatedContent, word, replacement);
            }
          });
        }
      });

      return updatedContent;
    };

    const highlightedContent = getHighlightedContent(htmlContent, content);
    console.log("updatedcontent highlighted", highlightedContent)

    setTaskApprovalOwnerData(htmlContent);

    setTaskApprovalReceiverData(highlightedContent);

    setRawUpdatedContent(content);

    setOpennewPopup(true);

    setAcceptedBothData(htmlContent + content);
  };

  // console.log(htmlContent, content,"contentset")

  const handleApprove = async (
    assetType,
    assetsectionId,
    assetId,
    taskassignwith,
    xxx,
    sectionName
  ) => {
    // alert("approved");
    console.log(assetType,
      assetsectionId,
      assetId,
      taskassignwith,
      xxx,
      sectionName, "sectionName");

    handleOpenTaskAssignedCommentModal()

    console.log("specifiSectiontaskData", specifiSectiontaskData)
    const taskassigid = specifiSectiontaskData?.taskassignwith;

    const assignToEmail = specifiSectiontaskData?.assignToEmail;

    const link = specifiSectiontaskData?.link;

    console.log(taskassignwith, "taskassignwithtaskassignwith");

    // console.log(
    //   assetType,
    //   assetsectionId,
    //   assetId,
    //   taskassignwith,
    //   "asdfgasdfg"
    // );
    const organizationId = userInfo.organizationId;
    const status = "approved";
    setTaskApproveStatus("approved")
    const data = {
      assetType,
      assetsectionId,
      assetId,
      taskassigid,
      organizationId,
      cuurentUser,
      assignedBy,
      status,
      link,
      assignToEmail,
      rawUpdatedContent,
      sectionName
    }
    setReviewData(data)


    // console.log(rawUpdatedContent,"rawUpdatedContent")
    // try {
    //   const response = await decisionByReceiver(
    //     assetType,
    //     assetsectionId,
    //     assetId,
    //     taskassigid,
    //     userInfo.organizationId,
    //     cuurentUser,
    //     assignedBy,
    //     status,
    //     link,
    //     assignToEmail,
    //     rawUpdatedContent
    //   );

    //   if (response.status == 200) {
    //     getAssetSectionData();

    //     setSuccess(true);

    //     setStatusAccepted(true);

    //     setSpecifiSectiontaskData(null);
    //     const activityResponse = await addActivity(
    //       rfmManagementData.value.assetid,
    //       assetType,
    //       activityMsg.accepteChanges,
    //       userInfo.username,
    //       sectionName
    //     );

    //     handleGetActivity();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    // Replace original content with updated content

    setTaskApprovalOwnerData(rawUpdatedContent);

    setOpenPopup(false);
    setOpennewPopup(false);
  };

  const handleAcceptBoth = async (
    assetType,
    assetsectionId,
    assetId,
    taskassignwith,
    sectionTitle,
    xxx,
    sectionName

  ) => {
    // Combine both contents in the first editor

    const taskassigid = specifiSectiontaskData.taskassignwith;

    const assignToEmail = specifiSectiontaskData.assignToEmail;

    const link = specifiSectiontaskData.link;

    // console.log(taskassignwith, "taskassignwithtaskassignwith");

    // console.log(
    //   assetType,
    //   assetsectionId,
    //   assetId,
    //   taskassignwith,
    //   "asdfgasdfg"
    // );

    handleOpenTaskAssignedCommentModal()


    const organizationId = userInfo.organizationId;
    const status = "acceptboth";
    setTaskApproveStatus("acceptboth")
    const data = {
      assetType,
      assetsectionId,
      assetId,
      taskassigid,
      organizationId,
      cuurentUser,
      assignedBy,
      status,
      link,
      assignToEmail,
      acceptedBothData
    }
    // handleOpenTaskAssignedCommentModal()
    setReviewData(data)

    // try {
    //   const response = await decisionByReceiver(
    //     assetType,
    //     assetsectionId,
    //     assetId,
    //     taskassigid,
    //     userInfo.organizationId,
    //     cuurentUser,
    //     assignedBy,
    //     status,
    //     link,
    //     assignToEmail,
    //     acceptedBothData
    //   );

    //   if (response.status == 200) {
    //     getAssetSectionData();

    //     setSuccess(true);

    //     setStatusAccepted(true);

    //     setSpecifiSectiontaskData(null);
    //     toast.success("Task Accepted");
    //     const activityResponse = await addActivity(rfmManagementData.value.assetid, assetType, activityMsg.accepteChanges, userInfo.username, sectionName)

    //     handleGetActivity();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    setTaskApprovalOwnerData(taskApprovalOwnerData + taskApprovalReceiverData);

    setOpennewPopup(false);
    setOpenPopup(false);
  };

  // const handleSaveTask = async (
  //   assetType,
  //   assetsectionId,
  //   assetId,
  //   taskassignwith,
  //   id,
  //   htmlContentdata,
  //   sectionTitle
  // ) => {
  //   // alert("approved manually");
  //   // Combine both contents in the first editor

  //   const taskassigid = specifiSectiontaskData.taskassignwith;

  //   const assignToEmail = specifiSectiontaskData.assignToEmail;

  //   const link = specifiSectiontaskData.link;

  //   console.log(taskassignwith, "taskassignwithtaskassignwith");

  //   console.log(
  //     assetType,
  //     assetsectionId,
  //     assetId,
  //     taskassignwith,
  //     htmlContent,
  //     htmlOwnerContent,
  //     "asdfgasdfg"
  //   );

  //   const status = "approved";
  //   const organizationId=userInfo.organizationId;
  //   const data={
  //     assetType,
  //         assetsectionId,
  //         assetId,
  //         taskassigid,
  //         organizationId,
  //         cuurentUser,
  //         assignedBy,
  //         status,
  //         link,
  //         assignToEmail,
  //         acceptedBothData
  //   }
  //   setReviewData(data)

  //   // try {
  //   //   const response = await decisionByReceiver(
  //   //     assetType,
  //   //     assetsectionId,
  //   //     assetId,
  //   //     taskassigid,
  //   //     userInfo.organizationId,
  //   //     cuurentUser,
  //   //     assignedBy,
  //   //     status,
  //   //     link,
  //   //     assignToEmail,
  //   //     htmlOwnerContent
  //   //   );

  //   //   if (response.status == 200) {
  //   //     getAssetSectionData();

  //   //     setSuccess(true);

  //   //     setStatusAccepted(true);

  //   //     setSpecifiSectiontaskData(null);
  //   //     toast.success("Task Accepted Manually");
  //   //     const activityResponse = await addActivity(
  //   //       rfmManagementData.value.assetid,
  //   //       assetType,
  //   //       activityMsg.rejectChanges,
  //   //       userInfo.username,
  //   //       sectionTitle
  //   //     );

  //   //     handleGetActivity();
  //   //   }
  //   // } catch (error) {
  //   //   console.log(error);
  //   // }

  //   setTaskApprovalOwnerData(htmlContent);

  //   setOpennewPopup(false);
  //   setOpenPopup(false);
  // };



  const handleRejectTask = async (
    assetType,
    assetsectionId,
    assetId,
    taskassignwith,
    id,
    htmlContent,
    sectionTitle
  ) => {
    console.log( assetType,
      assetsectionId,
      assetId,
      taskassignwith,
      id,
      htmlContent,
      sectionTitle,"rejectedinfo")
    // Combine both contents in the first editor
    handleOpenTaskAssignedCommentModal()
    const taskassigid = specifiSectiontaskData.taskassignwith;

    const assignToEmail = specifiSectiontaskData.assignToEmail;

    const link = specifiSectiontaskData.link;

    // console.log(taskassignwith, "taskassignwithtaskassignwith");

    // console.log(
    //   assetType,
    //   assetsectionId,
    //   assetId,
    //   taskassignwith,
    //   htmlContent,
    //   "asdfgasdfg"
    // );

    const status = "rejected";
    setTaskApproveStatus("reject")
    const organizationId = userInfo.organizationId;
    const data = {
      assetType,
      assetsectionId,
      assetId,
      taskassigid,
      organizationId,
      cuurentUser,
      assignedBy,
      status,
      link,
      assignToEmail,
      htmlContent
    }
    setReviewData(data)

    // try {
    //   const response = await decisionByReceiver(
    //     assetType,
    //     assetsectionId,
    //     assetId,
    //     taskassigid,
    //     userInfo.organizationId,
    //     cuurentUser,
    //     assignedBy,
    //     status,
    //     link,
    //     assignToEmail,
    //     htmlContent
    //   );

    //   if (response.status == 200) {
    //     getAssetSectionData();

    //     setSuccess(true);

    //     setStatusAccepted(true);

    //     setSpecifiSectiontaskData(null);
    //     toast.success("Task Rejected");
    //     const activityResponse = await addActivity(
    //       rfmManagementData.value.assetid,
    //       assetType,
    //       activityMsg.rejectChanges,
    //       userInfo.username,
    //       sectionTitle
    //     );

    //     handleGetActivity();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    setTaskApprovalOwnerData(htmlContent);

    setOpennewPopup(false);
    setOpenPopup(false);
  };

  const handleFinalMerge = async (taskassignwith) => {
    setLoading(true)
    // alert("called")
    console.log(taskassignwith, taskApprovalOwnerData, "taskassignwith");
    const assignToEmailArray = taskassignwith.map(k => k.assignToEmail)

    console.log(reviewData, "reviewData")
    console.log(assignToEmailArray, "assignToEmailArray")
    const assignByEmail = taskassignwith[0].assignByEmail;
    const data = { assignToEmailArray, assignByEmail, taskApprovalOwnerData };
    console.log(data, "dadadadadad")



    try {
      const response = await decisionByReceiver(
        reviewData?.assetType,
        reviewData?.assetsectionId,
        reviewData?.assetId,
        reviewData?.taskassigid,
        userInfo.organizationId,
        reviewData?.cuurentUser,
        reviewData?.assignedBy,
        reviewData?.status,
        reviewData?.link,
        reviewData?.assignToEmail,
        // reviewData?.htmlContent,
        // reviewData?.rawUpdatedContent ? reviewData?.rawUpdatedContent : reviewData?.acceptedBothData,
        taskApprovalOwnerData,

        commentMessage
      );

      if (response.status == 200) {
        getAssetSectionData();

        setSuccess(true);

        setStatusAccepted(true);

        setSpecifiSectiontaskData(null);
        const activityResponse = await addActivity(
          reviewData?.assetId,
          reviewData?.assetType,
          activityMsg.accepteChanges,
          userInfo.username,
          reviewData?.sectionName
        );

        handleGetActivity();
        setReviewData("");
        showToast(`Proposal.taskReview`)
        setOpenJoditPopup(false)
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false)





    // try {
    //   const response = await sendFinalEmail(data);
    //   console.log(response.data);

    //   if (response.status == 200) {

    //     toast.success("Task saved successfully");
    //   } else {
    //     console.log("error in getting response");
    //   }
    // } catch (error) {

    // }

  }


  useEffect(() => {
    const fetchProposalWritingUpdates = () => {
      fetchTrackedChanges(
        rfmManagementData.value.assetid,
        currentInfo.sectionId,
        assetType
      );
    };
    fetchProposalWritingUpdates();

    const intervalId = setInterval(fetchProposalWritingUpdates, 60000);
    return () => clearInterval(intervalId);
  }, [currentInfo]);

  const fetchTrackedChanges = async (assetId, sectionId, assetType) => {
    try {
      const trackedData = await getTrackedChanges(
        assetId,
        sectionId,
        assetType
      );
      setTrackedChanges(trackedData.data);
    } catch (error) {
      console.error("Error fetching tracked changes:", error);
    }
  };

  async function fetchDataInEditor(
    type,
    htmlContent,
    title,
    name,
    sectionId,
    keydata,
    createdby,
    assignedEmail,
    firstReadonlyCondition,
    isTaskAccepted,
    isTaskSubmitted
  ) {
    console.log(title, createdby, "Title of the asset section ", name);
    setContent('')
    setWordCount(0)
    // alert(assignedEmail)
    try {
      setSectionTitle("");



      const namesArray = Array.isArray(name) ? name : [name];

      if (namesArray.length > 0 && typeof namesArray[0] === "string") {
        console.log("__00");

        // Decode the filename
        const newName = decodeURIComponent(namesArray[0]);
        const parts = newName.split("/");
        const FilteredName = parts.pop();
        // console.log("File name for editor__:", FilteredName);

        let userid = userInfo._id;
        if (rfmManagementData?.ProjectType?.data === "Shared Project") {
          userid = sharedOwnerId;
        }
        const newTitle = title.replace("/",'')
        // alert("callled")
        const docxContent = await getSectionDocument(
         `${newTitle}.docx`,
          userInfo.organizationId,
          rfmManagementData.value.id,
          rfmManagementData.value.assetid,
          userid,
          sectionId,
          keydata
        );
        console.log("yes", docxContent, FilteredName);
        console.log("check333", assignedEmail, cuurentUser, createdby, sUserId)
        if (firstReadonlyCondition === false) {
          if (!isAdmin && createdby == sUserId) {
            setIsReadOnly(false)
          } else if (!isAdmin && assignedEmail == cuurentUser && isTaskAccepted && !isTaskSubmitted) {
            setIsReadOnly(false)
          }
          else if (isAdmin && !assetDetails.isBidRequested) {
            setIsReadOnly(false)
          } else if (isAdmin && assetDetails.isBidRequested) {
            setIsReadOnly(true)

          }
          else {
            setIsReadOnly(true)
          }


        }
        if (docxContent.textResponse) {
          seteditSectionTitle(false);
          setIsgetDataEditor(true);
          const parsedResponse = JSON.parse(docxContent.textResponse);
          console.log("chchchc", parsedResponse)
          let newContent = `<pre style="white-space: pre-wrap; word-wrap: break-word; overflow: auto;">${parsedResponse.data.documentBody}</pre>`
          setContent(newContent);
          setWordCount(getWordCount(newContent))
          setPriorHtmlContent(parsedResponse.data.documentBody);
          setSectionTitle(title);
        } else {
          console.log("cccnnnn")
          setIsgetDataEditor(true);
          seteditSectionTitle(false);
          setSectionTitle(title);
          setContent("");
          setWordCount(0)
        }
      } else {
        // alert("not arrray")
      }
    } catch (error) {
      console.error("Error fetching and Section file content:", error);
    }
  }

  const stripHtmlTags = (htmlString) => {
    return htmlString?.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const splitIntoSentences = (text) => {
    return text.match(/[^.!?]+[.!?]*/g) || [];
  };

  async function fetchDataFile(name, sectionId) {
    setLoading(true);
    // alert(sectionId,"asset section ")
    try {
      const filename = name;
      let docFormat = name.slice(-4).replace(/\./g, "");
      // console.log("final file name", filename);
      let userid = userInfo._id;
      if (rfmManagementData?.ProjectType?.data === "Shared Project") {
        userid = sharedOwnerId;
      }
      const docxContent = await getRFPDocument(
        filename,
        userInfo.organizationId,
        rfmManagementData.value.id,
        rfmManagementData.value.assetid,
        userid,
        sectionId
      );
      // const docxContent = await fetch(`http://localhost:5050/api/buckets3/getByName2/${filename}`);
      console.log("fetchDataFile", docxContent, filename, docFormat);
      if (docFormat == "doc") {
        showToast(`RFP.previewUnavailable`)
      } else if (docFormat == "docx") {
        console.log(docxContent.textResponse, "doxcc")
        setShowDocxPreview(docxContent.textResponse)
        setPopupOpen(true);
      } else if (docFormat == "pdf") {
        setFileURL(docxContent.blobURL);
        setPopupOpen(true);
      } else if (docFormat == "txt") {
        setFileURL(docxContent.blobURL);
        setPopupOpen(true);
      }
      // }
    } catch (error) {
      console.error("Error fetching and processing file content:", error);
      // showNoPreviewInIframe();
    } finally {
      setLoading(false);
    }
    // setLoading(false)
  }

  const handleSectionClick = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const toggleLeftPane = () => {
    setLeftPaneVisible(!isLeftPaneVisible);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setShowDocxPreview("")
  };

  // download fucntion
  const handPassNameforDownload = (e, name, createdby) => {
    if (!assetData?.isBidRequested) {
      if (createdby !== userInfo._id && userInfo.userRole[0] === "Application User") {
        showToast(`RFP.downloadSection`)
      } else {
        // alert("trigger")
        if (Array.isArray(name) && typeof name[0] === "string") {
          // Check if name is an array and the first element is a string
          const newName = decodeURIComponent(name[0]); // Decode the filename
          const parts = newName.split("/");
          const FilteredName = parts.pop();
          handleDownload(FilteredName);
          // console.log("FilteredNameDownload__:", FilteredName);
        } else {
          console.log("Input is not an array containing strings.");
        }

        e.stopPropagation();
      }
    } else {
      showToast(`RFP.rfpBidRequested`);
    }
  };

  const handleDownload = async (filename) => {
    // alert(filename)
    try {
      const docxContent = await getRFPDocument(
        filename,
        userInfo.organizationName,
        rfmManagementData.value.id,
        rfmManagementData.value.assetid
      );
      // const blob = await docxContent.blob();
      const blob = new Blob([docxContent], { type: "text/plain" });
      // console.log(blob, "blob");
      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(blob);
      anchor.download = "text_content.docx";
      anchor.click();
      URL.revokeObjectURL(anchor.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDeleteFile = async (
    e,
    filename,
    assetsectionId,
    type,
    createdby
  ) => {
    if (!assetData?.isBidRequested) {
      if (rfmManagementData?.ProjectType?.data === "Shared Project") {
        const data = sharedSectionDetails?.find(
          (sharedData) =>
            sharedData.id === assetsectionId &&
            sharedData.selectedOption === "Edit"
        );

        if (!data) {
          showToast(`Error.edit`);
          return;
        }
      }
      e.stopPropagation();
      setIsPreviewOpen(false);
      setShowDocxPreview("")
      try {
        let featurename = "Delete Section";
        const checkfeatureName = accessFeatures?.filter(
          (k) => k.name == featurename
        );
        // console.log(checkfeatureName[0].access, "checkfeatureName");
        if (!checkfeatureName[0].access) {
          showToast(`RFP.deleteSectionAuth`);
        } else {
          if (!isAdmin && createdby !== sUserId) {

            showToast(`RFP.deleteSectionAuth`)
          }

          else {
            setLoading(true);

            let validationName;
            if (
              Array.isArray(filename) &&
              filename.length === 1 &&
              filename[0] !== ""
            ) {
              validationName = filename;
            } else {
              validationName = "delete";
            }
            // console.log("validationName", validationName);
            if (validationName !== "delete") {
              const name = await handleDeleteFileName(validationName, "doc");
              // console.log(
              //   "name of the delete function",
              //   name,
              //   assetsectionId,
              //   filename
              // );
              const response = await deleteFile(
                userInfo.organizationId,
                rfmManagementData.value.id,
                rfmManagementData.value.assetid,
                assetsectionId,
                name,
                userInfo._id
              );
              getAssetSectionData();
              // console.log(response, "response of delete");
              setIsPreviewOpen(false);
              setShowDocxPreview("")
              if (response.status == 200) {
                // toast.success("Document deleted successfully");
                if (type == "doc") {
                  const activityResponse = await addActivity(
                    rfmManagementData.value.assetid,
                    assetType,
                    activityMsg.deleteFile,
                    userInfo.username
                  );
                  handleGetActivity();
                } else {
                  const activityResponse = await addActivity(
                    rfmManagementData.value.assetid,
                    assetType,
                    activityMsg.deleteSection,
                    userInfo.username
                  );
                  handleGetActivity();
                }
              } else {
                console.log("erorororororororororororro in delete");
              }
            } else {
              const response = await deleteFile(
                userInfo.organizationId,
                rfmManagementData.value.id,
                rfmManagementData.value.assetid,
                assetsectionId,
                validationName,
                userInfo._id
              );
              getAssetSectionData();
              // console.log(response, "response of delete");
              setIsPreviewOpen(false);
              setShowDocxPreview("")
              if (response.message === "Asset section Deleted.") {
                if (type == "doc") {
                  const activityResponse = await addActivity(
                    rfmManagementData.value.assetid,
                    assetType,
                    activityMsg.deleteFile,
                    userInfo.username
                  );
                  handleGetActivity();
                } else {
                  showToast(`RFP.deleteSection`);
                  const activityResponse = await addActivity(
                    rfmManagementData.value.assetid,
                    assetType,
                    activityMsg.deleteSection,
                    userInfo.username
                  );
                  handleGetActivity();
                }
              } else {
                console.log("erorororororororororororro in delete");
              }
            }
          }
        }
      } catch (e) {
        console.log("error in Delete", e);
      } finally {
        setLoading(false);
      }
    } else {
      if (assetData?.acceptBidRequest) {
        return showToast(`RFP.rfpBidSuccessfully`)
      }
      showToast(`RFP.rfpBidRequested`)
    }
  };

  const handleDeleteFileName = (name, type) => {
    if (Array.isArray(name) && typeof name[0] === "string") {
      // Check if name is an array and the first element is a string
      const newName = decodeURIComponent(name[0]); // Decode the filename
      const parts = newName.split("/");
      const FilteredName = parts.pop(); // Extract the last element after splitting by '/'
      // console.log("FilteredName__:", FilteredName);
      return FilteredName;
    } else {
      console.log("Input is not an array containing strings.");
    }
  };



  const handleAssetsectionupload = async (value) => {
// alert("hjkl")

    if (rfmManagementData?.ProjectType?.data === "Shared Project") {
      const data = sharedSectionDetails?.find(sharedData => sharedData.id === assetsectionId && sharedData.selectedOption === "Edit");


      if (!data) {
        showToast(`Error.edit`);
        return;
      }
    }
    const cleanedContent = content.replace(/<[^>]*>/g, ''); // This regex removes all HTML tags 
    const words = cleanedContent.split(/\s+/).slice(0, 4).join('_'); // Adjust the number of words as needed 
    const decodedWords = decodeURIComponent(words); // Convert HTML entities to characters 
    const filename = decodedWords.length > 0 ? `${decodedWords}` : 'default_filename';


    // Convert HTML content to Blob 
    setLoading(true);
    const blob = new Blob([content], { type: 'text/html' });


    // Create FormData object 
    const newTitle = newSectionTitle.replace("/",'')
    const formData = new FormData();
    formData.append('filename', blob, newTitle); // Append content as file with generated filename 
    formData.append('orgName', userInfo.organizationName);
    formData.append('assetid', rfmManagementData.value.assetid);
    formData.append('assetsectionId', assetsectionId);
    formData.append('htmlContent', htmlContent);
    formData.append('userId', userInfo._id);
    formData.append('orgId', userInfo.organizationId);
    formData.append('isEdited', true);

    if (value == true) {
      formData.append('abhay', "reciver");
    }

    const extractImages = (html) => {
      // Match image tags (keeps the entire <img ...> tag)
      return html.match(/<img [^>]*src="[^"]*"[^>]*>/g) || [];
    };

    const priorHtmlContentWithoutTags = stripHtmlTags(priorHtmlContent);
    const htmlContentWithoutTags = stripHtmlTags(htmlContent);

    // Extract image tags (without removing HTML)
    const priorImages = extractImages(priorHtmlContent);
    const currentImages = extractImages(htmlContent);

    const priorSentences = splitIntoSentences(priorHtmlContentWithoutTags);
    const currentSentences = splitIntoSentences(htmlContentWithoutTags);

    const diff = Diff.diffArrays(priorSentences, currentSentences);
    const imageDiff = Diff.diffArrays(priorImages, currentImages);

    const result = {
      added: [],
      removed: []
    };

    diff.forEach(part => {
      if (part.added) {
        result.added.push(part.value.join(' '));
      } else if (part.removed) {
        result.removed.push(part.value.join(' '));
      }
    });

    imageDiff.forEach(part => {
      if (part.added) {
        result.added.push("Image added: " + part.value.join(' '));
      } else if (part.removed) {
        result.removed.push("Image removed: " + part.value.join(' '));
      }
    });

    console.log(result, "yollo");



    console.log(formData, "__formData")

    try {
      const trackChanges = await saveTrackedChanges(rfmManagementData.value.assetid, assetsectionId, assetType, userInfo.username, result.added, result.removed, htmlContent,userInfo._id)
      formData.append('trackchangesId', trackChanges.data._id);

      const response = await axios.post(`https://api.contractpro360.com/api/newbucket/assetsectionuploads/${rfmManagementData.value.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });


      if (response.status === 200) {
        console.log("upload response", response)
        showToast(`RFP.assetSectionUpload`)
        const activityResponse = await addActivity(rfmManagementData.value.assetid, assetType, activityMsg.uploadSection, userInfo.username)
        handleGetActivity()
        getAssetSectionData()
        if(!isAssignedToCurrentUser) {setIsgetDataEditor(false);}
      } else {
        console.log("error in upload")
      }
      // Handle success 
      console.log('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error.message);
    }
    finally {
      setLoading(false);
    }
  };



  const handleRevertChanges = async (verId,html, doc) => {
    setRevertHtml(html)
    setRevertDoc(doc)

    console.log(doc,"hohojojo")
    if(sectionCreateddBy === userInfo._id){
      setContent(html);
      setTemporaryContent(html);
    }else{
      setRevertRequestModalOpen(true)
    }
  };


  const handleRevertNotification = (doc) =>{
    setRevertDoc(doc)
    setRevertApprovalOpen(true)
  }

  const handleCloseRevertApproval = () =>{
    setRevertApprovalOpen(false)
  }

  const submitRevertApproval = async () => {
    const data={
      id: revertDoc._id
    }
    const res = await updateRevertRequest(data)
    if (res.status===200){
      setContent(revertDoc.documentBody);
      setTemporaryContent(revertDoc.documentBody);
      toast.success("Revert Request Approved")
      handleCloseRevertApproval()
      fetchTrackedChanges(
        rfmManagementData.value.assetid,
        assetsectionId,
        assetType
      );

    }else{
      toast.error("Error in approving revert request")
    }
  }

  const handleRevertRequestChange = (event) => {
    const value = event.target.value;
    setRevertRequest(value)
  };

  const submitRevertRequest = async () => {

    const data={
      message: revertRequest,
      requestedUser: userInfo.username,
      requestedUserId: userInfo._id,
      trackChangesId: revertDoc._id
    }

    const res = await sendRevertRequest(data)
    if(res.status===200){
      toast.success('Revert request sent successfully')
      handleCloseRevertRequestModal()
    }else{
      toast.error('Error in sending revert request')
    }
    
  }

  const handleMouseEnter = (addedContent, removedContent) => {
    console.log(addedContent, removedContent, "____changedcontent");
    let modifiedContent = htmlContent;
    setTemporaryContent(htmlContent);

    // Helper function to escape special characters in sentences for regex
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    // Function to wrap found sentences with a span tag
    const wrapSentences = (content, sentences, className) => {
      sentences.forEach((sentence) => {
        const escapedSentence = escapeRegExp(sentence);
        const regex = new RegExp(`(${escapedSentence})`, "g");
        content = content.replace(
          regex,
          `<span class="${className}">$1</span>`
        );
      });
      return content;
    };

    // Wrap sentences in addedContent and removedContent
    modifiedContent = wrapSentences(
      modifiedContent,
      addedContent,
      "added-content"
    );
    modifiedContent = wrapSentences(
      modifiedContent,
      removedContent,
      "removed-content"
    );

    // console.log(
    //   "Modified HTML Content:",
    //   modifiedContent,
    //   "addedContent",
    //   addedContent,
    //   "removedContent",
    //   removedContent
    // );
    setContent(modifiedContent);
  };

  const handleMouseLeave = () => {
    // Function to remove span tags with specific class names
    const removeSpans = (content, classNames) => {
      classNames.forEach((className) => {
        const regex = new RegExp(
          `<span class="${className}">(.*?)<\/span>`,
          "g"
        );
        content = content.replace(regex, "$1");
      });
      return content;
    };

    let revertedContent = temporaryContent; // Revert to the original content
    revertedContent = removeSpans(revertedContent, [
      "added-content",
      "removed-content",
    ]);

    setContent(revertedContent);
  };

  const getHtmlcontent = () => {
    if (editor.current) {
      const value = editor.current.value;
      setHtmlContent(value);
      console.log("Editor current value1", value);
    } else {
      console.log("Editor ref is not set up properly.");
    }


    if (editor.current) {
      const value = editor.current.value;


      console.log("Editor current value", value);



      const htmlContent = editor.current.value; // Access the HTML content
      console.log("htmlContent:", htmlContent);

      let wordCount = 0;
      let textContent = '';
      if (htmlContent === "") {

        setWordCount(0)
        return
      }

      if (htmlContent.trim() !== '') {
        wordCount = getWordCount(htmlContent)
      }
      setWordCount(wordCount)
    }

  };

  function getWordCount(htmlContent) {
    if (htmlContent.trim() === '') {
      return 0; // Return 0 if the content is empty
    }
  
    // Replace line breaks with newline characters
    const processedHtmlContent = htmlContent
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<div\s*\/?>/gi, '\n');
  
    // Create a temporary element to extract text
    const tempElement = document.createElement('div');
    tempElement.innerHTML = processedHtmlContent;
    const textContent = tempElement.innerText.trim(); // Extract the text from HTML
  
    if (textContent === '') {
      return 0; // Return 0 if the extracted text is empty
    }
  
    // Count words
    const wordCount = textContent.split(/\s+/).length;
  
    return wordCount;
  }
  


  const getHtmlOwnercontent = () => {
    if (editor1.current) {
      const value = editor1.current.value;
      setHtmlownerContent(value);
      console.log("", value);
    } else {
      console.log("Editor ref is not set up properly.");
    }
  };

  // edit section title
  const handlSectiontitle = async (e) => {
    // console.log("Edited section title__", e);
    setSectionTitle(e);
    try {
      const response = await updateAssetsection(assetsectionId, e);
      if (response.status == 200) {
        // toast.success("Updated succesfully")
        getAssetSectionData();
      } else {
        console.log("error in response ");
      }
    } catch (e) {
      console.log("error while updating the title", e);
    }
  };

  //Summarize description

  const handleSummaryModalOpen = async (e) => {
    setLoading(true);
    const isSummarized = await callSummarizer();

    setOpenSummaryModal(true);

    setLoading(false);
    e.stopPropagation();
  };

  const callSummarizer = async () => {
    try {
      const isSummarized = await isSummarizedDescription(
        rfmManagementData.value.id
      );
      // console.log("isSummarized", isSummarized);
      if (isSummarized.status == 200) {
        const data = {
          text: cleanedContent,
          prompts: "one",
          content: "",
        };
        const summarizerResponse = await getCheckSummariser(data);
        // console.log("summarizerResponse", summarizerResponse);
        if (summarizerResponse.data !== "") {
          let responseOutput = summarizerResponse?.data?.output;
          // console.log("summarized description ", responseOutput);
          setsummarizedDescription(responseOutput);
          try {
            const response = await updateSummarizedDescription(
              rfmManagementData.value.id,
              responseOutput[0]
            );
            // console.log(response);
          } catch (error) {
            console.log(error);
          }
        }
      } else if (isSummarized.status == 400) {
        setsummarizedDescription(isSummarized.data.summarizedDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleColorforDate = () => {
    const today = new Date();
    let tooltipContent;
    // console.log("today", today);
    // console.log("responseDeadLine", rfmManagementData.value.date1);
    if (rfmManagementData.value.date1) {
      const providedDate = new Date(rfmManagementData.value.date1);
      const timeRemaining = providedDate - today;
      const totalDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const percentageRemaining = (totalDays / 30) * 100;
      // console.log("percentageRemaining", percentageRemaining);
      if (totalDays < 0) {
        // Provided date has passed
        setStatusColor("black"); // Updated color to 'black'
        tooltipContent = `Delayed (${Math.abs(totalDays)} days late)`;
      } else if (percentageRemaining <= 33.33) {
        setStatusColor("red");
        tooltipContent = `Delayed (${totalDays} days remaining)`;
      } else if (percentageRemaining <= 66.33) {
        setStatusColor("orange");
        tooltipContent = `Moderately delayed (${totalDays} days remaining)`;
      } else {
        setStatusColor("green");
        tooltipContent = `On time (${totalDays} days remaining)`;
      }
    } else {
      setStatusColor("grey");
      tooltipContent = `Response date not available`;
    }
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComments(event.target.value);
  };

  const handleAddNote = async (e) => {
    // e.stopPropagation()
    try {
      const response = await addAssetComments(
        rfmManagementData.value.assetid,
        assetType,
        noteType,
        note,
        userInfo.username
      );
      // console.log("called", response);
      if (response.status == 201) {
        setisModalOpen(false);
        toast.success(response.message);
        handleGetComment();
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.addNote,
          userInfo.username
        );
        handleGetActivity();
        // console.log("response of activity transaction", activityResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddComment = async (e) => {
    // e.stopPropagation()
    try {
      const response = await addAssetComments(
        rfmManagementData.value.assetid,
        assetType,
        commentType,
        commentsText,
        userInfo.username
      );
      // console.log("called", response);
      if (response.status == 201) {
        toast.success(response.message);
        setisCommentModalOpen(false);
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.addComment,
          userInfo.username
        );
        handleGetActivity();
        handleGetTypeComment();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetTypeComment = async () => {
    try {
      const response = await getAssetComments(
        rfmManagementData.value.assetid,
        assetType,
        commentType
      );
      if (response.status == 201) {
        // console.log("Comment_data", response);
        setCommentData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetComment = async () => {
    try {
      const response = await getAssetComments(
        rfmManagementData.value.assetid,
        assetType,
        noteType
      );
      if (response.status == 201) {
        setNotesData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteNotes = async (id) => {
    // console.log("delete NOte value", id);
    try {
      const response = await deleteAssetComment(id);
      if (response.status == 200) {
        showToast(`RFP.assetCommentDelete`);
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.deleteNote,
          userInfo.username
        );
        handleGetActivity();
        handleGetComment();
      }
    } catch (e) {
      console.log(e, "errro while deleting");
    }
  };

  const handleUpdateNotes = async (id, text) => {
    setUpdatIcon(true);
    setisModalOpen(true);
    setSelectedNoteId(id);
    setNote(text);
  };
  const handleUpdateNoteApi = async (e) => {
    // e.stopPropagation()
    try {
      const response = await UpdateassetComments(
        selectedNoteId,
        userInfo.username,
        note
      );
      if (response.status == 200) {
        showToast(`RFP.assetCommentUpdate`);
        handleGetComment();
        setisModalOpen(false);
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.updateNote,
          userInfo.username
        );
        handleGetActivity();
        setNote("");
      }
    } catch (e) {
      console.log(e, "errro while deleting");
    }
  };

  const handleDeleteComments = async (id) => {
    try {
      const response = await deleteAssetComment(id);
      if (response.status == 200) {
        showToast(`RFP.assetCommentDelete`);
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.deleteComment,
          userInfo.username
        );
        handleGetActivity();
        handleGetTypeComment();
      }
    } catch (e) {
      console.log(e, "errro while deleting");
    }
  };

  const handleUpdateComments = async (id, text) => {
    // alert("open");
    let featurename = "Add Comment";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    // console.log(checkfeatureName[0].access, "checkfeatureName");
    if (!checkfeatureName[0].access) {
      showToast(`RFP.addAssetCommentAuth`);
    } else {
      setUpdatCommentIcon(true);
      setisCommentModalOpen(true);
      setSelectedCommentId(id);
      setComments(text);
    }
  };

  const handleUpdateCommentApi = async (e) => {
    e.stopPropagation();
    try {
      const response = await UpdateassetComments(
        selectedCommentId,
        userInfo.username,
        commentsText
      );
      if (response.status == 200) {
        showToast(`RFP.assetCommentUpdate`);
        handleGetTypeComment();
        setisCommentModalOpen(false);
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.updateComment,
          userInfo.username
        );
        // console.log("update comment",activityResponse)
        handleGetActivity();
        setComments("");
      }
    } catch (e) {
      console.log(e, "error while deleting");
    }
  };

  useEffect(() => {
    const fetchRfpUpdates = () => {
      handleGetActivity();
      handleGetComment();
      handleGetTypeComment();
      getAssetSectionData();
      fetchAssignedTaskdetails()
    };
    fetchRfpUpdates();

    const intervalId = setInterval(fetchRfpUpdates, 30000);
    return () => clearInterval(intervalId);
  }, []);

  // getActivity Api
  const handleGetActivity = async () => {
    try {
      const response = await getActivity(rfmManagementData.value.assetid);
      if (response) {
        setActivityData(response.data);
        // console.log("All Activity", response.data);
      }
    } catch { }
  };

  const handleShowMore = () => {
    setDisplayCount(displayCount + 5);
  };
  const handleShowLess = () => {
    setDisplayCount(5);
  };

  const handleAddTemplate = () => {
    setShowTemplate(true);
    dispatch(templateData("RFP"));
    history.push("/TemplateManagement");
  };

  const hideAddTemplate = () => {
    setShowTemplate(false);
  };

  const handleOpenModal = (e) => {
    let featurename = "Add Note";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    // console.log(checkfeatureName[0].access, "checkfeatureName");
    if (!checkfeatureName[0].access) {
      showToast(`RFP.addNoteAuth`);
    } else {
      e.stopPropagation();
      setUpdatIcon(false);
      setNote("");
      setisModalOpen(true);
    }
  };
  const handleModalClose = () => {
    setisModalOpen(false);
    setNote("");
  };
  const handleCommentModalClose = () => {
    setisCommentModalOpen(false);
    setComments("");
  };
  const handleOpenCommentModal = (e) => {
    let featurename = "Add Comment";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    // console.log(checkfeatureName[0].access, "checkfeatureName");
    if (!checkfeatureName[0].access) {
      showToast(`RFP.addAssetCommentAuth`);
    } else {
      e.stopPropagation();
      setComments("");
      setisCommentModalOpen(true);
      setUpdatCommentIcon(false);
    }
  };
  const handleCancel = () => {
    setNote("");
  };

  const handleCancelComment = () => {
    setComments("");
  };

  const openCard = (text, viewType, Id, createdby) => {
    // console.log("Text from card", text, viewType, Id, createdby);
    setCreatedByCard(createdby);
    handleCardDataModalOpen();
    setViewType(viewType);
    setcardData(text);
    setCardId(Id);
  };

  const handleCardDataModalClose = () => {
    setisCardDatModalOpen(false);
  };

  const handleCardDataModalOpen = () => {
    setisCardDatModalOpen(true);
  };

  const handleNextCard = () => {
    if (viewType === "noteCard") {
      const currentIndex = notesData.findIndex((item) => item._id === cardId);
      // console.log("currentIndex", currentIndex, cardId);
      if (currentIndex !== -1 && currentIndex < notesData.length - 1) {
        const nextCard = notesData[currentIndex + 1];
        setcardData(nextCard.text);
        setCardId(nextCard._id);
        setCreatedByCard(nextCard.createdby);
        // console.log("Next Card:", nextCard);
      } else {
        console.log("No next card available.");
      }
    } else if (viewType === "commentCard") {
      const currentIndex = commentData.findIndex((item) => item._id === cardId);
      // console.log("currentIndex", currentIndex, cardId);
      if (currentIndex !== -1 && currentIndex < commentData.length - 1) {
        const nextCard = commentData[currentIndex + 1];
        setcardData(nextCard.text);
        setCardId(nextCard._id);
        setCreatedByCard(nextCard.createdby);
        // console.log("Next Card:", nextCard);
      } else {
        console.log("No next card available.");
      }
    }
  };

  const handlePreviousCard = () => {
    if (viewType === "noteCard") {
      const currentIndex = notesData.findIndex((item) => item._id === cardId);
      // console.log("currentIndex", currentIndex, cardId);
      if (currentIndex !== -1 && currentIndex > 0) {
        const nextCard = notesData[currentIndex - 1];
        setcardData(nextCard.text);
        setCardId(nextCard._id);
        setCreatedByCard(nextCard.createdby);
        // console.log("Previous Card:", nextCard);
      } else {
        console.log("No Previous card available.");
      }
    } else if (viewType === "commentCard") {
      const currentIndex = commentData.findIndex((item) => item._id === cardId);
      // console.log("currentIndex", currentIndex, cardId);
      if (currentIndex !== -1 && currentIndex > 0) {
        const nextCard = commentData[currentIndex - 1];
        setcardData(nextCard.text);
        setCardId(nextCard._id);
        setCreatedByCard(nextCard.createdby);
        // console.log("Previous Card:", nextCard);
      } else {
        console.log("No Previous card available.");
      }
    }
  };

  // swapping state


  const toggleModal = () => {
    // if (!assetData?.isBidRequested) {
    let featurename = "Swap";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    // console.log(checkfeatureName[0].access, "checkfeatureName");
    if (!checkfeatureName[0].access) {
      showToast(`RFP.swapAuth`);
    } else {
      if(asssetsectionData.length > 1){
      setModalOpen(!modalOpen);
      }else{
        toast.error('Swap requires 2 or more sections')
      }
    }
    // } else {
    //   toast.warn("This RFP has been sent to management for approval !");
    // }

  };


  const closeModal = () => {
    setModalOpen(false);
  };

  // Reorder state


  const toggleReorderModal = () => {
    // if (!assetData?.isBidRequested) {
    let featurename = "Reorder";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    // console.log(checkfeatureName[0].access, "checkfeatureName");
    if (!checkfeatureName[0].access) {
      showToast(`RFP.reorderAuth`);
    } else {
      if(asssetsectionData?.length > 1){
      setshowReorderModal(!showReorderModal);
      }else{
        toast.error('Reorder requires 2 or more sections')
      }
    }
    // window.alert('This is an alert message!');
    // } else {
    //   toast.warn("This RFP has been sent for bid request to management for approval.")
    // }

  };
  // share assets with users


  const getAllUserForSameOrgData = async () => {
    // console.log("assetId============>>", rfmManagementData.value.assetid);
    // console.log("Called check", cuurentUser);
    // console.log("userinfodata===========", userInfo.email);
    const data = {
      email: sessionStorage.getItem("currentUserEmail"),
      assetid: rfmManagementData.value.assetid,
      assetType: assetType,
    };
    // console.log("existinguSersdata===========", data);
    try {
      const response = await getAllUserForSameOrg(data);
      // console.log("infodata==========>>>", response);
      if (response) {
        // console.log("response of addassetsSharingData ", response);
        setUsers(response.data);
        setExistingUsers(response.assetSent);
      } else {
        console.log("error in response");
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
  // console.log("existingusers-===>", existingusers);
  const openModalshr = () => {
    if (!assetData?.isBidRequested) {
      let featurename = "Share RFP";
      const checkfeatureName = accessFeatures?.filter(
        (k) => k.name == featurename
      );
      // console.log(checkfeatureName[0].access, "checkfeatureName");
      if (!checkfeatureName[0].access) {
        showToast(`ERROR.upload`);
      } else {
        getAllUserForSameOrgData();
        setModalOpenshr(true);
      }
    } else {
      showToast(`RFP.rfpBidRequested`)
    }

  };

  const closeModalshr = () => {
    setModalOpenshr(false);
  };

  const handleBacktoOpportunity = () => {
    history.push("/OppourtunityManagementSubadmin");
  };

  const closeModalSectionshr = () => {
    setModalOpenSectionshr(false);
  };
  const openModalSectionshr = (
    sectionId,
    documenttype,
    Assettype,
    createdby
  ) => {
    console.log(sectionId,
      documenttype,
      Assettype,
      createdby, "sharemodall")
    if (!assetData?.isBidRequested) {
      let featurename = "Share Section";
      const checkfeatureName = accessFeatures?.filter(
        (k) => k.name == featurename
      );
      // console.log(checkfeatureName[0].access, "checkfeatureName");
      if (!checkfeatureName[0].access) {
        showToast(`RFP.shareSectionAuth`);
      } else {
        if (createdby !== userInfo._id && userInfo.userRole[0] === "Application User") {
          showToast(`RFP.shareSectionAuth`);
        } else {
          setAssetSetionId(sectionId);
          setSharedAssetSectionScreenType(Assettype);
          setSharedAssetSectionType(documenttype);
          setModalOpenSectionshr(true);
          getAllUserForSameOrgData();
          setIsSectionSharing(true);
        }
      }
    } else {
      showToast(`RFP.rfpBidRequested`)
    }
  };

  const getSectionSharedfunction = async () => {
    let data = {
      email: sessionStorage.getItem("currentUserEmail"),
      assetid: rfmManagementData.value.assetid,
    };
    try {
      const response = await getSectionSharedData(data);
      if (response) {
        // console.log(response, "response of section shared ", response.data);
        setSharedSectionDetails(response.data);
        setsharedOwnerId(response.ownerUserid);
      } else {
        console.log("not found shared section ");
      }
    } catch (e) {
      console.log(e, "error in getting shared section data ");
    }
  };

  const statusPendingStyle = {
    fontSize: "7px",
    color: "orange",
    borderRadius: "50%",
    WebkitAnimation: "sonarEffect 1.3s ease-out infinite",
    MozAnimation: "sonarEffect 1.3s ease-out infinite",
    animation: "sonarEffect 1.3s ease-out infinite",
  };



  const handleAccept = (sectionId) => {
    // Additional logic for accepting

    // Assuming you have an array or object where you store the accepted status for each section,
    // you can update the accepted status for the specific section identified by sectionId.
    // For example:
    const updatedSharedSectionDetails = sharedSectionDetails.map(
      (sharedData) => {
        if (sharedData.id === sectionId) {
          return { ...sharedData, accepted: true };
        }
        return sharedData;
      }
    );

    // Assuming sharedSectionDetails is a state variable, update it with the new accepted status.
    // For example:
    setSharedSectionDetails(updatedSharedSectionDetails);

    // Close the popup after accepting
    setShowPopups(false);

    // Call the function after accepting
    handlegetFileName(
      document.documentpath,
      document.type,
      document.htmlContent,
      sectionId,
      document.title
    );
  };
  //  const ccdd = sectionsArray[1]?.taskAssignWith.find(
  //   (m) => m.assignToEmail === userInfo?.email
  // )?.link
  // console.log(ccdd,"ccdd")

  const handleReject = () => {
    // Additional logic for rejecting
    setShowPopups(false); // Close the popup after rejecting
  };

  const getExtractedClause = async (value) => {
    try {
      let featurename = "Clause";
      const checkfeatureName = accessFeatures?.filter(
        (k) => k.name == featurename
      );
      // console.log(checkfeatureName[0].access,"checkfeatureName")
      // if (!checkfeatureName[0].access) {
      //   toast.error("You are not allowed to extract clause");
      // } else {
      let data = {
        assetid: rfmManagementData.value.assetid,
        email: sessionStorage.getItem("currentUserEmail")
      }
      if (value != true) {
        setLoading(true);
      }
      const response = await getClauses(data);
      // console.log("response",response)
      setExtractedClause(response?.data);
      if (value != true) {
        setIsOpenClauseModal(true);
      }
      // }
    } catch (e) {
      console.log(e, "error in getExtractedClause");
    } finally {
      setLoading(false);
    }
  };

  const handleAddClauses = (e) => {
    // console.log("add clauses", e.target.value);
    setAddManualClause(e.target.value);
  };

  const handleAddManualClause = async () => {
    try {
      // if (userInfo?.userRole[0] === "Application User") {
      //   return toast.error("You are not allowed to add clause.")
      // }
      if (addReason == "") {
        return showToast(`Proposal.reasonAddClause`);
      }

      // Extract existing clauses from clauses and manualClause

      const status = userInfo?.userRole?.map(role => role.trim().toLowerCase()).some(role => role === "manager" || role === "application admin")   ? "approved" : "pending";
      let data = {
        assetId: rfmManagementData.value.assetid,
        clauses: addManualClause,
        filename: "Manually Added Clauses",
        status: status,
        createdBy: cuurentUser,
        assetType: "rfp",
        reason: addReason
      };
      const response = await addClauses(data);
      if (response.status === 200) {

        showToast('Success.clauseAdded')
        getExtractedClause();
        setAddManualClause("");
        handleAddReasonModalclose()
      }
    } catch (error) {
      console.log(error, "erro in adding manual clauses ");
    }
  };


  const getAssetData = async () => {
    try {
      let data = {
        assetId: rfmManagementData.value.assetid,
      };
      const response = await getSpecificAsset(data);
      if (response.status == 200) {
        // console.log(response, "specific asset found");
        const data = response.data;
        setAssetData(data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBidClick = async () => {
    setLoading(true)
    try {
      let data = {
        assetId: rfmManagementData.value.assetid,
      };
      const response = await bidAsset(data);
      if (response.status == 200) {
        history.push("/OppourtunityManagementSubadmin");
        setLoading(false)
        showToast(`RFP.rfpBidApproved`)
      } else {
        setLoading(false)
        showToast(`RFP.rfpBidRequesteFail`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
      showToast(`Error.errorOccured`);
    }
  };

  const handleNoBidClick = async () => {
    setLoading(true)
    try {
      let data = {
        assetId: rfmManagementData.value.assetid,
      };
      const response = await nobidAsset(data);
      if (response.status == 200) {
        history.push("/OppourtunityManagementSubadmin");
        setLoading(false)
        showToast(`RFP.rfpBidRejected`)
      } else {
        setLoading(false)
        showToast(`RFP.rfpBidRejecteError`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
      showToast(`RFP.errorOccured`);
    }
  };

  const getTaskCompletion = async () => {
    try {
      let data = {
        assetId: rfmManagementData.value.assetid,
        orgId
      };
      const response = await getTeamTask(data);
      if (response.status == 200) {
        setTeamTasks(response.data)
        setTaskCompletionModal(true)
      } else {
        showToast(`RFP.taskFetchError`);
      }

    } catch (error) {
      console.log(error);
      showToast(`Error.errorOccured`);
    }
  }

  const handleTaskCompletion = async (updatedTasks) => {
    try {
      let data = {
        assetId: rfmManagementData.value.assetid,
        orgId,
        taskStatus: updatedTasks,
        teamDetailsObjectId: teamTasks._id
      };
      const response = await updateTeamTask(data);
      if (response.status == 200) {
        handleRequestBid()
        showToast(`Proposal.taskComplete`)
      } else {
        showToast(`Error.errorOccured`);
      }

    } catch (error) {
      console.log(error);
      showToast(`Error.errorOccured`);
    }
    closeTaskCompletionModal()
  }

  const handleRTBClick = async () => {
    getTaskCompletion()
  }

  const handleRequestBid = async () => {
    setLoading(true)
    try {
      let data = {
        assetId: rfmManagementData.value.assetid,
      };
      const response = await requestBid(data);
      if (response.status == 200) {
        history.push("/OppourtunityManagementSubadmin");
        setLoading(false)
        showToast(`RFP.rfpBidRequested`)
      } else {
        setLoading(false)
        showToast(`RFP.rfpBidRequestedFailed`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
      showToast(`Error.errorOccured`);
    }
  };

  const handleOpenClauseModal = () => {
    setIsOpenClauseModal(true);
    handleGetDeletedClause()
  };

  const handleClauseExtraction = async (value) => {
    // if (!assetData?.isBidRequested) {
    setExtractedClause("");
    // if (resourceLinkspaths?.length === 0 || resourceLinkspaths === null ) {
    //   toast.warn("No resource links found.");
    //   return;
    // }
    setDownloadFileName(assetData?.opportunityid.title);
    let data = {
      resourceLinks: assetData?.opportunityid.resourceLinks,
      assetId: assetData?._id,
      email: sessionStorage.getItem("currentUserEmail")
    };
    try {
      setLoading(true);
      const response = await getResourceLinks(data);

      if (response.status == 201) {
        console.log("extracted clauses:", response);

        setExtractedClause(response.data);

        handleOpenClauseModal();

      } else if (response.status == 400) {
        getExtractedClause(assetData?._id);

        handleOpenClauseModal();

      } else {
        console.log("error in getting xtracted clauses ");

        handleOpenClauseModal();

      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    // } else {
    //   toast.warn("This RFP has been sent to managemnet for bid request approval.")
    // }
  };

  const handleDownloadClause = () => {
    if (userInfo?.userRole[0] === "Application User") {
      return showToast(`RFP.downloadClauseAuth`)
    }
    setClauseshowDownloadModal(true);
  };

  const ClausePDFDownload = () => {
    let pdfContent = "";
    // console.log(extractedClause, downloadFileName, "aaaa");
    extractedClause.clauses.forEach((item) => {
      const responseData = item.responseData
        .split("\n")
        .map((clause) => `<p>${clause}</p>`)
        .join("");
      pdfContent += `<div><h5>${item.filename}</h5>${responseData}</div><br>`;
    });
    // console.log("pdfContent", pdfContent);
    const element = document.createElement("div");
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: `${downloadFileName}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }).then((pdf) => {
      saveAs(pdf, `${downloadFileName}.pdf`);
    });
    setClauseshowDownloadModal(false);
  };

  const ClauseDocxDownload = async () => {
    // const contentState = convertFromRaw(JSON.parse(documentData.onlyDocumentContent));
    // const contentHTML = stateToHTML(contentState);
    // let downloadFileName = fileForSave.slice(0, -5);

    let pdfContent = "";
    // console.log(extractedClause, downloadFileName, "aaaa");
    extractedClause.clauses.forEach((item) => {
      const responseData = item.responseData
        .split("\n")
        .map((clause) => `<p>${clause}</p>`)
        .join("");
      pdfContent += `<div><h5>${item.filename}</h5>${responseData}</div><br>`;
    });

    const htmlString = `<!DOCTYPE html>
      <html lang="en">
      <head>
      <meta charset="UTF-8">
      <title>${downloadFileName}</title>
      </head>
      <body>
      <h3>${downloadFileName}</h3>
      <hr/>
      ${pdfContent}
      </body>
      </html>`;

    asBlob(htmlString).then((docx) => {
      saveAs(docx, `${downloadFileName}.docx`);
      setClauseshowDownloadModal(false); // Close the modal after the download
    });
  };
  console.log(sectionsArray, "sectionsArray");

  const handleDeleteClause = async () => {
    console.log("idclause", dataForDeleteClause);

    if (!assetData?.isBidRequested) {
      try {
        // if (userInfo?.userRole[0] === "Application User") {
        //   return toast.error("You are not allowed to delete clause.")
        // }
        if (deleteReason == "") {
          return showToast(`RFP.reasonDeleteClause`)
        }
        let data = {
          clauseText: dataForDeleteClause.clause,
          clauseId: dataForDeleteClause.id,
          clauseDocumentId: dataForDeleteClause.dataid,
          reason: deleteReason
        };
        const response = await deleteClause(data);
        if (response.status == 200) {
          showToast(`Proposal.clauseDelete`);
          // console.log("response of delete clause", response);
          getExtractedClause();
          handleGetDeletedClause()
          handleDeleteReasonModalclose()
          // console.log("", extractedClause);
        }
      } catch (e) {
        console.log("error in delete");
      }
    } else {
      if (assetData?.acceptBidRequest) {
        return showToast(`RFP.rfpBidSuccessfully`)
      }
      showToast(`RFP.rfpBidRequested`)
    }
  };



  const handleGetDeletedClause = async () => {
    try {
      let data = {
        assetId: rfmManagementData.ProposalData.assetid
      };
      const response = await getDeletedClause(data);
      if (response.status == 200) {
        console.log("dddddd", response);
        setDeletedClauseArray(response.data)
        getExtractedClause();
      }
    } catch (e) {
      console.log("error in delete");
    }
  };

  const handleClauseModalProject = async (
    clause,
    clauseObjectId,
    clauseDocumentId,
    assetId
  ) => {
    setClasueProjectData("");
    setShowEditButton(false);
    try {
      let data = {
        clauseText: clause,
        clauseObjectId,
        clauseDocumentId,
        assetId,
      };
      setLoading(true);
      const response = await getClauseProject(data);
      if (response.status === 200) {
        setClauseDataEditable(true);
        // console.log("response of get project data", response);
        const clasue = response.data[0];
        const formatDate = (dateStr) => (dateStr ? dateStr.split("T")[0] : "");
        setClasueProjectData({
          projectName: clasue.projectName,
          description: clasue.description,
          projectName: clasue.projectName,
          number: clasue.number,
          startDate: formatDate(clasue.startDate),
          endDate: formatDate(clasue.endDate),
          fundingSource: clasue.fundingSource,
          contractType: clasue.contractType,
          projectLocation: clasue.projectLocation,
        });
      } else {
        setClauseDataEditable(false);
      }
    } catch (error) {
      console.log("error in fetching clause project data");
    } finally {
      setLoading(false);
    }
    // console.log("clause", clause, clauseObjectId, clauseDocumentId, assetId);
    // setIsOpenClauseModal(false);

    setIsClauseProjectModalOpen(true);
    setSelectedClause(clause);
    setSelectedClauseObjectId(clauseObjectId);
    setSelectedClauseDataId(clauseDocumentId);
    setSelectedClauseAssetId(assetId);
  };

  const handleAddProjectClause = (e, fieldName) => {
    const { value } = e.target;
    setClasueProjectData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleCloseClauseProject = () => {
    setIsClauseProjectModalOpen(false);
  };

  const handleAddProjectClauseData = async () => {
    try {
      setLoading(true);
      const data = {
        assetId: selectedClauseAssetId,
        clauseDocumentId: selectedClauseDocumentId,
        clauseText: selectedClause,
        clauseObjectId: selectedClauseObjectId,
        projectName: clauseProjectData.projectName,
        description: clauseProjectData.description,
        number: clauseProjectData.number,
        startDate: clauseProjectData.startDate,
        endDate: clauseProjectData.endDate,
        fundingSource: clauseProjectData.fundingSource,
        contractType: clauseProjectData.contractType,
        projectLocation: clauseProjectData.projectLocation,
      };
      // console.log("data to send ", data);
      const response = await addClauseProject(data);
      if (response.status == 200) {
        showToast(`Success.dataAdded`);
        // console.log("response of adding project", response);
        handleCloseClauseProject();
      }
      if (response.status == 400) {
        toast.error(response.message);
        console.log("response of adding project", response.message);
      }
    } catch (error) {
      console.log("error in addind project details ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateClauseProject = async () => {
    try {
      const data = {
        clauseText: selectedClause,
        clauseObjectId: selectedClauseObjectId,
        clauseDocumentId: selectedClauseDocumentId,
        assetId: selectedClauseAssetId,
        projectName: clauseProjectData.projectName,
        description: clauseProjectData.description,
        projectName: clauseProjectData.projectName,
        number: clauseProjectData.number,
        startDate: clauseProjectData.startDate,
        endDate: clauseProjectData.endDate,
        fundingSource: clauseProjectData.fundingSource,
        contractType: clauseProjectData.contractType,
        projectLocation: clauseProjectData.projectLocation,
      };
      const response = await updateClauseProject(data);
      if (response.status == 200) {
        showToast(`Success.dataUpdated`);
        getExtractedClause();
      }
      if (response.status === 400) {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnableUpdate = () => {
    setClauseDataEditable(false);
    setShowEditButton(true);
  };

  const downloadTableAsPDF = () => {
    const element = document.getElementById("table-to-pdf");
    html2pdf().from(element).save("RequirementMapping.pdf");
    setShowDownloadMappingModal(false); // Close the modal after the download
  };


  // search section
  const handleSearchChange = (e) => {
    try {
      const newSearchTerm = e.target.value.toLowerCase().trim();
      if (!newSearchTerm) {
        setIsFilteredAndSearch(false);
      }
      // console.log(newSearchTerm, "newSearchTerm");
      const data =
        filteredSectionData.length > 0
          ? filteredSectionData
          : initialAsssetSectionData;
      // console.log(data, "data to perform search");
      const filteredSection = data?.filter((doc) => {
        const plainTextContent = doc.htmlContent.replace(/<[^>]*>/g, "");

        const titleMatched = doc.title?.toLowerCase().includes(newSearchTerm);
        const contentMatched = plainTextContent
          .toLowerCase()
          .includes(newSearchTerm);
        return titleMatched || contentMatched;
      });
      // console.log(filteredSection, "filteredSection----");
      if (filteredSectionData.length > 0 && newSearchTerm) {
        setIsFilteredAndSearch(true);
        setinitialfilteredSectionData(filteredSection);
      } else {
        setAssetSectionData(() =>
          newSearchTerm ? filteredSection : initialAsssetSectionData
        );
      }
    } catch (error) {
      console.log("error in search", error);
    }
  };

  //search notice summary


  const highlightSearchTerm = (content, term) => {
    if (!term) return content;

    // Split content into parts based on search term
    const parts = content.split(new RegExp(`(${term})`, "gi"));

    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === term.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: "yellow" }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  // Function to highlight search term within HTML content
  const highlightDescription = (content, term) => {
    if (!term) return content;
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const regex = new RegExp(`\\b(${term})\\b`, "gi");
    const walk = (node) => {
      if (node.nodeType === 3) {
        const text = node.nodeValue;
        const spanWrapper = document.createElement("span");
        spanWrapper.innerHTML = text.replace(
          regex,
          '<span style="background-color: yellow;">$1</span>'
        );
        node.parentNode.replaceChild(spanWrapper, node);
      } else if (
        node.nodeType === 1 &&
        node.childNodes &&
        node.childNodes.length
      ) {
        for (let i = 0; i < node.childNodes.length; i++) {
          walk(node.childNodes[i]);
        }
      }
    };
    // Start walking the DOM tree
    walk(tempDiv);
    // Return the modified HTML content
    return tempDiv.innerHTML;
  };

  const handleCheckboxChange = (documentId) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = prevCheckedItems.includes(documentId)
        ? prevCheckedItems.filter((id) => id !== documentId)
        : [...prevCheckedItems, documentId];

      // Check the new state of checked items
      if (newCheckedItems.length === 0) {
        setFilteredSectionData([]);
      }

      return newCheckedItems;
    });
  };

  const handleSelectSection = () => {
    // console.log("Select button clicked");
    if (checkedItems.length === 0) {
      showToast(`RFP.searchSectionAuth`);
    } else {
      setSelectedSections(checkedItems);
      const filteredSections = asssetsectionData.filter((document) =>
        checkedItems.includes(document._id)
      );
      setFilteredSectionData(filteredSections);
      // console.log("Selected sections:", checkedItems);
    }
  };



  useEffect(() => {
    if (isgetDataEditor === false) {
      // setMergeDocColor(false)
    }
  }, [isgetDataEditor]);



  const handleCloseTaskAssignmentModal = () => {
    setTaskAssignmentModalOpen(false);
  };

  const handleOpenTaskAssignmentModal = (
    sectionId,
    documenttype,
    Assettype,
    createdby,
    title
  ) => {
    console.log(
      sectionId, documenttype, Assettype, createdby, title, "addmodall")
    if (!assetData?.isBidRequested) {
      let featurename = "Add User";
      const checkfeatureName = accessFeatures?.filter(
        (k) => k.name == featurename
      );
      // console.log(checkfeatureName[0].access, "checkfeatureName");
      if (!checkfeatureName[0].access) {
        showToast(`Proposal.taskAssign`);
      } else {
        if (createdby !== sUserId) {
          showToast(`Proposal.taskAssign`);
        } else {
          if (documenttype !== "sec") {
            return showToast(`Proposal.sectionShare`)
          } else {
            setTaskAssignmentModalOpen(true);
            setTaskAssignmentsectionId(sectionId);
            setSharedAssetSectionScreenType(Assettype);
            setSharedAssetSectionType(documenttype);
            setnewSectionTitle(title);
            getAllUserForSameOrgData()
          }
        }
      }
    } else {
      if (assetData?.acceptBidRequest) {
        return showToast(`RFP.rfpBidSuccessfully`)
      }
      showToast(`RFP.rfpBidRequested`)
    }
  };

  //reply to comment
  const handleReplyChange = (event) => {
    setReplyText(event.target.value);
  };

  const handleReply = async (id, replyMsg, isUpdateTrue) => {
    // console.log("insideReplyComment", id,replyMsg,isUpdateTrue)
    setIsReplyModelOpen(true);
    setSelectedCommentForReply(id);
    setReplyText(replyMsg);
    setCommentReplyUpdateIcon(isUpdateTrue);
  };

  const handleReplyTocomment = async (e) => {
    try {
      // console.log(replyText, userInfo.username, selectedCommentForReply)
      const response = await replyToComment(
        replyText,
        userInfo.username,
        selectedCommentForReply
      );
      // console.log("Reply called", response)
      if (response.status == 201) {
        toast.success(response.message);
        setIsReplyModelOpen(false);
        setReplyText("");
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.addReply,
          userInfo.username
        );
        handleGetActivity();
        handleGetTypeComment();
      } else {
        showToast(`Proposal.errSendReply`);
      }
    } catch (error) {
      console.log("Error while replying to comment", error);
    } finally {
      setIsReplyModelOpen(false);
    }
  };
  const handleReplyModelClose = async () => {
    setIsReplyModelOpen(false);
    setReplyText("");
  };

  const handleCancelReply = () => {
    setReplyText("");
    setIsReplyModelOpen(false);
  };

  const handleDeleteCommentReply = async (id) => {
    try {
      // console.log(id, "idFromDelete")
      const response = await deleteCommentReply(id);
      if (response.status == 200) {
        toast.success(response.message);
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.deleteReply,
          userInfo.username
        );
        handleGetActivity();
        handleGetTypeComment();
      }
    } catch (e) {
      console.log(e, "errro while deleting");
    }
  };

  const handleUpdateCommentReply = async () => {
    try {
      // console.log(selectedCommentForReply, userInfo.username, replyText,"____updateData")
      const response = await UpdateReplyOfComment(
        selectedCommentForReply,
        userInfo.username,
        replyText
      );
      if (response.status == 200) {
        toast.success(response.message);
        handleGetTypeComment();
        setIsReplyModelOpen(false);
        setReplyText("");
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.updateReply,
          userInfo.username
        );
        // console.log("update comment",activityResponse)
        handleGetActivity();
        setComments("");
      }
    } catch (e) {
      console.log(e, "error while deleting");
    }
  };

  //reply to notes
  const handleNotesReplyChange = (event) => {
    setNotesReplyText(event.target.value);
  };

  const handleNotesReply = async (id, replyMsg, isUpdateTrue) => {
    // console.log("insideReply", id,replyMsg,isUpdateTrue)
    setNotesReplyUpdateIcon(isUpdateTrue);
    setIsNotesReplyModelOpen(true);
    setSelectedNoteForReply(id);
    setNotesReplyText(replyMsg);
  };

  const handleReplyToNotes = async (e) => {
    try {
      // console.log(notesReplyText, userInfo.username, selectedNoteForReply)
      const response = await replyToComment(
        notesReplyText,
        userInfo.username,
        selectedNoteForReply
      );
      // console.log("Reply called", response)
      if (response.status == 201) {
        toast.success(response.message);
        setIsNotesReplyModelOpen(false);
        setNotesReplyText("");
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.addReply,
          userInfo.username
        );
        handleGetActivity();
        // handleGetTypeComment()
        handleGetComment();
      } else {
        showToast(`Proposal.errSendReply`);
      }
    } catch (error) {
      console.log("Error while replying to comment", error);
    } finally {
      setIsNotesReplyModelOpen(false);
    }
  };
  const handleNotesReplyModelClose = async () => {
    setIsNotesReplyModelOpen(false);
    setNotesReplyText("");
  };

  const handleCancelNotesReply = () => {
    setNotesReplyText("");
    setIsNotesReplyModelOpen(false);
  };

  const handleDeleteNotesReply = async (id) => {
    try {
      // console.log(id, "idFromDelete")
      const response = await deleteCommentReply(id);
      if (response.status == 200) {
        toast.success(response.message);
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.deleteReply,
          userInfo.username
        );
        handleGetActivity();
        // handleGetTypeComment()
        handleGetComment();
      }
    } catch (e) {
      console.log(e, "errro while deleting");
    }
  };

  const handleUpdateNotesReply = async () => {
    try {
      console.log(
        selectedNoteForReply,
        userInfo.username,
        notesReplyText,
        "___updatenotebody"
      );
      const response = await UpdateReplyOfComment(
        selectedNoteForReply,
        userInfo.username,
        notesReplyText
      );
      if (response.status == 200) {
        toast.success(response.message);
        // handleGetTypeComment()
        handleGetComment();
        setIsNotesReplyModelOpen(false);
        setNotesReplyText("");
        const activityResponse = await addActivity(
          rfmManagementData.value.assetid,
          assetType,
          activityMsg.updateReply,
          userInfo.username
        );
        // console.log("update comment",activityResponse)
        handleGetActivity();
      }
    } catch (e) {
      console.log(e, "error while deleting");
    }
  };

  const handleVersionModal = async (sectionId, version) => {
    const response = await specificVersion(sectionId);
    if (response == true) {
      setVersionModalOpen(true);
      setVersionSectionId(sectionId);
      setSelectedVersion(version);
    }
  };

  const handleCloseVersionModal = () => {
    setVersionModalOpen(false)
  }


  const getVersionData = async () => {
    try {
      const orgid = sessionStorage.getItem("organizationId")
      let data = {
        orgId: orgid,
        assetid: rfmManagementData?.value?.assetid
      }
      console.log(data, assetData, "___data")
      if (data.orgId && data.assetid) {
        const response = await getAllSectionVersions(data);
        if (response.status === 200) {
          console.log("getVersionData", response);
          setVersionData(response?.sectionVersions);
        } else {
          console.log("Error in response", response);
        }
      } else {
        console.log("Missing orgId or assetid", data);
      }

    } catch (error) {
      console.log("error while getting version");
    }
  };

  useEffect(() => {

    getVersionData();
    const intervalId = setInterval(getVersionData, 60000);
    return () => clearInterval(intervalId);

  }, []);


  const specificVersion = async (assetSectionId) => {
    try {
      if (assetSectionId) {
        console.log("assetSectionId:", assetSectionId);
        let data = {
          assetSectionId: assetSectionId,
        };
        const response = await getSpecificVersionData(data);
        if (response.status === 200) {
          console.log("getSpecificVersionData", response);
          const sortedVersions = response.documents.sort((a, b) => new Date(b.date) - new Date(a.date));
          setSpecificVersionData(sortedVersions);
          return true;
        }
      }

    } catch (error) {
      console.log("error while getting specific  version")
    }
  }
  const displayMappedClauses = (mapped_clauses, filename) => {
    if (!mapped_clauses || mapped_clauses.every((clause) => clause === "undefined")) {
      return "N/A";
    } else {

      // console.log("mapped_clauses",mapped_clauses)
      return mapped_clauses.map((clause, index) => {
        const isPresent = extractedClause?.clauses?.some(data =>
          data.filename === filename && data.responseData.split('\n').includes(clause)

        );
        console.log("isPresent", isPresent)
        return (
          <span key={index} style={{ color: isPresent ? 'green' : 'red' }}>
            {clause}
          </span>
        );
      })

    }
  };


  useEffect(() => {
    if (filteredSectionData && filteredSectionData.length > 0) {
      if (isFilteredAndSearch) {
        setSectionsArray(initialfilteredSectionData);
      } else {
        setSectionsArray(filteredSectionData);
      }
    } else {
      setSectionsArray(asssetsectionData);
    }
  }, [filteredSectionData, isFilteredAndSearch, initialfilteredSectionData, asssetsectionData]);

  const removePartPrefix = (partName) => {
    return partName.replace(/^Part \d+ - /, '');
  };


  const handleAcceptClause = async (manualClauseId, assetid, extractedClauseid) => {

    try {
      let data = {
        assetId: assetid,
        extractedClauseId: extractedClauseid,
        email: cuurentUser,
        clauseId: manualClauseId

      }
      const response = await approveClause(data)
      if (response.status == 200) {
        getExtractedClause()
        console.log(response, "Response of approved clause")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleRejectClause = async (manualClauseId, assetid, extractedClauseid) => {

    try {
      let data = {
        assetId: assetid,
        extractedClauseId: extractedClauseid,
        email: cuurentUser,
        clauseId: manualClauseId
      }
      const response = await rejectClause(data)
      if (response.status == 200) {
        console.log(response, "Response of approved clause")
        getExtractedClause()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteReasonModalOpen = (id, clause, dataid) => {
    console.log(id, clause, dataid, "VVVV")
    setDataForDeleteClause({
      id,
      clause,
      dataid
    });
    setIsOpenDeleteReasonModel(true);
  };
  const handleDeleteReasonModalclose = () => {
    setIsOpenDeleteReasonModel(false);
    setDeleteReason("");
  };

  const handleAddReasonModalOpen = () => {
    if (!assetData?.isBidRequested) {
      if (addManualClause == "") {
        return showToast(`Proposal.provideClause`)
      }
      const existingClauses = new Set();
      if (extractedClause?.clauses?.length) {
        extractedClause.clauses.forEach(clause => {
          clause.responseData.split("\n").forEach(c => existingClauses.add(c.trim()));
        });
      }
      if (extractedClause?.manualClause?.length) {
        extractedClause.manualClause.forEach(clause => {
          existingClauses.add(clause.clause.trim());
        });
      }

      // Check if the addManualClause already exists
      if (existingClauses.has(addManualClause.trim())) {
        return showToast(`Proposal.clauseExist`);
      }
      setIsOpenAddReasonModel(true);
    } else {
      if (assetData?.acceptBidRequest) {
        return showToast(`RFP.rfpBidSuccessfully`)
      }
      showToast(`RFP.rfpBidRequested`)
    }
  };
  const handleAddReasonModalclose = () => {
    setIsOpenAddReasonModel(false);
    setAddReason("");
  };


  const handleRowSelect = (row) => {
    setSelectedRow(row);
  };

  console.log(selectedRow, "__selectedRow")

  const handleSendData = async () => {
    if (selectedRow) {
      setLoading(true)
      const dataToSend = {
        requirementData: requirementDataForSuggestion,
        requirementText: selectedRow.requirement_text,
        sectionTitle: selectedRow.section_title
      };

      const response = await keywordForSamrtSuggestion(dataToSend);
      if (response.status == 200) {
        console.log("keywords___", response.data);
        const parsedKeywords = parseKeywords(response.data[0]);
        setKeywords(parsedKeywords)
        setLoading(false)
      }
    } else {
      showToast(`Proposal.selectRow`);
    }
    setLoading(false)
  };

  const parseKeywords = (keywordsString) => {
    const keywordsArray = keywordsString.split('\n')
      .map(keyword => keyword.trim()) // Trim whitespace
      .filter(keyword => keyword.length > 0); // Remove empty lines

    // Remove number prefix from the first element if it exists
    if (keywordsArray.length > 0) {
      const firstKeyword = keywordsArray[0].replace(/^\d+\.\s*/, ''); // Remove number and dot at the start
      keywordsArray[0] = firstKeyword;
    }

    // Map to array of objects with id and keyword
    const parsedKeywords = keywordsArray.map((keyword, index) => ({
      id: index + 1,
      keyword
    }));

    return parsedKeywords;
  };

  const options = keywords.map((keyword) => ({
    value: keyword.keyword,
    label: keyword.keyword
  }));

  // Find the selected option
  const selectedOption = options.find(option => option.value === selectedKeyword);


  const handleSelectChange = async (selectedOption) => {
    const selectedValue = selectedOption.value;
    console.log("Selected value:", selectedValue);

    setSelectedKeyword(selectedValue);
    setLoading(true);

    const dataToSend = {
      selectedKeyword: selectedValue
    };

    try {
      const response = await smartSuggestion(dataToSend);
      if (response.status === 200) {
        console.log("keywordData___", response.data);
        setKeywordData(response.data.output);
      } else {
        showToast(`Proposal.errKeywordData`);
      }
    } catch (error) {
      showToast(`Proposal.errKeywordData`);
    } finally {
      setLoading(false);
    }
  };


  // Function to start editing a row
  const handleEdit = (rowId, requirementText) => {
    setEditableRow(rowId);
    setEditedRequirementText(requirementText);
  };

  // Function to save the edited text and update the dummy state
  const handleSave = (rowId) => {
    setDummy(prevDummy =>
      prevDummy.map(row =>
        row.requirement_id === rowId
          ? { ...row, requirement_text: editedRequirementText }
          : row
      )
    );
    showToast(`Success.dataSaved`);
    setEditableRow(null);
  };

  const handleCloseDiv = () => {
    setKeywordData('');
    setKeywords([])
  };



  // Close modal
  const closeModalCustom = () => {
    setIsModalOpenCustom(false);

  };

  const handleCloseAddClause = () => {
    setOpenModalClauseAdd(false);
    setClausesInput('');
  };

  const handleClauseChange = (value) => {
    setClausesInput(value);
    console.log("value input", value);
  };

  const handleAddClause = () => {
    if (currentRow) {
      const updatedDummy = dummy1.map((row) => {
        if (row.requirement_id === currentRow.requirement_id) {
          return {
            ...row,
            manualClauses: row.manualClauses ? [...row.manualClauses, clausesInput] : [clausesInput],
          };
        }
        return row;
      });
      setDummy(updatedDummy);
      setDummy1(updatedDummy);

      setClausesInput('');
      setOpenModalClauseAdd(false); // Close the modal after adding the clause
    }
  };



  const handleDeleteAddedClause = (row, manualClause) => {
    if (row) {
      const updatedDummy = dummy.map((data) => {
        if (data.requirement_id === row.requirement_id) {
          const updatedManualClauses = data.manualClauses.filter(clause => clause !== manualClause);
          return {
            ...data,
            manualClauses: updatedManualClauses,
          };
        }
        return data;
      });

      setDummy(updatedDummy);
      setDummy1(updatedDummy);
    }
  };



  const fetchClausesList = async () => {
    const response = await getClausesList()
    const processClauses = (clauses) => {
      return clauses.map(clause => clause.split('#')[1]);
    };
    console.log("fetchClausesList", response)
    setFarClauses(processClauses(response?.farClauses));
    setDfarClauses(processClauses(response?.dfarClauses));

  }

  const handleSelectClauseChange = (selectedOptions, requirementId) => {
    setselectedClauseResources(prevSelected => ({
      ...prevSelected,
      [requirementId]: selectedOptions
    }));

    const selectedClauses = selectedOptions.map(option => option.value);

    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        return {
          ...row,
          manualClauses: selectedClauses,
        };
      }
      return row;
    });
    console.log("updatedDummy", updatedDummy)
    setDummy(updatedDummy);
    setDummy1(updatedDummy);

  };

  const handleSelectSectionChange = (selectedOptions, requirementId) => {
    setSelectedSectionMap(prevSelected => ({
      ...prevSelected,
      [requirementId]: selectedOptions
    }));

    const selectedSection = selectedOptions.map(option => option.value);

    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        return {
          ...row,
          manualSection: selectedSection,
        };
      }
      return row;
    });
    console.log("updatedDummy", updatedDummy)
    setDummy(updatedDummy);
    // setDummy1(updatedDummy);

  };


  const sectionDummyData = [
    { label: "Section A", value: "Section_A" },
    { label: "Section B", value: "Section_B" },
    { label: "Section C", value: "Section_C" },
  ];


  const combinedClauses = [
    ...farClauses.map(clause => ({ value: clause, label: clause })),
    ...dfarClauses.map(clause => ({ value: clause, label: clause }))
  ];

  useEffect(() => {
    async function fetchResources() {
      try {
        const assetId = rfmManagementData?.value?.assetid;

        if (!orgId || !assetId) {
          console.error('orgId and assetId are required.');
          return;
        }

        const response = await getresourceallocation(assetId, orgId);
        const data = response.data;
        const options = data.map(resource => ({
          value: resource._id,
          label: resource.resourceFullName,
          payperhour: resource.payPerHour
        }));
        setResourceOptions(options);
        console.log(resourceOptions, "resourceOptions")
      } catch (error) {
        console.error('Error fetching resource allocation:', error.message);
      }
    }
    fetchResources();
  }, [orgId, rfmManagementData?.value?.assetid]);


  const fetchRequirementMappingHistory = async () => {
    try {
      const assetId = rfmManagementData?.value?.assetid;



      if (!orgId || !assetId) {
        console.error('orgId and assetId are required.');
        return;
      }

      const response = await getRequirementMapping(assetId, orgId);
      const data = response?.requirements;
      setRequirementMappingHistory(data)


    } catch (error) {
      console.error('Error fetching requirement mapping history:', error.message);
    }
  }

  useEffect(() => {
    if (requirementMappingHistory.length > 0) {
      setRequirementMappingHistoryExists(true)
    }
  }, [requirementMappingHistory])



  const handleEffectiveHoursChange = (e, requirementId) => {
    const hours = e.target.value;
    setEffectiveHours(prevState => ({
      ...prevState,
      [requirementId]: hours
    }));

    const selected = selectedResources[requirementId] || [];
    const payRates = selected.reduce((acc, resource) => {
      const resourceOption = resourceOptions.find(option => option.value === resource.value);
      if (resourceOption) {
        acc[resource.value] = resourceOption.payperhour;
      }
      return acc;
    }, {});

    const totalAmount = selected.reduce((sum, resource) => {
      const payRate = payRates[resource.value] || 0;
      const totalHours = hours / selected.length;
      return sum + (payRate * totalHours);
    }, 0);

    setTotalBillingAmounts(prevState => ({
      ...prevState,
      [requirementId]: totalAmount
    }));



    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        return {
          ...row,
          hours: hours,
          totalAmount: totalAmount,
        };
      }
      return row;
    });
    console.log("updatedDummynew22", updatedDummy)
    setDummy(updatedDummy);

    console.log("ZZX1", "hours:", hours, "payRates:", payRates, "totalAmount:", totalAmount)
  };

  const handleResourcesChange = (selectedOptions, requirementId) => {
    const selected = selectedOptions || [];
    setSelectedResources(prevState => ({
      ...prevState,
      [requirementId]: selected
    }));

    const payRates = selected.reduce((acc, resource) => {
      const resourceOption = resourceOptions.find(option => option.value === resource.value);
      if (resourceOption) {
        acc[resource.value] = resourceOption.payperhour;
      }
      return acc;
    }, {});

    setResourcePayRates(prevState => ({
      ...prevState,
      [requirementId]: payRates
    }));

    const hours = effectiveHours[requirementId] || 0;
    const totalAmount = selected.reduce((sum, resource) => {
      const payRate = payRates[resource.value] || 0;
      const totalHours = hours / selected.length;
      return sum + (payRate * totalHours);
    }, 0);

    setTotalBillingAmounts(prevState => ({
      ...prevState,
      [requirementId]: totalAmount
    }));

    const selectedResource = selected.map((option) => option.value)
    console.log("ZZX", "selectedResource:", selectedResource, "totalAmount:", totalAmount, "payrate:", payRates)
    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        // const existingPayRates = Array.isArray(row.payRates) ? row.payRates : [];

        // // Merge new payRates with existingPayRates
        // const mergedPayRates = {...existingPayRates, ...payRates};

        return {
          ...row,
          Resources: selectedResource,
          totalAmount: totalAmount,
          payRates: payRates, // Store as an object
        };
      }
      return row;
    });
    console.log("updatedDummynew", updatedDummy)
    setDummy(updatedDummy);
  };

  const handleRemarksChange = (e, requirementId) => {
    const remark = e.target.value;
    setRemarks(prevState => ({
      ...prevState,
      [requirementId]: remark
    }));

    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        return {
          ...row,
          remark
        };
      }
      return row;
    });
    setDummy(updatedDummy);
  };


  const deleteExistingSections = async (data) => {
    try {
      const response = await deleteExisting(data);
      if (response.status === 200) {
        return response;
      } else {
        console.log("Failed to delete existing sections.");
        return null;
      }
    } catch (error) {
      console.log("error in deleting existing section", error);
      return null;
    }
  };

  const calculateAggregates = (dummy) => {
    let totalResources = 0;
    let totalHours = 0;
    let totalAmount = 0;
    console.log("Aggregate", dummy)
    dummy.forEach(item => {
      // Check if Resources is an array and sum the number of resources
      if (Array.isArray(item.Resources)) {
        totalResources += item.Resources.length;
      }

      if (item.hours) {
        let hours = parseFloat(item.hours);
        if (!isNaN(hours)) {
          totalHours += hours;
        } else {
          console.log("Invalid hours value:", item.hours);
        }
      }

      // Check and sum the amount
      if (item.totalAmount) {
        let amount = parseFloat(item.totalAmount);
        if (!isNaN(amount)) {
          totalAmount += amount;
        } else {
          console.log("Invalid amount value:", item.totalAmount);
        }
      }
    });

    return {
      totalResources,
      totalHours,
      totalAmount
    };
  }


  const getTaskCompletionOnpageLoad = async () => {
    try {
      let data = {
        assetId: rfmManagementData.value.assetid,
        orgId
      };
      const response = await getTeamTask(data);
      if (response.status == 200) {
        setTeamTasks(response.data)
      } else {
        // toast.error("Error in fetching tasks. Please try again.");
      }

    } catch (error) {
      console.log(error);
      showToast(`Error.errorOccured`);
    }
  }


  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setSideModal(!sideModal)
  };
  const [isTaskAssignedCommentOpen, setisTaskAssignedCommentOpen] = useState(false)
  const [isFetchedCommentModalOpen, setIsFetchedCommentModalOpen] = useState(false)
  const [sectionCommentData, setSectionCommentsdata] = useState('')
  const [taskAsignmentcomment, setTaskAsignmentComment] = useState({
    'approved': '',
    'acceptboth': '',
    'reject': ''
  });

  const handleOpenTaskAssignedCommentModal = () => {
    setTaskAsignmentComment({
      'approved': '',
      'acceptboth': '',
      'reject': ''
    })
    setisTaskAssignedCommentOpen(true)
  }

  const handleCloseTaskAssignedCommentModal = () => {
    setisTaskAssignedCommentOpen(false)

  }


  const handleTaskAsignmentCommentChange = (event) => {
    const value = event.target.value;

    setTaskAsignmentComment(prevState => ({
      approved: taskApproveStatus === 'approved' ? value : '',
      acceptboth: taskApproveStatus === 'acceptboth' ? value : '',
      reject: taskApproveStatus === 'reject' ? value : '',
    }));
  };

  const handleSubmitTaskComment = () => {
    const comment = taskAsignmentcomment[taskApproveStatus];

    if (!comment) {
      showToast(`Proposal.enterComment`);
      return;
    }

    if (taskApproveStatus === 'approved') {
      showToast(`Proposal.taskApprove`);
    } else if (taskApproveStatus === 'acceptboth') {
      showToast(`Proposal.bothTaskApproved`);
    } else {
      showToast(`Proposal.taskReject`);
    }

    // Here, you can add any additional logic needed after submission
    setCommentMessage(comment)
    handleCloseTaskAssignedCommentModal();

  };

  const handleFetchComments = (document) => {
    setSectionCommentsdata(document)
    setIsFetchedCommentModalOpen(true)
    console.log("handleFetchComments", document)

  }

  const handleCloseFetchedCommentModal = () => {
    setIsFetchedCommentModalOpen(false)
  }


  const handleExpandIcon = () => {
    setIsExpanded2(!isExpanded2)
    setSideModal(!sideModal)
  }

  useEffect(() => {
    if (isExpanded2) {
      setSideModal(true)
    }
  }, [isExpanded2])


  const handleSelectAttachment = (attachmentName) => {
    const attachmentUrl = attachmentName;
    const isSelected = resourceLinks.includes(attachmentUrl);

    if (isSelected) {
      const updatedLinks = resourceLinks.filter((link) => link !== attachmentUrl);
      setResourceLink(updatedLinks);
    } else {
      if (resourceLinks.length < 5) {
        setResourceLink([...resourceLinks, attachmentUrl]);
      } else {
        showToast(`Proposal.errDocLimit`)
      }
    }
  };



  const handleCloseAttachmentModal = () => {
    setIsOpenAttachmentModal(false)
  }

  const handleOpenAttachmentModal = () => {
    setIsOpenAttachmentModal(true)
  }


  const handleSectorFieldChange = (selectedOption) => {
    setSelectedSectors(selectedOption);
  };

  const handleSelectFieldChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'select-all')) {
      if (selectedFields.length === AiData.length - 1) {
        setSelectedFields([]);
      } else {
        setSelectedFields(AiData.filter(option => option.value !== 'select-all'));
      }
    } else {
      setSelectedFields(selectedOptions || []);
    }
  };

  async function sectionCreation() {
    setLoading(true)
    console.log("openAiSectionsList.length", openAiSectionsList)
    console.log("openAiSectionsList.length", openAiSectionsList.length)
    if (!openAiSectionsList || openAiSectionsList.length === 0) {
      showToast(`RFP.generateSectionWarning`);
      setLoading(false)
      return;
    }
    if (!selectedFields || selectedFields.length === 0) {
      showToast(`Proposal.oneSec`);
      setLoading(false)
      return;
    }
    const selectedHeadings = selectedFields?.map(data => data.value);
    const newHeadings = selectedHeadings?.filter(heading => !predefinedSections.includes(heading));
    console.log("New headings to add:", newHeadings, selectedHeadings);

    try {

      const extractedText = attachmentPDFText?.replace(/[\n\r]+/g, ' ');;
      const dummyStope = await aiResponce(extractedText)
    }
    catch (error) {
      console.log(error)
      showToast(`Error.errorOccured`)

    } finally {
      setLoading(false)
    }
  }

  async function aiResponce(extractedText) {
    setLoading(true);

    let sectionResponse = null;
    let assetSectionData = null;
    let organizationName = userInfo?.organizationName
    let category = departMentSector

    try {
      const selectedHeadings = selectedFields?.map(data => data.value);


      // Call improveInput only if there are headings for improvement
      if (selectedHeadings.length > 0) {
        sectionResponse = await improveSectionContent(extractedText, selectedHeadings);

        console.log("Improved sections:", sectionResponse);

        if (sectionResponse && Array.isArray(sectionResponse)) {
          for (const section of sectionResponse) {
            await AddSectionData2(section.Heading, " ");
          }

          try {
            assetSectionData = await getAssetsection(
              rfmManagementData.value.id, assetType, rfmManagementData.value.assetid
            );
          } catch (error) {
            console.error("Error getting asset section data:", error);
            // Handle error if needed
          }
          console.log("assetSectionData", assetSectionData)
          if (sectionResponse && assetSectionData && assetSectionData.data) {
            for (const section of sectionResponse) {
              const newTitle = section.Heading.toLowerCase()
              const matchedSection = assetSectionData.data.find(
                assetSection => assetSection.title.toLowerCase() === newTitle
              );
              console.log("Adding HTML content for section:", section.Heading);
              console.log("matchedSection", matchedSection)
              if (matchedSection) {
                const formData = new FormData();
                const blob = new Blob([section?.Content], { type: "text/html" });
                const newTitle = matchedSection.title.replace("/",'')
                formData.append("filename", blob, newTitle);  // Append content as file with generated filename
                formData.append("orgName", userInfo.organizationName);
                formData.append("assetid", matchedSection.assetId);
                formData.append("assetsectionId", matchedSection._id); // Use assetsectionId from matchedSection
                formData.append("htmlContent", section.Content); // Use Content from the first API response
                formData.append("userId", userInfo._id);
                formData.append("orgId", matchedSection.orgId);
                formData.append("assetType", "rfp");

                try {
                  console.log("goan to next function ")
                  await addContentInAutoSection(formData);
                } catch (err) {
                  console.log(`Error uploading section ${section.Heading}:`, err);
                  showToast(`RFP.addSectionError`);
                }
              }
            }
          }
          console.log("All sections improved successfully.");
        } else {
          console.log("Invalid section response format or empty response.");
        }
      } else {
        console.log("No headings to improve found in predefined sections.");
      }
    } catch (error) {
      console.error("Error adding or improving sections:", error);
      showToast(`RFP.processSectionError`);
    } finally {
      setLoading(false);
    }



    console.log("Asset section data:", assetSectionData);
    console.log("Section response:", sectionResponse);
    showToast(`RFP.processSectionSuccess`);

  }



  const AddSectionData2 = async (heading, description) => {
    try {

      const response = await addAssetsection(rfmManagementData.value.assetid, description, heading, "rfp", "Ai-generated", orgId, userInfo._id)
      if (response.status === 200) {
        console.log(`Section "${heading}" added successfully.`);
        // Handle success if needed
      } else {
        console.log(`Failed to add section "${heading}".`);
        // Handle failure if needed
      }
    } catch (error) {
      console.log("Error adding section:", error);
      // Handle error if needed
    }
  }

  const addContentInAutoSection = async (formData1) => {

    try {

      const response = await axios.post(
        `https://api.contractpro360.com/api/newbucket/assetsectionuploads/${rfmManagementData.value.id}`,
        formData1,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        // console.log("upload response", response)
        // toast.success("uploaded successfully");
        return true
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCallFileReader = async () => {
    try {
      setLoading(true)
      if (!resourceLinks || resourceLinks.length === 0) {
        showToast(`RFP.generateSectionAuth`)
        return
      }
      const response = await extractPdfData(
        resourceLinks
      );

      const MultipleFile = response.join("")
      console.log("MultipleFile", MultipleFile)
      const dummyStope = await aiSectionResponce(MultipleFile)

      setAttachmentPDFText(MultipleFile)
      console.log("snehal", response)

    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  async function aiSectionResponce(extractedText) {
    setLoading(true);

    let sectionResponse = null;
    let assetSectionData = null;

    try {
      sectionResponse = await improveInputSection(extractedText, departMentSector);

      console.log("Improved sections:", sectionResponse);
      const keysOptions = [
        { value: 'select-all', label: 'Select All' },
        ...sectionResponse?.sections?.map((key) => ({
          value: key,
          label: key,
        }))];
      console.log("keysOptions", keysOptions)
      if (!assetDetails?.aiSections?.length > 0) {

        setOpenAiSectionsList(keysOptions)
        addAiSectionsData(keysOptions)
      }



      console.log("All sections improved successfully.");

    } catch (error) {
      console.error("Error adding or improving sections:", error);
      showToast(`RFP.processSectionError`);
    }
    setLoading(false);
  }


  async function addAiSectionsData(sections) {
    try {
      const assetId = rfmManagementData?.value?.assetid;
      const data = { sections, assetId }
      console.log(data, "dataCheck_")
      const response = await addAisections(data);
      if (response.status == 200) {
        console.log(response, "addAiSectionResponse")
      }

    } catch (error) {
      console.log(error, "Error adding sections.")
    }
  }

  return (
    <>
      <Loader loading={loading} />

      <>
        <div className="row" style={{ backgroundColor: "white" }}>
          {!isgetDataEditor && (
            <>
              <div className="col-md-9">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      rowGap: "5px",
                      // justifyContent: "space-between",

                      alignItems: "center",

                    }}
                  >
                    <Button
                      className="CTAButtons"
                      data-tip
                      data-for="uploadsectiondoc"
                      component="label"
                      htmlFor="OppoUpload"
                    >
                      <ReactTooltip id="uploadsectiondoc" place="right" effect="solid">
                        Upload PDF to create section
                      </ReactTooltip>
                      Upload

                      <span style={{ marginLeft: "8px" }}>
                        <FileUploadIcon />
                      </span>
                      <VisuallyHiddenInput
                        id="OppoUpload"
                        type="file"
                        key={inputKey}
                        onChange={handleFileChange} // Directly call the function here
                      />
                    </Button>

                    <Button
                      onClick={() => {
                        handleAddopenModal(true);
                      }}
                      variant="outlined"
                      className="CTAButtons"
                      data-tip
                      data-for="addsections"
                    >
                      <ReactTooltip id="addsections" place="bottom" effect="solid">
                        Click to add new section
                      </ReactTooltip>
                      Add
                      <span style={{ marginLeft: "8px" }}>
                        <AddIcon />
                      </span>
                    </Button>
                    {/* <ShareAssetsPage
                      isOpen={modalOpenshr}
                      onClose={closeModalshr}
                      users={users}
                      existingusers={existingusers}
                      assetId={rfmManagementData.value.assetid}
                      currentuser={cuurentUser}
                      assetType={assetType}
                    /> */}
                    <TaskAssignmentModal
                      isOpen={isTaskAssignmentModalOpen}
                      onClose={handleCloseTaskAssignmentModal}
                      memberList={users}
                      sectionHead={newSectionTitle}
                      sectionId={taskAssignmentsectionId}
                      assetId={rfmManagementData?.value?.assetid}
                      assetType="rfp"
                      currnetUser={cuurentUser}
                      currentUserName={userInfo?.username}
                      assetSectionS={asssetsectionData?.filter((f) => f.type == "sec" && f.assetType == "rfp")}
                      existingusers={existingusers}
                      sectionType={sharedAssetSectionType}
                    // assetSharingData={assetSharingData}
                    />

                    {/* <ShareAssetsSectionPage
                      isOpen={modalOpenSectionshr}
                      onClose={closeModalSectionshr}
                      users={users}
                      existingusers={existingusers}
                      assetName={propsPassed?.title}
                      assetId={rfmManagementData.value.assetid}
                      currentuser={cuurentUser}
                      assetType={assetType}
                      assetSectionId={assetsectionId}
                      sectionType={sharedAssetSectionType}
                      sectionScreenType={sharedAssetSectionScreenType}
                      userName={userInfo.username}
                    /> */}


                    <Button
                      onClick={toggleModal}
                      // title="Swap Your Sections"
                      variant="outlined"
                      className="CTAButtons"
                      data-tip
                      data-for="swap"
                    >
                      <ReactTooltip id="swap" place="bottom" effect="solid">
                        Swap Your Sections
                      </ReactTooltip>
                      Swap
                      {/* <span style={{ marginLeft: "8px" }}>
                        <SwapVertRoundedIcon />
                      </span> */}
                      <span>
                        <img
                          style={{
                            height: "19px",
                            marginTop: "0px",
                            marginLeft: "8px",
                            verticalAlign: "middle",
                          }}
                          src={Swapping}
                          alt="Description of the image"
                        />
                      </span>
                    </Button>
                    {modalOpen && (
                      <ReorderModal
                        isOpen={modalOpen}
                        onClose={closeModal}
                        items={asssetsectionData}
                        setItems={setAssetSectionData}
                      />
                    )}

                    <Button
                      onClick={toggleReorderModal}
                      variant="outlined"
                      className="CTAButtons"
                      data-tip
                      data-for="Reorder"
                    >
                      <ReactTooltip id="Reorder" place="bottom" effect="solid">
                        Reorder Your Sections
                      </ReactTooltip>
                      Reorder
                      <span style={{ marginLeft: "8px" }}>
                        <SwapVertRoundedIcon />
                      </span>
                    </Button>

                    <Button
                      // title="Extract Clauses"
                      className="CTAButtons"
                      variant="outlined"
                      id="clauseButton"
                      onClick={() => {
                        handleClauseExtraction();
                      }}
                      data-tip
                      data-for="Clauses"
                    >
                      <ReactTooltip id="Clauses" place="bottom" effect="solid">
                        Extract Clauses
                      </ReactTooltip>
                      Clause
                      {/* <span style={{ marginLeft: "8px" }}>
                  <DeleteIcon />
                </span> */}
                    </Button>

                    <Button
                      className="CTAButtons"
                      variant="outlined"
                      id="clauseButton"
                      onClick={() => openReportModal()}
                      data-tip
                      data-for="Reports"
                    >
                      <ReactTooltip id="Reports" place="bottom" effect="solid">
                        See RFP Reports
                      </ReactTooltip>
                      Report

                    </Button>

                    {showReorderModal && (
                      <ReorderSequence
                        items={asssetsectionData}
                        setItems={setAssetSectionData}
                        toggleModal={toggleReorderModal}
                      />
                    )}

                    <div>
                      <Button
                        onClick={() => {
                          handleBacktoOpportunity();
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                  {/* 
            <Button  variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: '#1976D2', borderColor: '#1976D2', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }}>Back</Button> */}


                </div>
                {/* search */}
                <div className="mt-3 d-flex gap-3 search-bar">
                  <div className="col-md-6 ">
                    <TextField
                      label={
                        <div>
                          <SearchIcon /> Search
                        </div>
                      }
                      variant="outlined"
                      size="small"
                      style={{
                        width: "100%",
                        borderRadius: "20px",
                        marginLeft: "-15px",
                      }}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <Button
                    title="Select section(s) for search"
                    variant="contained"
                    color="primary"
                    onClick={handleSelectSection}
                  >
                    Select
                  </Button>
                  {assetData?.isBidRequested && !assetData?.acceptBidRequest && !assetData?.rejectBidRequest && hasRequiredRole && (
                    <button onClick={handleBidModalOpen}>
                      <i
                        className="fa fa-asterisk blurb-ripple-out point"
                        style={{ color: "orange" }}
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}





                </div>
                {isOpenBidModel && (
                  <Dialog
                    open={isOpenBidModel}
                    onClose={handleBidModalclose}
                    PaperProps={{
                      style: {
                        minWidth: '400px',
                        // minHeight: '240px',
                        maxWidth: '20vw',
                        // maxHeight: '20vh',
                        padding: "15px"
                      },
                    }}
                  >
                    <DialogContent
                      PaperProps={{
                        style: {
                          width: '100%',
                          height: '100%',
                        }
                      }}>
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <h4>Manage Bid</h4>
                          <HighlightOffIcon onClick={handleBidModalclose} style={{ color: "red" }} />
                        </div>
                        <br />
                        <DialogContentText>
                          Are you sure you want to proceed with this bid? This will commit resources to prepare and submit a proposal.
                        </DialogContentText>

                        <br />
                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                          <Button
                            variant="contained"
                            style={{ marginRight: "15px", backgroundColor: "green", color: "white" }}
                            onClick={handleBidClick}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "red", color: "white" }}
                            onClick={handleNoBidClick}
                          >
                            Reject
                          </Button>
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                )}

                {!assetData?.isBidRequested && (
                  <>
                    <div className="mt-3 gap-3">
                      {!isAdmin &&
                        <Button variant="contained" color="primary" onClick={() => handleRTBClick()}>REQUEST TO BID</Button>
                      }
                    </div>

                    <TaskCompletionModal
                      open={taskCompletionModal}
                      handleClose={closeTaskCompletionModal}
                      tasks={teamTasks.teamTasks}
                      handleComplete={handleTaskCompletion}
                    />
                  </>
                )}

                {assetData?.isBidRequested && !hasRequiredRole && (
                  <div className="mt-3 gap-3">
                    <marquee style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", color: "red", width: "95%", height: "50px", fontSize: "16px", fontWeight: "bold", animation: "marquee 10s linear infinite" }}>Bid Request has been Sent to Management</marquee>

                  </div>
                )}



                <div class="title-card">
                  <div class="title-card-heading">
                    <h5 style={{ fontSize: mobileView ? '12px' : '15px' }}><b>{propsPassed?.title}</b></h5>
                  </div>

                  <div class="title-current-team">
                    <p style={{ marginTop: '13px' }}><b>{teamTasks?.teamName}</b></p>
                    <p style={{ margin: '10px' }}><b> - </b></p>
                    <p class="status"><b>{teamTasks?.status}</b></p>
                  </div>

                  <div class="submission-date">
                    <b><p style={{ fontSize: mobileView ? '12px' : '' }}>Submission Date</p></b>
                    <p style={{ color: statusColor }}>{normalDate}</p>
                  </div>

                  <div class="created-time">
                    <b><p style={{ fontSize: mobileView ? '12px' : '' }}>{userInfo.username}</p></b>
                    <p title="Created Asset Time">{createdTime}</p>
                  </div>

                  <Button>
                    <ReceiptLongIcon onClick={handleOpenAttachmentModal} />
                  </Button>


                </div>




                <Accordion
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "10px",
                    width: "100%",
                  }}
                >

                  <AccordionSummary
                    onClick={(e) => handleSummaryModalOpen(e)}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: "10px",
                      }}
                    >
                      <div>

                        <b>
                          <h4 style={{ marginBottom: '3px' }} data-tip data-for="summaryTip">
                            Summary
                          </h4>
                          <ReactTooltip id="summaryTip" place="bottom" effect="solid">
                            detail notice summary
                          </ReactTooltip>

                          {" "}
                        </b>

                      </div>


                    </div>
                  </AccordionSummary>


                </Accordion>
                {rfmManagementData?.value?.resourceLinksPath?.length > 0 && (
                  <Accordion
                    sx={{
                      borderRadius: "10px",
                      border: "3px solid black",
                      marginTop: "10px",
                      width: "100%",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <AccordionSummary
                      style={{ padding: "4px" }}
                      onClick={() => handleAccordionClick()}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: "10px",

                        }}
                      >
                        <div>
                          <b>

                            <h4 style={{ marginBottom: '3px' }} data-tip data-for="attachmentsTip">
                              Attachments
                              {" "}
                            </h4>
                            <ReactTooltip id="attachmentsTip" place="bottom" effect="solid">
                              see Attachment list
                            </ReactTooltip>

                          </b>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {expanded && (
                        <Table striped bordered hover>
                          <tbody>
                            {rfmManagementData?.value?.resourceLinksPath?.map(
                              (attachment, index) => (
                                <tr key={index}>
                                  <td>
                                    <div
                                      className="attachment-title"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <InsertDriveFileIcon
                                        style={{ marginRight: "5px" }}
                                      />
                                      {attachment?.linkname.replaceAll('+', ' ')}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        color: "#1976D2",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <button
                                        style={{
                                          padding: "4px",
                                          color: "#1976D2",
                                        }}
                                        onClick={() =>
                                          DownloadAttachments(
                                            attachment?.linkname,
                                            attachment.path
                                          )
                                        }
                                        data-tip
                                        data-for="attachment Download"
                                      >
                                        <DownloadIcon />{" "}
                                      </button>
                                      <ReactTooltip id="attachment Download" place="bottom" effect="solid">
                                        Download this attachment
                                      </ReactTooltip>
                                      <button
                                        style={{
                                          padding: "4px",
                                          color: "#1976D2",
                                        }}
                                        onClick={() =>
                                          handlePreviewClick(
                                            attachment.linkname,
                                            attachment.path
                                          )
                                        }
                                        data-tip
                                        data-for="attachment"
                                      >
                                        <PreviewIcon />{" "}
                                      </button>
                                      <ReactTooltip id="attachment" place="bottom" effect="solid">
                                        Preview this attachment
                                      </ReactTooltip>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                {asssetsectionData && asssetsectionData.length > 0 && (
                  <>
                    <React.Fragment
                    // key={index}
                    >
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",

                        }}
                      >
                        <div
                          className="opp-section"
                          style={{
                            flex: isLeftPaneVisible ? "1 1 20%" : "1 1 0%",
                            padding: "10px",
                            borderRight: "1px solid #ccc",
                            // position: "fixed",
                            overflowY: "auto",
                            maxHeight: "45vh",
                            textAlign: "left",
                            fontSize: "1rem",
                          }}
                        >
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={toggleLeftPane}
                          >
                            {isLeftPaneVisible ? "Hide" : "Show"} Sections
                          </button>
                          {/* <h2>Sections</h2> */}
                          {isLeftPaneVisible && (
                            <ul
                              style={{
                                listStyleType: "none",
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              {sectionsArray && sectionsArray.length > 0 && (
                                <>
                                  {sectionsArray.map((document, index) => (
                                    <div style={{ display: "flex" }}>
                                      <input
                                        type="checkbox"
                                        checked={checkedItems.includes(
                                          document._id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(document._id)
                                        }
                                        style={{ marginRight: "5px" }}
                                      />
                                      <li
                                        key={document._id}
                                        onClick={() =>
                                          handleSectionClick(document._id)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          backgroundColor: "#f5f5f5",
                                          margin: "10px 0",
                                          padding: "10px",
                                          borderRadius: "5px",
                                          overflowX: "auto",
                                          width: "100%"
                                        }}
                                      >
                                        {index + 1}. {document.title}/
                                        {document.type}
                                      </li>
                                    </div>
                                  ))}
                                </>
                              )}
                            </ul>
                          )}
                        </div>

                        <div
                          className="document-contents"
                          style={{
                            flex: "1 1 80%",

                            // marginLeft: "50%",
                            maxHeight: "45vh",
                            overflowY: "auto",
                          }}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginLeft: '20px' }}><h2>Document Contents</h2></div>
                            <div className="expand-icon" onClick={() => handleExpandIcon()} style={{ marginRight: '15px' }}>{sideModal ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon />}</div>
                          </div>
                          {sectionsArray && sectionsArray.length > 0 && (
                            <>
                              {sectionsArray.map((document, index) => (
                                <>
                                  <Accordion
                                    key={document._id}
                                    id={document._id}
                                    style={{ marginBottom: "0px", }}
                                  >
                                    <AccordionSummary>
                                      <div
                                        style={{
                                          borderRadius: "10px",
                                          width: "100%",
                                          display: "flex",
                                          border: "1px solid #ccc",
                                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                                        }}
                                      >
                                        <div style={{
                                          backgroundColor: assignedTaskdetails?.find(
                                            (task) =>
                                              task.assetId === rfmManagementData.value.assetid &&
                                              task.sectionId === document._id &&
                                              task.members.some((member) => member.email === userInfo?.email)
                                          )?.members.find(
                                            (member) => member.email === userInfo?.email
                                          )?.color || '#3d83fa',
                                          width: "1%",
                                          borderTopLeftRadius: "10px",
                                          borderBottomLeftRadius: "10px",
                                        }}></div>


                                        <div
                                          className="section-card"
                                          style={{
                                            width: "99%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: document.type !== "sec" ? "center" : "none",
                                            borderTopRightRadius: "10px",
                                            borderBottomRightRadius: "10px",
                                            padding: "10px",
                                            // border: "1px solid black"
                                          }}
                                        >
                                          <div style={{ width: "60%" }}>
                                            <b>
                                              <h4
                                                style={{
                                                  marginBottom: "3px",
                                                  width: "70%",
                                                }}
                                              >
                                                {document.title}
                                              </h4>
                                              <h4
                                                style={{
                                                  marginBottom: "3px",
                                                  width: "70%",
                                                }}
                                              >
                                                Created By:{" "}{document && document.createdby === sUserId ? "You" : document?.createdbyName}
                                              </h4>




                                              {document.taskAssignWith.some(
                                                (task) =>
                                                  task.assignToEmail ===
                                                  cuurentUser ||
                                                  task.assignByEmail ===
                                                  assignbyUser
                                              ) && (
                                                  <>
                                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px", marginRight: "25px" }}>
                                                      <p style={{ color: "black" }}>
                                                        Status:{" "}
                                                        <span style={{ color: "green" }}>
                                                          {
                                                            document.taskAssignWith.find(
                                                              (task) =>
                                                                task.assignToEmail ===
                                                                cuurentUser ||
                                                                task.assignByEmail ===
                                                                assignbyUser
                                                            )?.statusofSection
                                                          }
                                                        </span>
                                                      </p>
                                                      {assignedTaskdetails?.length > 0 &&
                                                        <>
                                                          <p style={{ color: "black" }}>
                                                            Due Date:{" "}
                                                            <span style={{
                                                              color: assignedTaskdetails?.find(
                                                                (task) =>
                                                                  task.assetId === rfmManagementData.value.assetid &&
                                                                  task.sectionId === document._id &&
                                                                  task.members.some((member) => member.email === userInfo?.email)
                                                              )?.members.find(
                                                                (member) => member.email === userInfo?.email
                                                              )?.color || 'orange',
                                                            }}>
                                                              {assignedTaskdetails
                                                                .filter(
                                                                  (task) =>
                                                                    task.assetId === rfmManagementData?.value?.assetid &&
                                                                    task.sectionId === document._id
                                                                )
                                                                .map((task) =>
                                                                  task.members
                                                                    .filter((member) => member.email == userInfo?.email)
                                                                    .map((member) =>
                                                                      new Date(member.dueDate).toLocaleDateString()
                                                                    )
                                                                )
                                                                .flat()
                                                                .join(", ")}
                                                            </span>
                                                          </p>


                                                          <p style={{ color: "black" }}>
                                                            Priority:{" "}
                                                            <span style={{
                                                              color: assignedTaskdetails?.find(
                                                                (task) =>
                                                                  task.assetId === rfmManagementData.value.assetid &&
                                                                  task.sectionId === document._id &&
                                                                  task.members.some((member) => member.email === userInfo?.email)
                                                              )?.members.find(
                                                                (member) => member.email === userInfo?.email
                                                              )?.color || 'orange',
                                                            }}>
                                                              {assignedTaskdetails
                                                                .filter(
                                                                  (task) =>
                                                                    task.assetId === rfmManagementData?.value?.assetid &&
                                                                    task.sectionId === document._id
                                                                )
                                                                .map((task) =>
                                                                  task.members
                                                                    .filter((member) => member.email == userInfo?.email)
                                                                    .map((member) =>
                                                                      member.priority
                                                                    )
                                                                )
                                                                .flat()
                                                                .join(", ")}
                                                            </span>
                                                          </p>
                                                          {document.taskAssignWith.some(data => data.statusofSection === "Task Approved" || data.statusofSection === "Task Denied") && (
                                                            <IconButton onClick={() => handleFetchComments(document)}>
                                                              <RemoveRedEyeIcon />
                                                            </IconButton>
                                                          )}

                                                        </>
                                                      }
                                                    </div>
                                                    <div>
                                                      {assignedTaskdetails?.length > 0 &&
                                                        <p style={{ color: "black" }}>
                                                          Task Description:{" "}
                                                          <span style={{ color: "#1976D2" }}>
                                                            {assignedTaskdetails
                                                              .filter(
                                                                (task) =>
                                                                  task.assetId === rfmManagementData?.value?.assetid &&
                                                                  task.sectionId === document._id
                                                              )
                                                              .map((task) =>
                                                                task.members
                                                                  .filter((member) => member.email === userInfo?.email)
                                                                  .map((member) =>
                                                                    member.description
                                                                  )
                                                              )
                                                              .flat()
                                                              .join(", ")}
                                                          </span>
                                                        </p>
                                                      }
                                                    </div>
                                                  </>

                                                )}

                                              {document.taskAssignWith.some(
                                                (task) =>
                                                  task.assignByEmail ===
                                                  cuurentUser
                                              ) && (
                                                  <>
                                                    <p style={{ color: "black", marginTop: "15px", }}>
                                                      Status:{" "}
                                                      <span style={{ color: "green" }}>
                                                        {
                                                          document.taskAssignWith?.find(
                                                            (task) =>
                                                              task.assignByEmail ===
                                                              cuurentUser
                                                          )?.statusofSection
                                                        }
                                                      </span>
                                                    </p>


                                                    {document.taskAssignWith.some(data => data.statusofSection === "Task Approved" || data.statusofSection === "Task Denied") && (
                                                      <IconButton onClick={() => handleFetchComments(document)}>
                                                        <RemoveRedEyeIcon />
                                                      </IconButton>
                                                    )}

                                                  </>
                                                )}
                                            </b>
                                          </div>

                                          <div>
                                            {/* {rfmManagementData?.ProjectType?.data == "Shared Project" && sharedSectionDetails?.some(sharedData => sharedData.id == document._id && sharedData.isEdit == true) &&  

                               <button style={{ padding: "6px", color: "#1976D2" }}><EditIcon /></button> 

                               } 

                                */}

                                            {/* <button
                                              style={{
                                                padding: "6px",
                                                color: "#1976D2",
                                              }}
                                              onClick={() => {
                                                openModalSectionshr(
                                                  document._id,
                                                  document.type,
                                                  document.assetType,
                                                  document.createdby
                                                );
                                              }}
                                              data-tip
                                              data-for="sharesection"
                                            >
                                              <SendIcon />
                                            </button>
                                            <ReactTooltip id="sharesection" place="bottom" effect="solid">
                                              Share this section
                                            </ReactTooltip> */}

                                            <button
                                              style={{
                                                padding: "6px",
                                                paddingTop: "0px",
                                                color: "#1976D2",
                                              }}
                                              onClick={(e) => { handleSectionDownload(document.documentpath, document.type, document.htmlContent, document._id, document.title, document.createdby) }}
                                              data-tip
                                              data-for="downloadcontent"
                                            >
                                              <DownloadIcon />
                                            </button>
                                            <ReactTooltip id="downloadcontent" place="bottom" effect="solid">
                                              Download section content
                                            </ReactTooltip>


                                            <button
                                              style={{
                                                padding: "6px",
                                                paddingTop: "0px",
                                                color: "#1976D2",
                                              }}
                                              onClick={() => {
                                                handleOpenTaskAssignmentModal(
                                                  document._id,
                                                  document.type,
                                                  document.assetType,
                                                  document.createdby,
                                                  document.title,

                                                );
                                              }}
                                              data-tip
                                              data-for="useradding"
                                            >
                                              <PersonAddIcon />
                                            </button>
                                            <ReactTooltip id="useradding" place="bottom" effect="solid">
                                              Add user
                                            </ReactTooltip>




                                            <button
                                              style={{
                                                padding: "6px",
                                                paddingTop: "0px",
                                                color: "red",
                                              }}
                                              onClick={(e) => {
                                                handleDeleteFile(
                                                  e,
                                                  document.documentpath,
                                                  document._id,
                                                  document.type,
                                                  document.createdby
                                                );
                                                e.stopPropagation();
                                              }}
                                              data-tip
                                              data-for="sectiondelete"
                                            >
                                              <DeleteIcon />
                                            </button>
                                            <ReactTooltip id="sectiondelete" place="bottom" effect="solid">
                                              Delete section
                                            </ReactTooltip>
                                            {document.taskAssignWith.some(
                                              (task) =>
                                                task.assignToEmail ===
                                                cuurentUser &&
                                                task.isAccepted &&
                                                !task.isSubmitted
                                            ) &&
                                              document.taskAssignWith

                                                .filter(
                                                  (task) =>
                                                    task.assignToEmail ===
                                                    cuurentUser &&
                                                    task.isAccepted &&
                                                    !task.isSubmitted
                                                )

                                                .map((task, index) => (
                                                  <EditIcon
                                                    key={index}
                                                    onClick={() => {
                                                      handlegetFileName(
                                                        task.link
                                                          ? task.link
                                                          : document.documentpath,

                                                        document.type,

                                                        document.htmlContent,

                                                        document._id,

                                                        document.title,

                                                        document.assetType,

                                                        document.taskAssignWith,

                                                        // !task?.link && task?.isTaskReassign && "owner" ,
                                                        // task?.link && task?.isTaskReassign && "receiver" ,
                                                        //  task?.link  && !task?.isTaskReassign && "receiver",
                                                        //  !task?.link  && !task?.isTaskReassign && "owner",
                                                        !task?.isTaskReassign && !task?.link && task?.isReassignCount == 0 ? "owners" :
                                                        task?.isTaskReassign
                                                        ? task?.link 
                                                          ? "receiver"   // If task is reassigned and link exists
                                                          : "owner"      // If task is reassigned and no link exists
                                                        : task?.link 
                                                          ? "receiver"   // If task is not reassigned and link exists
                                                          : "owners"    // If task is not reassigned and no link exists
                                                      ,

                                                      document.createdby
                                                      )
                                                    }}
                                                  />
                                                ))}

                                            {document.taskAssignWith.some(
                                              (task) =>
                                                task.assignToEmail ===
                                                cuurentUser &&
                                                !task.isAccepted
                                                // given statusofSection ! =Task Rejected so once rejected this button should not be shown 
                                                && task.statusofSection !== "Task Rejected"
                                            ) && (
                                                <button
                                                  onClick={() =>
                                                    setShowPopup(
                                                      document.assetType,
                                                      document._id,

                                                      rfmManagementData.value
                                                        .assetid,
                                                      document.taskassignid,
                                                      document?.taskAssignWith,
                                                    )
                                                  }
                                                >

                                                  <i
                                                    className="fa fa-asterisk blurb-ripple-out point"
                                                    style={{ color: "orange" }}
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              )}

                                            {document.taskAssignWith.some(
                                              (task) =>
                                                task.assignToEmail ===
                                                cuurentUser &&
                                                task.isSubmitted &&
                                                task.statusofSection ==
                                                "Task performed"
                                            ) && (
                                                <button
                                                // onClick={() =>
                                                //   setShowPopup(
                                                //     document.assetType,
                                                //     document._id,
                                                //     rfmManagementData.value
                                                //       .assetid,
                                                //     document.taskassignid,
                                                //     document?.taskAssignWith,
                                                //   )
                                                // }
                                                >
                                                  Task Submitted
                                                </button>
                                              )}

                                            {document.taskAssignWith.some(
                                              (task) =>
                                                task.assignByEmail ===
                                                cuurentUser &&
                                                task.isSubmitted &&
                                                task?.statusofSection ==
                                                "Task performed"
                                            ) && (
                                                <button
                                                  onClick={() =>
                                                    setShowPopupfoApproval(
                                                      document.assetType,
                                                      document._id,
                                                      rfmManagementData.value
                                                        .assetid,
                                                      document.taskassignid,
                                                      document?.taskAssignWith
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-asterisk blurb-ripple-out point"
                                                    style={{ color: "orange" }}
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              )}

                                            {open === document._id && (
                                              <Dialog
                                                open={open === document._id}
                                                onClose={handleAcceptClose}
                                                PaperProps={{
                                                  className: "dialogPaper",
                                                }}
                                              >
                                                <div className="dialogHeader">
                                                  <DialogTitle className="dialogTitle">
                                                    Requesting Task for `
                                                    {document.title}`
                                                  </DialogTitle>

                                                  <div className="dialogClose">
                                                    <CloseIcon
                                                      onClick={
                                                        handleAcceptClose
                                                      }
                                                    />
                                                  </div>
                                                </div>

                                                <DialogContent className="dialogContent">
                                                  {document.taskAssignWith.filter(task => task.assignToEmail == cuurentUser && task.statusofSection=="Pending Acceptance")
                                                    .map((task, index) => (
                                                      <Button
                                                        key={index}
                                                        className="dialogButton"
                                                        onClick={() =>
                                                          handleAcceptClick(
                                                            document.assetType,
                                                            document._id,
                                                            rfmManagementData
                                                              .value.assetid,
                                                            task.taskassignid,
                                                            document.title
                                                          )
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                      >
                                                        Accept
                                                      </Button>
                                                    )
                                                    )}

                                                  {document.taskAssignWith.filter(task => task.assignToEmail == cuurentUser && task.statusofSection=="Pending Acceptance")
                                                    .map((task, index) => (
                                                      <>
                                                      <Button
                                                        className="dialogButton"
                                                        onClick={() => {
                                                          handleOpenRejectDialog()
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                      >
                                                        Reject
                                                      </Button>



                                                      <Dialog open={openRejectDialog} onClose={handleCloseRejectDialog}>
                                                    <DialogTitle>Reject Task Confirmation</DialogTitle>
                                                    <DialogContent>
                                                      <DialogContentText>
                                                        Are you sure you want to reject this task?
                                                      </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                      <Button onClick={()=>{
                                                        handleRejectClick(
                                                          document.assetType,
                                                          document._id,
                                                          task.taskassignid,
                                                          document
                                                        )
                                                      }} color="secondary" autoFocus>
                                                        Yes
                                                      </Button>
                                                      <Button onClick={handleCloseRejectDialog} color="primary">
                                                        No
                                                      </Button>
                                                    </DialogActions>
                                                  </Dialog>
                                                      </>
                                                    ))}
                                                </DialogContent>
                                              </Dialog>
                                            )}

                                            <Modal
                                              open={openPopup === document._id}
                                              onClose={handleAcceptClose}
                                              aria-labelledby="modal-modal-title"
                                              aria-describedby="modal-modal-description"
                                            >
                                              <Box sx={style}>
                                                {!opennewPopup && (
                                                  <>
                                                    <div
                                                      className="dialogHeader"
                                                      style={{
                                                        width: "100%",
                                                        margin: "auto",
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <Typography
                                                        style={{
                                                          textAlign: "center",
                                                          marginLeft: "33%",
                                                        }}
                                                        variant="h6"
                                                        className="dialogTitle"
                                                      >
                                                        Requesting Approval for
                                                        {document.title}
                                                      </Typography>

                                                      <div
                                                        className="dialogClose"
                                                        style={{
                                                          marginLeft: "10%",
                                                        }}
                                                      >
                                                        <IconButton
                                                          onClick={
                                                            handleAcceptClose
                                                          }
                                                        >
                                                          <CloseIcon />
                                                        </IconButton>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="dialogContent"
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "10%",
                                                      }}
                                                    >
                                                      {document.taskAssignWith.map(
                                                        (task, index) => (
                                                          <>
                                                            {task?.statusofSection ==
                                                              "Task performed" &&
                                                              task?.isApproved ==
                                                              false && (
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    marginBottom:
                                                                      "20px",
                                                                    width:
                                                                      "50%",
                                                                  }}
                                                                  key={index}
                                                                >
                                                                  <Typography
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    {task.assignToEmail
                                                                      ? task.assignToEmail
                                                                      : task.assignToEmail -
                                                                      "yet to submit"}
                                                                  </Typography>

                                                                  <Button
                                                                    className="dialogButton"
                                                                    onClick={() =>
                                                                      handlegetFileNames(
                                                                        document.documentpath,
                                                                        document.type,
                                                                        document.htmlContent,
                                                                        document._id,
                                                                        document.title,
                                                                        document?.assetType,
                                                                        task?.taskassignid,
                                                                        task?.linkSubmitted,
                                                                        task?.htmlcontent,
                                                                        task?.assignToEmail,
                                                                        task?.link
                                                                      )
                                                                    }
                                                                    variant="contained"
                                                                    color="primary"
                                                                    style={{
                                                                      marginLeft:
                                                                        "28%",
                                                                      height:
                                                                        "25px",
                                                                      width:
                                                                        "58px",
                                                                    }}
                                                                  >
                                                                    Review
                                                                  </Button>
                                                                </div>
                                                              )}
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                  </>
                                                )}


                                              </Box>
                                            </Modal>

                                            <Modal
                                              open={openJoditPopup === document._id}>
                                              <Box className='review-approval-box'>
                                                {openJoditPopup && (
                                                  <>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          width: "100%",
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <h3
                                                          style={{
                                                            color:
                                                              "rgb(61, 131, 250)",
                                                          }}
                                                        >
                                                          Review and Approval
                                                        </h3>

                                                        <HighlightOffIcon
                                                          style={{
                                                            color: "red",
                                                          }}
                                                          onClick={() =>
                                                            setOpenJoditPopup(
                                                              false
                                                            )
                                                          }
                                                        />
                                                      </div>

                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: mobileView ? "column" : "row",
                                                          justifyContent:
                                                            "center",
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            height: mobileView ? "420px" : "550px", // Adjust this as needed for your layout
                                                            width: "100%",
                                                            marginRight: "15px",
                                                          }}
                                                        >
                                                          <h4
                                                            style={{
                                                              color:
                                                                "rgb(61, 131, 250)",
                                                            }}
                                                          >
                                                            Original Section
                                                          </h4>

                                                          <JoditEditor

                                                            className="review-editor1"
                                                            value={
                                                              taskApprovalOwnerData
                                                            }
                                                            onChange={() => {
                                                              getHtmlOwnercontent();
                                                            }}
                                                            ref={editor1}
                                                            config={config2}
                                                            style={{
                                                              overflow: "auto",
                                                              height: "100%", // Adjust for header height
                                                              width: "100%",
                                                            }}
                                                          />


                                                          <div style={{ marginTop: '10px' }}>
                                                            <button
                                                              className="review-buttons"
                                                              onClick={() =>
                                                                setOpenJoditPopup(
                                                                  false
                                                                )
                                                              }
                                                              style={{
                                                                height: "40px",
                                                                color: "white",
                                                                backgroundColor:
                                                                  "rgb(61, 131, 250)",
                                                              }}
                                                            >
                                                              Back
                                                            </button>
                                                            <button
                                                              className="review-buttons review-buttons-mar"
                                                              onClick={() => {
                                                                handleFinalMerge(
                                                                  document?.taskAssignWith
                                                                )
                                                              }}
                                                              style={{
                                                                height: "40px",
                                                                color: "white",
                                                                backgroundColor:
                                                                  "rgb(61, 131, 250)",

                                                              }}
                                                            >
                                                              Save
                                                            </button>
                                                          </div>

                                                        </div>

                                                        <div
                                                          style={{
                                                            height: "100%", // Adjust this as needed for your layout
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <div

                                                            className="reviewer-section"

                                                          >
                                                            <h4
                                                              style={{
                                                                color:
                                                                  "rgb(61, 131, 250)",
                                                              }}
                                                            >Reviewer Section
                                                            </h4>
                                                            {tasks.length > 0 && (
                                                              <>

                                                                <div
                                                                  style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    width: "55%",
                                                                  }}
                                                                >
                                                                  <Button onClick={handlePrevious} disabled={currentIndex === 0}>
                                                                    <ArrowBackIcon />
                                                                  </Button>
                                                                  <div
                                                                    style={{
                                                                      display: "flex",
                                                                      flexDirection: "column",
                                                                      alignItems: "center",
                                                                      // marginBottom: "20px",
                                                                      width: "100",
                                                                    }}
                                                                  >
                                                                    <Typography
                                                                      className="reviewer-email"
                                                                      style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        textAlign: "center",
                                                                        marginTop: "5px",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px"

                                                                      }}
                                                                      onClick={() =>
                                                                        handlegetFileNames(
                                                                          document?.documentpath,
                                                                          document?.type,
                                                                          document?.htmlContent,
                                                                          document?._id,
                                                                          document?.title,
                                                                          document?.assetType,
                                                                          tasks[currentIndex]?.taskassignid,
                                                                          tasks[currentIndex]?.linkSubmitted,
                                                                          tasks[currentIndex]?.htmlcontent,
                                                                          tasks[currentIndex]?.assignToEmail,
                                                                          tasks[currentIndex]?.link
                                                                        )
                                                                      }
                                                                    >
                                                                      {tasks[currentIndex]?.assignToEmail || "yet to submit"}
                                                                    </Typography>
                                                                  </div>
                                                                  <Button

                                                                    onClick={handleNext}
                                                                    disabled={currentIndex === tasks.length - 1}
                                                                  >
                                                                    <ArrowForwardIcon />
                                                                  </Button>
                                                                </div>
                                                              </>
                                                            )}
                                                          </div>

                                                          <JoditEditor
                                                            className="review-editor2"
                                                            value={
                                                              taskApprovalReceiverData
                                                            }
                                                            config={config3}
                                                            style={{
                                                              height: "100%", // Adjust for header height and divide by 3
                                                              width: "100%",
                                                            }}
                                                          />

                                                          <div
                                                            style={{
                                                              padding: "10px 0px",
                                                              display: "flex",
                                                              justifyContent: 'center'

                                                            }}
                                                          >
                                                            <button
                                                              className="review-buttons"
                                                              onClick={() =>
                                                                handleApprove(
                                                                  document?.assetType,
                                                                  document?._id,
                                                                  rfmManagementData
                                                                    .value
                                                                    .assetid,
                                                                  document?.taskassignwith,
                                                                  specifiSectiontaskData?.taskassignwith,
                                                                  document?.title
                                                                )
                                                              }
                                                              style={{
                                                                height: "40px",
                                                                color: "white",
                                                                backgroundColor:
                                                                  "rgb(61, 131, 250)",
                                                              }}
                                                            >
                                                              Approved
                                                            </button>

                                                            <button
                                                              className="review-buttons review-buttons-mar"
                                                              onClick={() =>
                                                                handleAcceptBoth(
                                                                  document.assetType,
                                                                  document._id,
                                                                  rfmManagementData
                                                                    .value
                                                                    .assetid,
                                                                  document.taskassignwith,
                                                                  specifiSectiontaskData.taskassignwith,
                                                                  document.title
                                                                )
                                                              }
                                                              style={{
                                                                height: "40px",
                                                                color: "white",
                                                                backgroundColor:
                                                                  "rgb(61, 131, 250)",

                                                              }}
                                                            >
                                                              Accept Both
                                                            </button>

                                                            <button
                                                              className="review-buttons review-buttons-mar"
                                                              onClick={() =>
                                                                handleRejectTask(
                                                                  document.assetType,
                                                                  document._id,
                                                                  rfmManagementData
                                                                    .value
                                                                    .assetid,
                                                                  document.taskassignwith,
                                                                  specifiSectiontaskData.taskassignwith,
                                                                  document.htmlContent,
                                                                  document.title
                                                                )
                                                              }
                                                              style={{
                                                                height: "40px",
                                                                color: "white",
                                                                backgroundColor:
                                                                  "rgb(61, 131, 250)",

                                                              }}
                                                            >
                                                              Reject
                                                            </button>

                                                            {/* <button
                                                              onClick={() =>
                                                                handleSaveTask(
                                                                  document.assetType,
                                                                  document._id,
                                                                  rfmManagementData
                                                                    .value
                                                                    .assetid,
                                                                  document.taskassignwith,
                                                                  specifiSectiontaskData.taskassignwith,
                                                                  document.htmlContent,
                                                                  document.title
                                                                )
                                                              }
                                                              style={{
                                                                height: "40px",
                                                      color: "white",
                                                      backgroundColor:
                                                        "rgb(61, 131, 250)",
                                                        marginLeft: "20px",
                                                              }}
                                                            >
                                                              Edit Manually
                                                            </button> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        margin: "15px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                      }}
                                                    >

                                                    </div>
                                                    {/* </div> */}

                                                  </>
                                                  // </div>
                                                )}
                                              </Box>
                                            </Modal>

                                            <button
                                              style={{
                                                padding: "6px",
                                                color: "#1976D2",
                                              }}
                                              onClick={(e) => {


                                                console.log(document, "kokokoo")

                                                const currentTask = document.taskAssignWith.find(
                                                  (t) => t.assignToEmail === userEmail && (t.statusofSection === "Task Performed" || t.statusofSection === "Review Inprogress")
                                                );

                                                console.log(currentTask, "kkokoko", userEmail)

                                                if (currentTask) {
                                                  // If a matching task is found, call with task-specific arguments
                                                  handlegetFileName(
                                                    currentTask.link ? currentTask.link : document.documentpath,
                                                    document.type,
                                                    document.htmlContent,
                                                    document._id,
                                                    document.title,
                                                    document.assetType,
                                                    document.taskAssignWith,
                                                    currentTask.link ? "receiver" : "owners",
                                                    document.createdby
                                                  );
                                                } else {
                                                  // Otherwise, call with document-specific arguments
                                                  handlegetFileName(
                                                    document.documentpath,
                                                    document.type,
                                                    document.htmlContent,
                                                    document._id,
                                                    document.title,
                                                    document.assetType,
                                                    document.taskAssignWith,
                                                    "owner",
                                                    document.createdby,
                                                  );
                                                }
                                              }}
                                              data-tip
                                              data-for="expandmore"
                                            >
                                              {/* {rfmManagementData?.ProjectType
                                                ?.data === "Oppoupload" && (
                                                <ExpandMoreIcon />
                                              )} */}
                                              {/* {(projectType === "myProject" || sUserId == document.createdby) && (
                                              )} */}
                                              <ExpandMoreIcon />
                                            </button>
                                            <ReactTooltip id="expandmore" place="bottom" effect="solid">
                                              click to view content of section
                                            </ReactTooltip>



                                          </div>
                                        </div>
                                      </div>
                                    </AccordionSummary>

                                    <AccordionDetails style={{ display: "flex", justifyContent: "space-between" }}>
                                      <div
                                        style={{
                                          borderRadius: "10px",
                                          width: "100%",
                                          // marginTop: "15px",
                                          // boxShadow:
                                          //   "rgba(0, 0, 0, 0.1) 0px 2px 4px",
                                          // padding: "10px",
                                          display: "flex",
                                          border: "1px solid #ccc",
                                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                                        }}>
                                        <div style={{
                                          // backgroundColor: "orange",
                                          backgroundColor: assignedTaskdetails?.find(
                                            (task) =>
                                              task.assetId === rfmManagementData.value.assetid &&
                                              task.sectionId === document._id &&
                                              task.members.some((member) => member.email === userInfo?.email)
                                          )?.members.find(
                                            (member) => member.email === userInfo?.email
                                          )?.color || '#3d83fa',
                                          width: "1%",
                                          // border: "1px solid red",
                                          borderTopLeftRadius: "10px",
                                          borderBottomLeftRadius: "10px",
                                        }}></div>
                                        <div
                                          style={{
                                            width: "99%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: document.type !== "sec" ? "center" : "none",
                                            borderTopRightRadius: "10px",
                                            borderBottomRightRadius: "10px",
                                            padding: "10px",
                                            // border: "1px solid black"
                                          }}
                                        >
                                          <Typography>
                                            <b>Description:</b>{" "}
                                            {document.description && document.description.trim() !== "" ? document.description : "No description available."}
                                          </Typography>
                                        </div>
                                      </div>


                                    </AccordionDetails>
                                  </Accordion>
                                </>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  </>
                )}



                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "20px",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "fitcontent",
                      alignItems: "center",
                      color: "#1976D2",
                      borderBottom: "1px solid #1976D2",
                    }}
                  >
                    <button
                      onClick={handleAddTemplate}
                      style={{
                        padding: "0px",
                        color: "#1976D2",
                        border: "none",
                        outline: "none",
                      }}
                    >
                      <NoteAltIcon />
                      Response
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {isgetDataEditor && (
            // <div className="col-md-9">
            <div className={`transition-width ${isExpanded ? 'col-md-12' : 'col-md-9'}`}>
              {/* Jodit Editor Row */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",

                  padding: "5px",
                  height: "85vh",
                  // border: "2px solid black"
                }}
                className="main-editor"
              >
                <div style={{ position: "relative", marginBottom: "15px" }}>
                  <TextField
                    style={{ borderRadius: "20px", width: "100%" }}
                    onChange={(e) => {
                      handlSectiontitle(e.target.value);
                    }}
                    value={sectionTitle}
                    variant="outlined"
                    disabled={!editSectionTitle}
                    fullWidth
                  />
                  <div
                    onClick={() => {
                      seteditSectionTitle(true);
                    }}
                    style={{
                      position: "absolute",
                      top: "10%",
                      right: "1%",
                      display: "flex", alignItems: "center"
                    }}
                  >
                    <button style={{ color: "#1976d2" }}>
                      <BorderColorIcon />
                    </button>
                    <div onClick={toggleExpand} style={{ cursor: "pointer" }}>
                      {isExpanded ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
                    </div>
                  </div>
                </div>

                <JoditEditor
                  config={config}
                  ref={editor}
                  value={content}
                  onChange={() => {
                    getHtmlcontent();
                  }}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  style={{ width: "100%", overflow: "auto" }}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                    flexDirection: windowWidth < 450 ? "column" : "row"
                  }}
                >
                  <div style={{ width: windowWidth < 450 ? "100%" : "40%", marginBottom: windowWidth < 450 ? "20px" : "" }}>
                    <b style={{ display: "inline-block" }}>10000 Words limit | Total words: {wordCount}</b>
                  </div>

                  <div style={{ width: windowWidth < 450 ? "100%" : "40%", display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: windowWidth < 450 ? "space-around" : "end" }}>
                    <button
                      onClick={() => {
                        setIsgetDataEditor(false);
                        setIsExpanded(false);
                      }}
                      style={{
                        borderRadius: "10px",
                        fontSize: "14px",
                        width: windowWidth < 450 ? "30%" : "90px",
                        border: "1px solid grey",
                        boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                        marginRight: "5px",
                        padding: windowWidth < 450 ? "" : "2px, 13px, 2px, 13px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center"
                      }}
                      className="editor-button"
                    >
                      Back
                    </button>

                    {isAssignedToCurrentUser && (
                      <button
                        onClick={() => {
                          // Handle the button click, you may need to modify this to handle the specific document if needed
                          const document = asssetsectionData.find((document) =>
                            document?.taskAssignWith?.some(
                              (task) => task?.assignToEmail === cuurentUser
                            )
                          );
                          if (document) {
                            handleSubmitteTask(document?._id, document?.title);
                          }
                        }}
                        style={{
                          borderRadius: "10px",
                          fontSize: "14px",
                          width: windowWidth < 450 ? "30%" : "90px",
                          border: "1px solid grey",
                          boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                          marginRight: "5px",
                          padding: windowWidth < 450 ? "" : "2px, 13px, 2px, 13px",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          textAlign: "center",
                          alignItems: "center"
                        }}
                        className="editor-button"
                        disabled={isreadonly}
                      >
                        Submit
                      </button>
                    )}

                    {
                      // First condition: If ProjectType is "Oppoupload"
                      (userInfo?.organizationId == orgId && asssetsectionData.find((document) =>
                        document?.taskAssignWith?.some((task) => task?.assignToEmail === cuurentUser) && document.createdby !== sUserId
                      ))
                        // ||
                        // asssetsectionData.find((document) =>
                        //   document?.taskAssignWith?.some((task) => task?.assignByEmail === cuurentUser)
                        // )
                        ? (
                          <button
                            onClick={() => {
                              handleAssetsectionupload(true);
                            }}
                            style={{
                              borderRadius: "10px",
                              fontSize: "14px",
                              backgroundColor: "#1976D2",
                              color: "white",
                              width: windowWidth < 450 ? "30%" : "90px",
                              boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                              padding: windowWidth < 450 ? "" : "5px, 13px, 5px, 13px",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              textAlign: "center",
                              alignItems: "center"
                            }}
                            className="editor-button"
                          >
                            Save
                          </button>
                        ) : (
                          // Second condition: If task is assigned to the current user


                          <button
                            onClick={() => {
                              handleAssetsectionupload();
                            }}
                            style={{
                              borderRadius: "10px",
                              fontSize: "14px",
                              backgroundColor: "#1976D2",
                              color: "white",
                              width: windowWidth < 450 ? "40%" : "90px",
                              boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                              padding: windowWidth < 450 ? "" : "5px, 13px, 5px, 13px",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              textAlign: "center",
                              alignItems: "center"
                            }}
                            className="editor-button"
                          >
                            Save
                          </button>

                        )
                    }
                  </div>
                </div>
              </div>




            </div>
          )}

          {/* <div className="col-md-3"> */}
          <div className={`transition-width ${isExpanded ? 'd-none' : 'col-md-3'} side-panel`}>
            <div
              style={{
                height: "90vh",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                boxShadow: "-5px 0px 5px rgba(0, 0, 0, 0.3)",
                padding: "5px",
                paddingLeft: "10px"
              }}
            >
              <div style={{ border: "1px solid #1976D2", borderRadius: "5px" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      // <ExpandMoreIcon />
                      <span data-tip data-for="expandIconactivities">
                        <ExpandMoreIcon />
                      </span>
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: windowWidth <= 850 ? "13px" : "18px",
                        color: "#1976D2",
                      }}
                      data-tip
                      data-for="Activities"

                    >
                      Activities
                    </Typography>
                    <ReactTooltip id="Activities" place="bottom" effect="solid">
                      Check all activities
                    </ReactTooltip>
                  </AccordionSummary>
                  <ReactTooltip id="expandIconactivities" place="bottom" effect="solid">
                    view all activities
                  </ReactTooltip>
                  <AccordionDetails>
                    <Typography>
                    <div style={{overflowY:'auto', height:'65vh'}}>
                      {activityData &&
                        activityData
                          .sort((a, b) => b.seq - a.seq)
                          .slice(0, displayCount)
                          .map((data, i) => {
                            return (
                              <div key={i} style={{ marginBottom: "8px" }}>
                                {/* <span style={{ fontWeight: "600" }}>{data.createdBy}: </span> */}
                                <ReusableCard
                                  text={data.msg}
                                  Id={data._id}
                                  createdby={data.createdBy}
                                  date={data.createdOn}
                                ></ReusableCard>
                              </div>
                            );
                          })}

                          </div>

                      <div className="flexbetween">
                        <div>
                          <h4 className="count_tag_">({activityCount})</h4>
                        </div>
                        <div className="flex">
                          {activityData && displayCount > 5 && (
                            <div
                              style={{
                                cursor: "pointer",
                                transition: "0.9s ease-in-out",
                              }}
                              onClick={handleShowLess}
                            >
                              <ExpandLessIcon />
                            </div>
                          )}
                          {activityData &&
                            displayCount < activityData.length &&
                            displayCount !== activityData.length && (
                              <div
                                style={{
                                  cursor: "pointer",
                                  transition: "0.7s ease-in-out",
                                }}
                                onClick={handleShowMore}
                              >
                                <ExpandMoreIcon />
                              </div>
                            )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div
                style={{
                  width: "96%",
                  margin: "auto",
                  height: "2px",
                  backgroundColor: "grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  border: "1px solid #1976D2",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              >
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      // <ExpandMoreIcon />
                      <span data-tip data-for="expandIconTool">
                        <ExpandMoreIcon />
                      </span>
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div className="note_count_flex">
                        <Typography
                          style={{
                            fontWeight: "600",
                            fontSize: windowWidth <= 850 ? "13px" : "18px",
                            color: "#1976D2",
                          }}
                          data-tip
                          data-for="Notes"
                        >
                          Notes
                        </Typography>
                        <ReactTooltip id="Notes" place="bottom" effect="solid">
                          Notes for asset
                        </ReactTooltip>
                        <div>
                          <h4 className="count_tag_">({noteCount})</h4>
                        </div>
                      </div>

                      <button
                        className="open_button_"
                        onClick={(e) => {
                          handleOpenModal(e);
                        }}
                        data-tip
                        data-for="addnotetolist"
                      >
                        <AddCircleOutlineIcon />
                      </button>
                      <ReactTooltip id="addnotetolist" place="bottom" effect="solid">
                        Add notes
                      </ReactTooltip>
                    </div>
                  </AccordionSummary>
                  <ReactTooltip id="expandIconTool" place="bottom" effect="solid">
                    view detail notes
                  </ReactTooltip>
                  <AccordionDetails>
                    <Typography>
                      {notesData && (
                        <div>
                          {notesData
                            .sort((a, b) => b.seq - a.seq)
                            .map((note, index) => (
                              <ReusableCard
                                viewType={"noteCard"}
                                text={note.text}
                                Id={note._id}
                                deleteNote={handleDeleteNotes}
                                updateNote={handleUpdateNotes}
                                createdby={note.createdby}
                                onClickCard={openCard}
                                date={note.createdon}
                                replyToComment={handleNotesReply}
                                replyView={showReply ? "showReply" : ""}
                                replyArray={note.commentReply}
                                deleteNotesReply={handleDeleteNotesReply}
                                updateCommentReply={handleNotesReply}
                              >
                                {" "}
                              </ReusableCard>
                            ))}
                        </div>
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div
                style={{
                  width: "96%",
                  margin: "auto",
                  height: "2px",
                  backgroundColor: "grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div style={{ border: "1px solid #1976D2", borderRadius: "5px" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      // <ExpandMoreIcon />
                      <span data-tip data-for="expandIconTooltip">
                        <ExpandMoreIcon />
                      </span>
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: windowWidth <= 850 ? "13px" : "18px",
                        color: "#1976D2",
                      }}
                      data-tip
                      data-for="VersionHistory"
                    >
                      Version History
                    </Typography>
                    <ReactTooltip id="VersionHistory" place="bottom" effect="solid">
                      Sections version history
                    </ReactTooltip>
                  </AccordionSummary>
                  <ReactTooltip id="expandIconTooltip" place="bottom" effect="solid">
                    view detail history
                  </ReactTooltip>
                  <AccordionDetails>
                    {/* <h5 onClick={handleVersionModal}> Clck</h5> */}
                    {versionData?.map((data) => {
                      return (
                        <VersionHistoryCard
                          title={data.title}
                          date={data.date}
                          version={data.version}
                          createdBy={data.createdBy}
                          AssetSectionId={data.AssetSectionId}
                          //  handleSpecifcVersionData={specificVersion}
                          versionModal={handleVersionModal}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>

              <div
                style={{
                  width: "96%",
                  margin: "auto",
                  height: "2px",
                  backgroundColor: "grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div style={{ border: "1px solid #1976D2", borderRadius: "5px" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      // <ExpandMoreIcon />
                      <span data-tip data-for="expandIconcomment">
                        <ExpandMoreIcon />
                      </span>
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <div className="comment_Flex_Button">
                      <div className="note_count_flex">
                        <Typography
                          style={{
                            fontWeight: "600",
                            fontSize: windowWidth <= 850 ? "13px" : "18px",
                            color: "#1976D2",
                          }}
                          data-tip
                          data-for="Comments"
                        >
                          Comments
                        </Typography>
                        <ReactTooltip id="Comments" place="bottom" effect="solid">
                          Check all comments
                        </ReactTooltip>
                        <div>
                          <h4 className="count_tag_">({commentCount})</h4>
                        </div>
                      </div>

                      <button
                        className="open_button_"
                        onClick={(e) => {
                          handleOpenCommentModal(e);
                        }}
                        data-tip
                        data-for="addcommenttolist"
                      >
                        <AddCircleOutlineIcon />
                      </button>
                      <ReactTooltip id="addcommenttolist" place="bottom" effect="solid">
                        Add comments
                      </ReactTooltip>
                    </div>
                  </AccordionSummary>
                  <ReactTooltip id="expandIconcomment" place="bottom" effect="solid">
                    view all comments
                  </ReactTooltip>
                  <AccordionDetails>
                    <Typography>
                      {commentData && (
                        <div style={{ marginTop: "20px" }}>
                          {commentData
                            .sort((a, b) => b.seq - a.seq)
                            .map((data, index) => (
                              <ReusableCard
                                viewType={"commentCard"}
                                text={data.text}
                                Id={data._id}
                                deleteComment={handleDeleteComments}
                                updateComment={handleUpdateComments}
                                createdby={data.createdby}
                                onClickCard={openCard}
                                date={data.createdon}
                                replyToComment={handleReply}
                                replyView={showReply ? "showReply" : ""}
                                replyArray={data.commentReply}
                                deleteCommentReply={handleDeleteCommentReply}
                                updateCommentReply={handleReply}
                              ></ReusableCard>
                            ))}
                        </div>
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              {isgetDataEditor && (
                <>
                  <div
                    style={{
                      width: "96%",
                      margin: "auto",
                      height: "2px",
                      backgroundColor: "grey",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  ></div>

                  <div
                    style={{ border: "1px solid #1976D2", borderRadius: "5px" }}
                  >
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography
                          style={{
                            fontWeight: "600",
                            fontSize: windowWidth <= 850 ? "13px" : "18px",
                            color: "#1976D2",
                          }}
                        >
                          Track Changes
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {trackedChanges && trackedChanges.length > 0 && (
                            <div>
                              {trackedChanges
                                .sort((a, b) => b.seq - a.seq)
                                .slice(0, displayCount)
                                .map((data, index) => (
                                  <div
                                    key={index}
                                    style={{ marginBottom: "8px" }}
                                  >
                                    <ReusableCard
                                      viewType="trackedChangesCard"
                                      Id={data._id}
                                      createdby={data.createdBy}
                                      date={data.createdOn}
                                      addedContent={data.addedContent}
                                      removedContent={data.removedContent}
                                      s3Version={data.s3VersionId}
                                      onClickCard={handleRevertChanges}
                                      documentBody={data.documentBody}
                                      mouseEnter={handleMouseEnter}
                                      mouseLeave={handleMouseLeave}
                                      trackRecord={data}
                                      userId = {userInfo._id}
                                      handleRevertNotification = {handleRevertNotification}
                                    />
                                  </div>
                                ))}
                              <div className="flexbetween">
                                <div>
                                  <h4 className="count_tag_">
                                    ({trackedChanges.length})
                                  </h4>
                                </div>
                                <div className="flex">
                                  {displayCount > 5 && (
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        transition: "0.9s ease-in-out",
                                      }}
                                      onClick={handleShowLess}
                                    >
                                      <ExpandLessIcon />
                                    </div>
                                  )}
                                  {displayCount < trackedChanges.length &&
                                    displayCount !== trackedChanges.length && (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          transition: "0.7s ease-in-out",
                                        }}
                                        onClick={handleShowMore}
                                      >
                                        <ExpandMoreIcon />
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {sideModal &&
          <div className='side-modal' style={{ backgroundColor: 'white', height: '60vh', width: '70%', borderRadius: '15px', border: '1px solid blue', position: 'absolute', right: '5px', top: isExpanded ? '10%' : '55%' }}>
            <KeyboardDoubleArrowRightIcon onClick={() => {
              setSideModal(false);
              setIsExpanded2(false)
              setIsExpanded(false)
            }}
              style={{
                margin: '10px'
              }}
            />
            <div className={`transition-width col-md-3`}>
              <div
                style={{
                  height: "45vh",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                  // boxShadow: "-5px 0px 5px rgba(0, 0, 0, 0.3)",
                  padding: "5px",
                  paddingLeft: "10px",
                  overflowY: 'auto'
                }}
              >
                <div style={{ border: "1px solid #1976D2", borderRadius: "5px" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={
                        // <ExpandMoreIcon />
                        <span data-tip data-for="expandIconactivities">
                          <ExpandMoreIcon />
                        </span>
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "#1976D2",
                        }}
                        data-tip
                        data-for="Activities"

                      >
                        Activities
                      </Typography>
                      <ReactTooltip id="Activities" place="bottom" effect="solid">
                        Check all activities
                      </ReactTooltip>
                    </AccordionSummary>
                    <ReactTooltip id="expandIconactivities" place="bottom" effect="solid">
                      view all activities
                    </ReactTooltip>
                    <AccordionDetails>
                      <Typography>
                        {activityData &&
                          activityData
                            .sort((a, b) => b.seq - a.seq)
                            .slice(0, displayCount)
                            .map((data, i) => {
                              return (
                                <div key={i} style={{ marginBottom: "8px" }}>
                                  {/* <span style={{ fontWeight: "600" }}>{data.createdBy}: </span> */}
                                  <ReusableCard
                                    text={data.msg}
                                    Id={data._id}
                                    createdby={data.createdBy}
                                    date={data.createdOn}
                                  ></ReusableCard>
                                </div>
                              );
                            })}

                        <div className="flexbetween">
                          <div>
                            <h4 className="count_tag_">({activityCount})</h4>
                          </div>
                          <div className="flex">
                            {activityData && displayCount > 5 && (
                              <div
                                style={{
                                  cursor: "pointer",
                                  transition: "0.9s ease-in-out",
                                }}
                                onClick={handleShowLess}
                              >
                                <ExpandLessIcon />
                              </div>
                            )}
                            {activityData &&
                              displayCount < activityData.length &&
                              displayCount !== activityData.length && (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    transition: "0.7s ease-in-out",
                                  }}
                                  onClick={handleShowMore}
                                >
                                  <ExpandMoreIcon />
                                </div>
                              )}
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div
                  style={{
                    width: "96%",
                    margin: "auto",
                    height: "2px",
                    backgroundColor: "grey",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                ></div>

                <div
                  style={{
                    border: "1px solid #1976D2",
                    borderRadius: "5px",
                    marginTop: "10px",
                  }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={
                        // <ExpandMoreIcon />
                        <span data-tip data-for="expandIconTool">
                          <ExpandMoreIcon />
                        </span>
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div className="note_count_flex">
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "#1976D2",
                            }}
                            data-tip
                            data-for="Notes"
                          >
                            Notes
                          </Typography>
                          <ReactTooltip id="Notes" place="bottom" effect="solid">
                            Notes for asset
                          </ReactTooltip>
                          <div>
                            <h4 className="count_tag_">({noteCount})</h4>
                          </div>
                        </div>

                        <button
                          className="open_button_"
                          onClick={(e) => {
                            handleOpenModal(e);
                          }}
                          data-tip
                          data-for="addnotetolist"
                        >
                          <AddCircleOutlineIcon />
                        </button>
                        <ReactTooltip id="addnotetolist" place="bottom" effect="solid">
                          Add notes
                        </ReactTooltip>
                      </div>
                    </AccordionSummary>
                    <ReactTooltip id="expandIconTool" place="bottom" effect="solid">
                      view detail notes
                    </ReactTooltip>
                    <AccordionDetails>
                      <Typography>
                        {notesData && (
                          <div>
                            {notesData
                              .sort((a, b) => b.seq - a.seq)
                              .map((note, index) => (
                                <ReusableCard
                                  viewType={"noteCard"}
                                  text={note.text}
                                  Id={note._id}
                                  deleteNote={handleDeleteNotes}
                                  updateNote={handleUpdateNotes}
                                  createdby={note.createdby}
                                  onClickCard={openCard}
                                  date={note.createdon}
                                  replyToComment={handleNotesReply}
                                  replyView={showReply ? "showReply" : ""}
                                  replyArray={note.commentReply}
                                  deleteNotesReply={handleDeleteNotesReply}
                                  updateCommentReply={handleNotesReply}
                                >
                                  {" "}
                                </ReusableCard>
                              ))}
                          </div>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div
                  style={{
                    width: "96%",
                    margin: "auto",
                    height: "2px",
                    backgroundColor: "grey",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                ></div>

                <div style={{ border: "1px solid #1976D2", borderRadius: "5px" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={
                        // <ExpandMoreIcon />
                        <span data-tip data-for="expandIconTooltip">
                          <ExpandMoreIcon />
                        </span>
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "#1976D2",
                        }}
                        data-tip
                        data-for="VersionHistory"
                      >
                        Version History
                      </Typography>
                      <ReactTooltip id="VersionHistory" place="bottom" effect="solid">
                        Sections version history
                      </ReactTooltip>
                    </AccordionSummary>
                    <ReactTooltip id="expandIconTooltip" place="bottom" effect="solid">
                      view detail history
                    </ReactTooltip>
                    <AccordionDetails>
                      {/* <h5 onClick={handleVersionModal}> Clck</h5> */}
                      {versionData?.map((data) => {
                        return (
                          <VersionHistoryCard
                            title={data.title}
                            date={data.date}
                            version={data.version}
                            createdBy={data.createdBy}
                            AssetSectionId={data.AssetSectionId}
                            //  handleSpecifcVersionData={specificVersion}
                            versionModal={handleVersionModal}
                          />
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div
                  style={{
                    width: "96%",
                    margin: "auto",
                    height: "2px",
                    backgroundColor: "grey",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                ></div>

                <div style={{ border: "1px solid #1976D2", borderRadius: "5px" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={
                        // <ExpandMoreIcon />
                        <span data-tip data-for="expandIconcomment">
                          <ExpandMoreIcon />
                        </span>
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <div className="comment_Flex_Button">
                        <div className="note_count_flex">
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "#1976D2",
                            }}
                            data-tip
                            data-for="Comments"
                          >
                            Comments
                          </Typography>
                          <ReactTooltip id="Comments" place="bottom" effect="solid">
                            Check all comments
                          </ReactTooltip>
                          <div>
                            <h4 className="count_tag_">({commentCount})</h4>
                          </div>
                        </div>

                        <button
                          className="open_button_"
                          onClick={(e) => {
                            handleOpenCommentModal(e);
                          }}
                          data-tip
                          data-for="addcommenttolist"
                        >
                          <AddCircleOutlineIcon />
                        </button>
                        <ReactTooltip id="addcommenttolist" place="bottom" effect="solid">
                          Add comments
                        </ReactTooltip>
                      </div>
                    </AccordionSummary>
                    <ReactTooltip id="expandIconcomment" place="bottom" effect="solid">
                      view all comments
                    </ReactTooltip>
                    <AccordionDetails>
                      <Typography>
                        {commentData && (
                          <div style={{ marginTop: "20px" }}>
                            {commentData
                              .sort((a, b) => b.seq - a.seq)
                              .map((data, index) => (
                                <ReusableCard
                                  viewType={"commentCard"}
                                  text={data.text}
                                  Id={data._id}
                                  deleteComment={handleDeleteComments}
                                  updateComment={handleUpdateComments}
                                  createdby={data.createdby}
                                  onClickCard={openCard}
                                  date={data.createdon}
                                  replyToComment={handleReply}
                                  replyView={showReply ? "showReply" : ""}
                                  replyArray={data.commentReply}
                                  deleteCommentReply={handleDeleteCommentReply}
                                  updateCommentReply={handleReply}
                                ></ReusableCard>
                              ))}
                          </div>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {isgetDataEditor && (
                  <>
                    <div
                      style={{
                        width: "96%",
                        margin: "auto",
                        height: "2px",
                        backgroundColor: "grey",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    ></div>

                    <div
                      style={{ border: "1px solid #1976D2", borderRadius: "5px" }}
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "#1976D2",
                            }}
                          >
                            Track Changes
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {trackedChanges && trackedChanges.length > 0 && (
                              <div>
                                {trackedChanges
                                  .sort((a, b) => b.seq - a.seq)
                                  .slice(0, displayCount)
                                  .map((data, index) => (
                                    <div
                                      key={index}
                                      style={{ marginBottom: "8px" }}
                                    >
                                      <ReusableCard
                                        viewType="trackedChangesCard"
                                        Id={data._id}
                                        createdby={data.createdBy}
                                        date={data.createdOn}
                                        addedContent={data.addedContent}
                                        removedContent={data.removedContent}
                                        s3Version={data.s3VersionId}
                                        onClickCard={handleRevertChanges}
                                        documentBody={data.documentBody}
                                        mouseEnter={handleMouseEnter}
                                        mouseLeave={handleMouseLeave}
                                      />
                                    </div>
                                  ))}
                                <div className="flexbetween">
                                  <div>
                                    <h4 className="count_tag_">
                                      ({trackedChanges.length})
                                    </h4>
                                  </div>
                                  <div className="flex">
                                    {displayCount > 5 && (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          transition: "0.9s ease-in-out",
                                        }}
                                        onClick={handleShowLess}
                                      >
                                        <ExpandLessIcon />
                                      </div>
                                    )}
                                    {displayCount < trackedChanges.length &&
                                      displayCount !== trackedChanges.length && (
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            transition: "0.7s ease-in-out",
                                          }}
                                          onClick={handleShowMore}
                                        >
                                          <ExpandMoreIcon />
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

        }

        <Modal
          open={openSummaryModal}
          onClose={() => setOpenSummaryModal(false)}
          aria-labelledby="summary-modal-title"
          aria-describedby="summary-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              outline: "none",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              width: "80%",
              marginTop: "25px",
              maxHeight: "80vh",
              overflowY: "auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                id="summary-modal-title"
                style={{ color: "#007bff", fontWeight: 700 }}
              >
                Notice Summary
              </Typography>
              {/* <IconButton style={{ color: "red" }} onClick={() => setOpenSummaryModal(false)}> */}
              <IconButton
                style={{ color: "red" }}
                onClick={() => {
                  setOpenSummaryModal(false);
                  setIsSearchTerm("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
                flexDirection: window.innerWidth < 768 ? "column" : "row"
              }}
            >
              <div style={{ width: window.innerWidth < 768 ? "100%" : "270px", marginBottom: "15px" }}>
                <OpportunityInputBoxMui
                  fontBold={"bold"}
                  value={propsPassed?.type}
                  label={"Type"}
                  hideCursor={true}
                />
              </div>

              <div style={{ width: window.innerWidth < 768 ? "100%" : "270px", marginBottom: "15px" }}>
                <OpportunityInputBoxMui
                  fontBold={"bold"}
                  value={propsPassed?.agancy}
                  label={"Issuing Agency"}
                  hideCursor={true}
                />
              </div>

              <div style={{ width: window.innerWidth < 768 ? "100%" : "270px", marginBottom: "15px" }}>
                <OpportunityInputBoxMui
                  fontBold={"bold"}
                  value={normalDate}
                  label={"Submission deadline"}
                  statusColor={statusColor}
                  hideCursor={true}
                />
              </div>

            </div>

            <div style={{ marginBottom: "25px" }}>
              <OpportunityInputBoxMui
                fontBold={"bold"}
                value={propsPassed?.title}
                label={"Title"}
                hideCursor={true}
              />
            </div>

            <div style={{ marginBottom: "20px" }}>



              <OpportunityMultilineInputBoxMui
                color={"black"}
                value={cleanedInitialContent}
                label={"Description"}
                rows={8}
                hideCursor={true}
              />
            </div>

            <div style={{ marginTop: "30px", marginBottom: "25px", overflow: "auto" }}>


              <OpportunityMultilineInputBoxMui
                color={"black"}
                value={summarizedDescription}
                label={"Summarized Description "}
                rows={8}
                hideCursor={true}
                style={{ marginTop: '10px' }}

              />
            </div>
          </div>
        </Modal>


        <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              outline: "none",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
            className='add-section-modal'
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                alignItems: "center",
                width: "400px",
                color: "#1976D2",
              }}
            >
              <Typography variant="h5" id="summary-modal-title">
                Add Section
              </Typography>
              <IconButton onClick={() => setOpenAddModal(false)}>
                <HighlightOffIcon style={{ color: "red" }} />
              </IconButton>
            </div>

            <TextField
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Title
                  <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                </div>
              }
              value={title}
              placeholder="Enter Title"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={handleAppNameChange}
            />
            <TextField
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Description
                  <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                </div>
              }
              placeholder="Enter the Description"
              value={adddescription}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              onChange={handleDescriptionChange}
            />

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                columnGap: "5px",
              }}
            >
              <Button
                style={{
                  width: "100px",
                  border: "1px solid #FF0000",
                  color: "#FF0000",
                  borderRadius: "10px",
                }}
                color="secondary"
                onClick={() => {
                  setOpenAddModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                style={{
                  width: "100px",
                  backgroundColor: "#1976D2",
                  color: "white",
                  borderRadius: "10px",
                }}
                onClick={() => AddSectionData()}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>


        {isPopupOpen && (
          <div
            style={{
              position: "fixed",
              top: "10%",
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
              // Enable scrolling
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                width: "80%", // Set a fixed width
                height: "80%", // Set a fixed height
                overflow: "auto", // Hide any overflow
                position: "relative",
              }}
            >
              <div style={{ width: "100%" }}>
                {showDocxPreview ? (
                  <div style={{ width: '100%', height: '500px', overflow: 'auto' }}>
                    <div dangerouslySetInnerHTML={{ __html: showDocxPreview }} />
                  </div>
                ) : (
                  <>
                    <iframe
                      src={fileURL}
                      id="previewIframe"
                      // ref={pdfViewer}
                      title="PDF Viewer"
                      width="100%"
                      height="500"
                    // frameBorder="0"
                    ></iframe>
                  </>
                )
                }

              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClosePopup}
                  style={{ marginTop: "10px" }}
                >
                  Close Template
                </Button>
              </div>
            </div>
          </div>
        )}

        <PreviewEditor
          open={isPreviewOpen}
          handlePreviewCancel={closePreview}
          content={previewContent}
          // joditRef={editor}
          config={previewConfig}
        />

        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          className="modal_layout"
        >
          <div className="modal_layout_div">
            <div className="comment_modal_flex">
              <div className="modal_title_">
                {updateIcon == true ? <h3>Update Note</h3> : <h3>Add Note</h3>}
              </div>
              <div className="modal_closeIcon">
                <IconButton onClick={handleModalClose}>
                  <HighlightOffIcon className="modal_closeIcon" />
                </IconButton>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <TextField
                multiline
                value={note}
                onChange={handleNoteChange}
                label="Note"
                variant="outlined"
                rows={11}
                fullWidth
                style={{ marginTop: "10px" }}
              />
            </div>
            <div className="modal_div_buttons">
              <Button
                variant="outlined"
                onClick={() => {
                  handleCancel();
                }}
                style={{
                  border: "1px solid #FF0000",
                  color: "#FF0000",
                  borderRadius: "10px",
                  outline: "none"
                }}
              >
                Cancel
              </Button>
              {updateIcon === true ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleUpdateNoteApi();
                  }}
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    borderRadius: "10px",
                    outline: "none",
                    border: "none"
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleAddNote();
                  }}
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    borderRadius: "10px",
                    outline: "none",
                    border: "none"
                  }}
                >
                  Add
                </Button>
              )}


            </div>
          </div>
        </Modal>

        <Modal
          open={isCommentModalOpen}
          onClose={handleCommentModalClose}
          className="modal_layout"
        >
          <div className="modal_layout_div">
            <div className="comment_modal_flex">
              <div className="modal_title_">
                {updatCommentIcon == true ? (
                  <h3>Update Comment</h3>
                ) : (
                  <h3>Add Comment</h3>
                )}
              </div>
              <IconButton
                className="modal_closeIcon"
                onClick={handleCommentModalClose}
              >
                <HighlightOffIcon className="modal_closeIcon" />
              </IconButton>
            </div>

            <TextField
              multiline
              value={commentsText}
              onChange={handleCommentChange}
              label="Comment"
              variant="outlined"
              rows={11}
              fullWidth
              style={{ marginTop: "10px" }}
            />

            <div className="modal_div_buttons">
              <Button
                variant="outlined"
                onClick={() => {
                  handleCancelComment();
                }}
                style={{
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: "red",
                  borderColor: "red",
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                  outline: "none"
                }}
              >
                Cancel
              </Button>
              {updatCommentIcon === true ? (
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    handleUpdateCommentApi(e);
                  }}
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    borderRadius: "10px",
                    outline: "none",
                    border: "none"
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    handleAddComment(e);
                  }}
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    borderRadius: "10px",
                    outline: "none",
                    border: "none"
                  }}
                >
                  Add Comment
                </Button>
              )}


            </div>
          </div>
        </Modal>

        <Modal
          open={isCardDatModalOpen}
          onClose={handleCardDataModalClose}
          className="modal_layout"
        >
          <div className="modal_layout_div">
            <div className="comment_modal_flex">
              <div className="modal_title_">
                <h4>{createdByCard}</h4>
              </div>
              <IconButton
                className="modal_closeIcon"
                onClick={handleCardDataModalClose}
              >
                <CloseIcon className="modal_closeIcon" />
              </IconButton>
            </div>

            <TextField
              multiline
              value={cardData}
              variant="outlined"
              InputProps={{ readOnly: true }}
              rows={11}
              fullWidth
              style={{ marginTop: "10px" }}
            />
            <div className="modal_div_buttons">
              <Button
                // variant="outlined"
                onClick={() => {
                  handlePreviousCard();
                }}
                style={{
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: "#1976D2",
                  borderColor: "#1976D2",
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                }}
              >
                Previous
              </Button>

              <Button
                // variant="outlined"
                onClick={() => {
                  handleNextCard();
                }}
                style={{
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: "#1976D2",
                  borderColor: "#1976D2",
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </Modal>



        {/* for project far clauses */}

        <Modal
          open={reportModal}
          onClose={() => setReportModal(false)}
        >
          <div className="requestModal" id="Clause-modal-title1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography variant="h5"  >
                Report
              </Typography>
              <div>
                <IconButton onClick={() => setShowDownloadModalForReport(true)}>
                  <DownloadIcon />
                </IconButton>
                <IconButton onClick={() => setReportModal(false)}>
                  <CloseIcon id="closeIcon" />
                </IconButton></div>
            </div>
            <div className="reportModalMainDiv" >
              <TableContainer component={Paper} id="reportTable">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>FAR Clauses</TableCell>
                      <TableCell>Version</TableCell>
                      <TableCell>Customization Details</TableCell>
                      <TableCell>User</TableCell>

                    </TableRow>
                  </TableHead>


                  <TableBody>
                    {rows !== null && rows?.map((clause, index) => (
                      <TableRow key={index}>
                        <TableCell>{clause.clause}</TableCell>
                        <TableCell style={{ textAlign: 'left' }}>
                          <div>version 1.0</div><br />
                          {pathArray[index] && pathArray[index].length > 0 && (
                            pathArray[index].map((e, index1) => (
                              <div key={index1}>version {index1 + 2}.0</div>
                            ))
                          )}
                        </TableCell>
                        <TableCell style={{ textAlign: 'left' }}>
                          <div>Added  <span>{dataArray[index]?.createdDate}</span></div><br />

                          {pathArray[index] && pathArray[index].map((e, index1) => (
                            <div key={index1}>Modified  <span>{dataArray[index]?.modifiedDate[index1]}</span></div>
                          ))}
                        </TableCell>
                        <TableCell style={{ textAlign: 'left' }}>
                          {dataArray[index]?.createdBy ? (
                            <div>{dataArray[index]?.createdBy}</div>
                          ) : (
                            <div>System Generated</div>
                          )}
                          <br />
                          {dataArray[index]?.modifiedBy.length > 0 && (
                            dataArray[index]?.modifiedBy.map((e, index2) => (
                              <div key={index2}>{e}</div>
                            ))
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>



                </Table>
              </TableContainer>
            </div>

          </div>
        </Modal>






        <Modal
          open={showDownloadModalForReport}
          onHide={() => setShowDownloadModalForReport(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%', // Adjusted width
              maxWidth: 400,
              bgcolor: 'white',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
              textAlign: 'center',
              overflowY: 'auto', // Enable scrolling if content exceeds box height
            }}
          >
            <div className="modal-header">
              <h3 id="modal-modal-title" className="modal-title">Download Options</h3>
              <CloseIcon onClick={() => setShowDownloadModalForReport(false)} className="close-icon" style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: 10, right: 20 }} />
            </div>

            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <span>Download as...</span>
              <br />
              <br />
              <br />
              <br />
              {/* <Button onClick={downloadPDFForReport} variant="contained" color="primary" style={{ marginRight: "30px", marginBottom: "10px" }}>
                PDF
              </Button> */}
              <Button onClick={downloadExcelForReport} variant="contained" color="primary" style={{ marginBottom: "10px" }}>
                Excel
              </Button>
            </div>
          </Box>
        </Modal>















        <Modal
          open={isOpenClauseModal}
          onClose={() => setIsOpenClauseModal(false)}
        >
          <div className="requestModal">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" id="Clause-modal-title">
                Clauses
              </Typography>
              <IconButton onClick={() => setIsOpenClauseModal(false)}>
                <CloseIcon id="closeIcon" />
              </IconButton>
            </div>

            <div id="clauses_textfeild_flex">
              <div id="clauses_textfeild_main">
                <TextField
                  label={<div>Add Clause</div>}
                  value={addManualClause}
                  variant="outlined"
                  size="small"
                  style={{ width: "100%", borderRadius: "20px" }}
                  onChange={handleAddClauses}
                />
              </div>
              <div id="clauses_addButton_main">
                <Button
                  title="Upload"
                  variant="outlined"
                  onClick={handleAddReasonModalOpen}
                  id="addclauseButton"
                >
                  Add
                  {/* <span style={{ marginLeft: "8px" }}>
                        <SendIcon />
                      </span> */}
                </Button>
              </div>

              {extractedClause && (
                <div>
                  <IconButton>
                    <div title="Download" style={{ color: "#1976D2" }} onClick={handleDownloadClause}>
                      <DownloadIcon />
                    </div>
                  </IconButton>
                </div>
              )}
            </div>

            {!extractedClause || !extractedClause.length > 0 || !extractedClause?.clauses?.length > 0 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h4>No clauses found </h4>
              </div>
            )}

            <div id="extractedClause_div"
              style={{
                overflowX: window.innerWidth < 500 ? "hidden" : "auto"
              }}
            >
              {extractedClause && (
                <>
                  <div>
                    {extractedClause?.clauses?.map((clauseData, i) => (
                      <div key={i}>

                        <h5 id="filename_ptag" >
                          Filename:{" "}
                          <span className="filname_Style">
                            {clauseData.filename}
                          </span>
                        </h5>

                        {clauseData?.responseData
                          ?.split("\n")
                          .map((clause, j) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={j}
                            >
                              <p
                                id="clause_ptag"
                                onClick={() => {
                                  handleClauseModalProject(
                                    clause,
                                    clauseData._id,
                                    extractedClause._id,
                                    extractedClause.assetId
                                  );
                                }}
                              >
                                {clause}
                              </p>

                            </div>
                          ))}
                      </div>

                    ))}
                  </div>
                  <div>
                    {extractedClause && extractedClause.manualClause && extractedClause.manualClause.length > 0 &&
                      <div>
                        <h5 id="filename_ptag" style={{ fontSize: "18px", marginBottom: window.innerWidth < 500 ? "10px" : "", marginTop: window.innerWidth < 500 ? "10px" : "" }}>Manually Added Clauses</h5>
                      </div>
                    }
                    {extractedClause?.manualClause?.map((data, i) => {

                      const shouldRender =
                        (data.status === "pending" && data.createdBy === cuurentUser) ||
                        (data.status === "pending" && userInfo.userRole.map(role => role.trim().toLowerCase()).some(role => 
                          role === "manager" || role === "application admin"
                        )) ||
                        (data.status === "approved") ||
                        (data.status === "rejected" && data.createdBy === cuurentUser);

                      if (!shouldRender) {
                        return null; // Do not render this clause
                      }
                      return (
                        <div style={{ padding: "5px 0px", borderBottom: "1px solid lightgray" }}>


                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {i + 1}.
                              <span
                                id="clause_ptag"
                                onClick={() => {
                                  handleClauseModalProject(
                                    data.clause,
                                    data._id,
                                    extractedClause._id,
                                    extractedClause.assetId
                                  );
                                }}
                              >{data.clause}
                              </span>
                            </div>


                            <div
                              style={{
                                width: "fit-content",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: window.innerWidth < 500 ? "5px" : ""

                              }}
                            >
                              {!userInfo?.userRole?.map(role => role.trim().toLowerCase()).some(role => 
                                    role === "manager" || role === "application admin"
                                  ) && data.createdBy == cuurentUser &&
                                <div>
                                  <b>{data.status}</b>
                                </div>

                              }
                              {userInfo?.userRole?.map(role => role.trim().toLowerCase()).some(role => 
                                    role === "manager" || role === "application admin"
                                  ) && data.status == "pending" &&
                                <div style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center"

                                }}>
                                  <IconButton
                                    onClick={() => { handleAcceptClause(data._id, extractedClause.assetId, extractedClause._id) }}
                                  >
                                    <CheckIcon
                                      style={{ color: "green" }}
                                    />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => { handleRejectClause(data._id, extractedClause.assetId, extractedClause._id) }}
                                  >

                                    <CloseIcon
                                      style={{ color: "red" }}
                                    />
                                  </IconButton>

                                </div>
                              }

                              {
                                (data.status === "approved" ||
                                  (data.status === "pending" && data.createdBy === cuurentUser) ||
                                  (data.status === "rejected" && data.createdBy === cuurentUser) ||
                                  (data.status === "pending" && !userInfo.userRole.map(role => role.trim().toLowerCase()).some(role => 
                                    role === "manager" || role === "application admin"
                                  ))) && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end"
                                    }}
                                  >
                                    <IconButton
                                      style={{ padding: "0px", color: "red" }}
                                      onClick={() =>
                                        handleDeleteReasonModalOpen(
                                          data._id,
                                          data.clause,
                                          extractedClause._id
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                )
                              }

                            </div>
                          </div>
                          <div>
                            <strong>Reason: </strong>
                            <span>{data.reasonForAdd}</span>
                          </div>

                        </div>
                      )
                    })}
                  </div>
                  <div>
                    {deletedClauseArray?.length > 0 && (
                      <>
                        <div>
                          <h4 id="filename_ptag" style={{ fontSize: "18px", marginTop: "15px" }}>Deleted Clauses</h4>
                        </div>
                        {deletedClauseArray.map((d, i) => (
                          <div
                            key={d._id}
                            style={{ padding: "5px 0px", borderBottom: "1px solid lightgray" }}
                          >
                            <div>
                              {i + 1}. <span id="clause_ptag">{d.clause}</span>
                            </div>

                            <div>
                              <strong>Reason: </strong>
                              <span>{d.reasonForDelete}</span>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                  </div>
                </>
              )}
            </div>


            {isOpenDeleteReasonModel && (
              <Dialog
                open={isOpenDeleteReasonModel}
                onClose={handleDeleteReasonModalclose}
                PaperProps={{
                  style: {
                    minWidth: '400px',
                    maxWidth: '20vw',
                    padding: "15px",
                    zIndex: 2000
                  },
                }}
              >
                <DialogContent>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h4>Reason for delete clause</h4>
                      <HighlightOffIcon onClick={handleDeleteReasonModalclose} style={{ color: "red" }} />
                    </div>
                    <br />
                    <TextareaAutosize
                      minRows={8}
                      // placeholder="Enter rejection reason"
                      value={deleteReason}
                      onChange={(e) => setDeleteReason(e.target.value)}
                      style={{ width: "100%" }}
                    />
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                      <Button
                        variant="contained"
                        style={{ marginRight: "15px", backgroundColor: "green", color: "white" }}
                        onClick={handleDeleteClause}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}


            {isOpenAddReasonModel && (
              <Dialog
                open={isOpenAddReasonModel}
                onClose={handleAddReasonModalclose}
                PaperProps={{
                  style: {
                    width: windowWidth < 900 ? '80%' : '35%',
                    padding: "15px",
                    zIndex: 2000,
                    // border:"5px solid blue"
                  },
                }}
              >
                <DialogContent>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h4>Reason for adding clause</h4>
                      <HighlightOffIcon onClick={handleAddReasonModalclose} style={{ color: "red" }} />
                    </div>
                    <br />
                    <TextareaAutosize
                      minRows={8}
                      // placeholder="Enter rejection reason"
                      value={addReason}
                      onChange={(e) => setAddReason(e.target.value)}
                      style={{ width: "100%" }}
                    />
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                      <Button
                        variant="contained"
                        style={{ marginRight: "15px", backgroundColor: "green", color: "white" }}
                        onClick={handleAddManualClause}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}


            <div id="clause_footer_div">
              <div>
                <Button variant="outlined" style={{ border: "1px solid red", color: "red", marginTop: window.innerWidth < 500 ? "25px" : "" }}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={isClauseProjectModalOpen}
          onClose={handleCloseClauseProject}
        >
          <div className="clauseProjectModal">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" id="Clause-modal-title">
                Clause Project
              </Typography>
              <IconButton onClick={() => handleCloseClauseProject()}>
                <CloseIcon id="closeIcon" />
              </IconButton>
            </div>

            <div className="addProejct_input_div">
              <TextField
                label="Clause"
                variant="outlined"
                size="small"
                value={selectedClause}
                style={{ width: "100%", borderRadius: "20px" }}
              />
            </div>

            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData.projectName}
                label="Project Name"
                variant="outlined"
                size="small"
                style={{ width: "100%", borderRadius: "20px" }}
                InputProps={{
                  readOnly: clauseDataEditable,
                }}
                onChange={(e) => handleAddProjectClause(e, "projectName")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>
            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData.description}
                label="Description"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: clauseDataEditable,
                }}
                style={{ width: "100%", borderRadius: "20px" }}
                onChange={(e) => handleAddProjectClause(e, "description")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>
            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData.number}
                label="Number"
                variant="outlined"
                type="number"
                size="small"
                InputProps={{
                  readOnly: clauseDataEditable,
                }}
                style={{ width: "100%", borderRadius: "20px" }}
                onChange={(e) => handleAddProjectClause(e, "number")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>
            <div className="addProejct_input_div">
              <div className="datepicker_Div">
                <div>
                  <p>Start Date</p>
                </div>
                <div>
                  <TextField
                    id="date-picker"
                    type="date"
                    value={clauseProjectData.startDate}
                    onChange={(e) => handleAddProjectClause(e, "startDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        height: "30px",
                        boxShadow: "1px 1px 4px -2px #000000",
                      },
                      readOnly: clauseDataEditable,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="addProejct_input_div">
              <div className="datepicker_Div">
                <div>
                  <p>End Date:</p>
                </div>
                <div>
                  <TextField
                    id="date-picker"
                    type="date"
                    value={clauseProjectData.endDate}
                    onChange={(e) => handleAddProjectClause(e, "endDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        height: "30px",
                        boxShadow: "1px 1px 4px -2px #000000",
                      },
                      readOnly: clauseDataEditable,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData.fundingSource}
                label="Enter Funding Source"
                variant="outlined"
                size="small"
                style={{ width: "100%", borderRadius: "20px" }}
                InputProps={{
                  readOnly: clauseDataEditable,
                }}
                onChange={(e) => handleAddProjectClause(e, "fundingSource")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>

            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData.contractType}
                label="Enter Contract Type"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: clauseDataEditable,
                }}
                style={{ width: "100%", borderRadius: "20px" }}
                onChange={(e) => handleAddProjectClause(e, "contractType")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>

            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData.projectLocation}
                label="Enter Project Location "
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: clauseDataEditable,
                }}
                style={{ width: "100%", borderRadius: "20px" }}
                onChange={(e) => handleAddProjectClause(e, "projectLocation")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>

            {clauseDataEditable == false && !showEditButton && (
              <div id="clauseProject_addButton_main">
                <Button
                  title="Add"
                  variant="outlined"
                  onClick={handleAddProjectClauseData}
                  id="addclauseButton"
                >
                  Add
                </Button>
              </div>
            )}

            {clauseDataEditable == true && !showEditButton && (
              <div id="clauseProject_addButton_main">
                <Button
                  title="Edit"
                  variant="outlined"
                  onClick={handleEnableUpdate}
                  id="addclauseButton"
                >
                  Edit
                </Button>
              </div>
            )}

            {showEditButton && (
              <div id="clauseProject_addButton_main">
                <Button
                  title="Save"
                  variant="outlined"
                  onClick={handleUpdateClauseProject}
                  id="addclauseButton"
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </Modal>

        <Modal
          open={showClauseDownloadModal}
          onHide={() => setClauseshowDownloadModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%", // Adjusted width
              maxWidth: 400,
              bgcolor: "white",
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
              textAlign: "center",
              overflowY: "auto", // Enable scrolling if content exceeds box height
            }}
          >
            <div className="modal-header">
              <h3 id="modal-modal-title" className="modal-title">
                Download Options
              </h3>
              <CloseIcon
                onClick={() => setClauseshowDownloadModal(false)}
                className="close-icon"
                style={{
                  color: "red",
                  cursor: "pointer",
                  position: "absolute",
                  top: 10,
                  right: 20,
                }}
              />
            </div>

            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <span>Download as...</span>
              <br />
              <br />
              <br />
              <br />
              <Button
                onClick={ClausePDFDownload}
                variant="contained"
                color="primary"
                style={{ marginRight: "30px", marginBottom: "10px" }}
              >
                PDF
              </Button>
              <Button
                onClick={ClauseDocxDownload}
                variant="contained"
                color="primary"
                style={{ marginBottom: "10px" }}
              >
                Word
              </Button>
            </div>
          </Box>
        </Modal>

        {/* reply to comment */}
        <Modal
          open={isReplyModelOpen}
          onClose={handleReplyModelClose}
          className="modal_layout"
        >
          <div className="modal_layout_div">
            <div className="comment_modal_flex">
              <div className="modal_title_">
                {/* <h3>Add Reply</h3> */}
                {commentReplyUpdateIcon == true ? (
                  <h3>Update Reply</h3>
                ) : (
                  <h3>Add Reply</h3>
                )}
              </div>
              <IconButton
                className="modal_closeIcon"
                onClick={handleReplyModelClose}
              >
                <CloseIcon className="modal_closeIcon" />
              </IconButton>
            </div>

            <TextField
              multiline
              value={replyText}
              onChange={handleReplyChange}
              //  label="Add Comment.."
              variant="outlined"
              rows={11}
              fullWidth
              style={{ marginTop: "10px" }}
            />

            <div className="modal_div_buttons">
              {commentReplyUpdateIcon == true ? (
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    handleUpdateCommentReply(e);
                  }}
                  style={{
                    fontSize: "12px",
                    borderRadius: "10px",
                    color: "#1976D2",
                    borderColor: "#1976D2",
                    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                    marginLeft: "10px",
                  }}
                >
                  Update Reply
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    handleReplyTocomment(e);
                  }}
                  style={{
                    fontSize: "12px",
                    borderRadius: "10px",
                    color: "#1976D2",
                    borderColor: "#1976D2",
                    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                    marginLeft: "10px",
                  }}
                >
                  Add Reply
                </Button>
              )}

              <Button
                variant="outlined"
                onClick={() => {
                  handleCancelReply();
                }}
                style={{
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: "red",
                  borderColor: "red",
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

        {/* reply to notes */}
        <Modal
          open={isNotesReplyModelOpen}
          onClose={handleNotesReplyModelClose}
          className="modal_layout"
        >
          <div className="modal_layout_div">
            <div className="comment_modal_flex">
              <div className="modal_title_">
                {/* <h3>Add Reply</h3> */}
                {notesReplyUpdateIcon == true ? (
                  <h3>Update Reply</h3>
                ) : (
                  <h3>Add Reply</h3>
                )}
              </div>
              <IconButton
                className="modal_closeIcon"
                onClick={handleNotesReplyModelClose}
              >
                <CloseIcon className="modal_closeIcon" />
              </IconButton>
            </div>

            <TextField
              multiline
              value={notesReplyText}
              onChange={handleNotesReplyChange}
              //  label="Add Comment.."
              variant="outlined"
              rows={11}
              fullWidth
              style={{ marginTop: "10px" }}
            />

            <div className="modal_div_buttons">
              {notesReplyUpdateIcon == true ? (
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    handleUpdateNotesReply(e);
                  }}
                  style={{
                    fontSize: "12px",
                    borderRadius: "10px",
                    color: "#1976D2",
                    borderColor: "#1976D2",
                    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                    marginLeft: "10px",
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    handleReplyToNotes(e);
                  }}
                  style={{
                    fontSize: "12px",
                    borderRadius: "10px",
                    color: "#1976D2",
                    borderColor: "#1976D2",
                    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                    marginLeft: "10px",
                  }}
                >
                  Add Reply
                </Button>
              )}

              <Button
                variant="outlined"
                onClick={() => {
                  handleCancelNotesReply();
                }}
                style={{
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: "red",
                  borderColor: "red",
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>








        <Modal
          open={openModalClauseAdd}
          onClose={handleCloseAddClause}
        >
          <>
            <div className="modal_layout_custom">
              <div className="modal_content_custom">
                <div className="modalCloseDiv">
                  <IconButton
                    className="modal_closeIcon"
                    onClick={handleCloseAddClause}
                  >
                    <CloseIcon className="modal_closeIcon" />
                  </IconButton>
                </div>
                <div>
                  <TextField
                    value={clausesInput}
                    onChange={(e) => handleClauseChange(e.target.value)}
                    placeholder="Add clause"
                    size="small"
                  />
                  <Button onClick={handleAddClause} >
                    Add Clause
                  </Button>
                </div>
              </div>
            </div>
          </>
        </Modal>
        <VersionComapreModal
          versionModalState={versionModalOpen}
          handleVersionModalClose={handleCloseVersionModal}
          SecId={versionSectionId}
          versiondata={specificVersionData}
          versionNo={selectedVersion}
        />
      </>
      <Modal
        open={showDownloadSectionModal}
        onHide={() => setShowDownloadSectionModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%", // Adjusted width
            maxWidth: 400,
            bgcolor: "white",
            borderRadius: 8,
            boxShadow: 24,
            p: 2,
            textAlign: "center",
            overflowY: "auto", // Enable scrolling if content exceeds box height
          }}
        >
          <div className="modal-header">
            <h3
              id="modal-modal-title"
              className="modal-title"
            >
              Download Options
            </h3>
            <CloseIcon
              onClick={handleDownloadSectionModalClose}
              className="close-icon"
              style={{
                color: "red",
                cursor: "pointer",
                position: "absolute",
                top: 10,
                right: 20,
              }}
            />
          </div>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <span>Download as...</span>
            <br />
            <br />
            <br />
            <br />
            <Button
              onClick={downloadSectionSinglePDF}
              variant="contained"
              color="primary"
              style={{
                marginRight: "30px",
                marginBottom: "10px",
              }}
            >
              PDF
            </Button>
            <Button
              onClick={handleSectionDocxDownload}
              variant="contained"
              color="primary"
              style={{ marginBottom: "10px" }}
            >
              Word
            </Button>
          </div>
        </Box>
      </Modal>


      {/* <Modal
                open={isTaskAssignedCommentOpen}
                onClose={handleCloseTaskAssignedCommentModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box
                  style={{
                    width: '500px',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h5" id="Clause-modal-title">
                      Add Comment
                    </Typography>
                    <IconButton onClick={handleCloseTaskAssignedCommentModal}>
                      <CloseIcon id="closeIcon" />
                    </IconButton>
                  </div>
                  <TextField
                    label="Add your comment"
                    multiline
                    rows={8}
                    value={taskAsignmentcomment}
                    onChange={handleTaskAsignmentCommentChange}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: '20px' }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitTaskComment}
                  >
                    Submit
                  </Button>
                </Box>
              </Modal> */}

      <TaskAssignmentAddComentModal
        open={isTaskAssignedCommentOpen}
        close={handleCloseTaskAssignedCommentModal}
        value={taskAsignmentcomment[taskApproveStatus] || ''}
        onChange={handleTaskAsignmentCommentChange}
        submit={handleSubmitTaskComment}
      />


      <TaskAssignmentComentModal
        open={isFetchedCommentModalOpen}
        close={handleCloseFetchedCommentModal}
        commentData={sectionCommentData}
      />


<RevertRequestModal
        open={revertRequestModalOpen}
        close={handleCloseRevertRequestModal}
        value={revertRequest}
        onChange={handleRevertRequestChange}
        submit={submitRevertRequest}
      />


      <RevertApprovalModal
        open={revertApprovalOpen}
        close={handleCloseRevertApproval}
        submit={submitRevertApproval}
        doc={revertDoc.revertedBy}
      />  

      
      <AttachmentModal
        open={isOpenattachmentModal}
        close={handleCloseAttachmentModal}
        documentData={documentData}
        handleAttachment={handleSelectAttachment}
        resourceLinks={resourceLinks}
        sectorData={sectorData}
        AiData={openAiSectionsList}
        defaultSector={departMentSector}
        selectedSectors={selectedSectors}
        handleSectorFieldChange={handleSectorFieldChange}
        customStyles1={customStyles1}
        selectedFields={selectedFields}
        handleSelectFieldChange={handleSelectFieldChange}
        sectionCreation={sectionCreation}
        handleCallFileReader={handleCallFileReader}
      />


    </>
  );
};

