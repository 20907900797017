import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ChatBoxPage from './ChatBoxPage';


const ChatPopup = ({open, handleClose, usr}) => {

  return (
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="chat-modal"
        aria-describedby="chat-box-page"
      >
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          width: '80%',
          height: '80%',
          overflowY: 'auto',
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 style={{ margin: 0 }}>Chat</h2>
            <IconButton onClick={handleClose} style={{ padding: '8px' }}>
              <CloseIcon />
            </IconButton>
          </div>

          {/* Divider or other UI elements */}
          <hr />

          {/* ChatBoxPage component */}
          <ChatBoxPage usr={usr}/>
        </div>
      </Modal>
  );
};

export default ChatPopup;
